<div class="search-list">
    <div
        [formGroup]="form"
        class="search-list-mobile-search-control"
    >
        <search
            [analyticsClick]="gaEvents.APP_GLOBAL_SEARCH"
            formControlName="search"
            placeholder="Enter keywords or phrase"
            [debounceLimit]="debounceLimit"
        ></search>
    </div>

    <ng-container *ngIf="loader.counter as loading">
        <div class="search-list-header">
            <h3>Searching for '{{ form.value.search }}'</h3>
            <p>Searching for messages in all of your workflows.</p>
        </div>
    </ng-container>

    <global-search-ghost-loader *ngIf="loader.counter"></global-search-ghost-loader>
    <ng-container *ngIf="results$ | async; let response">
        <div *ngIf="!loader.counter">
            <ng-container *ngIf="response.result.length; else noResults">
                <div class="search-list-header">
                    <h3>Search results for '{{ this.queryParams$ | async }}'</h3>
                    <p>Showing top search results from messages in all of your workflows.</p>
                </div>
                <ng-container *ngFor="let message of response.result">
                    <global-search-card
                        [searchTerm]="form.controls.search?.value"
                        [message]="message"
                    ></global-search-card>
                </ng-container>
            </ng-container>

            <ng-template #noResults>
                <empty-state
                    title="No results found for '{{ this.queryParams$ | async }}'"
                    htmlText="We couldn't find any results matching your search.<br>Check spelling or adjust your search keywords."
                    [imageType]="emptyState.NotFound"
                >
                </empty-state>

                <div
                    *ngIf="aiAssistantFeatureEnabled$ | async"
                    class="search-list-ai-assistant-container"
                >
                    <button
                        [analyticsClick]="gaEvents.APP_GLOBAL_SEARCH_ASK_AI_ASSISTANT"
                        class="fx-btn fx-btn--primary"
                        type="button"
                        (click)="routeToAssistantPage()"
                    >
                        <delphi-logo label="Ask {{ environment.aiName }}"></delphi-logo>
                    </button>
                    <p>Get help with your search from Visory's AI Assistant</p>
                </div>
            </ng-template>
        </div>
    </ng-container>
</div>
