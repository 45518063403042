import { Component, Input, OnDestroy, OnInit, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";

const CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => QueryFieldComponent),
    multi: true,
};

@Component({
    selector: "query-field",
    templateUrl: "./query-field.component.html",
    styleUrls: ["./query-field.component.scss"],
    providers: [CONTROL_VALUE_ACCESSOR],
})
export class QueryFieldComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() editMode: boolean;

    enableCustomQuery = false;
    visible = false;
    control = new FormControl("");

    private onChange?: (query: string) => void;
    private onTouch?: () => void;
    private valSub?: Subscription;

    ngOnInit(): void {
        this.valSub = this.control.valueChanges.pipe(distinctUntilChanged()).subscribe((val) => {
            this.onTouch?.();
            this.onChange?.(val);
        });

        this.forceRepaint();
    }

    ngOnDestroy(): void {
        this.valSub?.unsubscribe();
    }

    writeValue(query: string): void {
        this.control.setValue(query);
    }

    registerOnChange(fn: (query: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.control.disable();
        } else {
            this.control.enable();
        }
    }

    private forceRepaint(): void {
        setTimeout(() => {
            this.visible = true;
        }, 0);
    }
}
