import { Component } from "@angular/core";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { Observable } from "rxjs";
import { UserAssigneeService } from "../../services/user-assignee.service";
import { ITimeline, Role } from "@visoryplatform/threads";
import { ActivatedRoute } from "@angular/router";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { Paginator } from "projects/portal-modules/src/lib/shared/services/paginator";
import { IPaginated } from "@visoryplatform/datastore-types";

@Component({
    selector: "list-user-timelines",
    templateUrl: "./list-user-timelines.component.html",
    styleUrls: ["./list-user-timelines.component.scss"],
})
export class ListUserTimelinesComponent {
    loader = new Loader();
    paginator = new Paginator<ITimeline>(10);
    userTimelines$: Observable<ITimeline[]>;

    role$: Observable<Role>;
    userId$: Observable<string>;

    constructor(
        private userTimelinesService: UserAssigneeService,
        private route: ActivatedRoute,
        private authService: AuthService,
    ) {
        this.userId$ = this.route.params.pipe(map((params) => params.userId));

        this.role$ = this.authService.getValidUser().pipe(
            map((user) => user.globalRole),
            shareReplay(1),
        );

        this.userTimelines$ = this.paginator.wrap();

        this.paginator.refresh((page) => this.getThreadListing(page));
    }

    private getThreadListing(page: string): Observable<IPaginated<ITimeline>> {
        return this.userId$.pipe(
            switchMap((userId) => this.loader.wrap(this.userTimelinesService.listTimelines(userId, page))),
        );
    }
}
