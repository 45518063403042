import { Injectable } from "@angular/core";
import { ICreateRequestMenu, ICreateRequestMenuTemplateDefinition, RequestTypes } from "../../../types/CreateRequest";
import { Role, ThreadTypes } from "@visoryplatform/threads";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { take } from "rxjs/operators";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { IRequestForm } from "../interfaces/IRequestForms";
import { ICreateRequestModalData } from "../interfaces/request.interfaces";

@Injectable({
    providedIn: "root",
})
export class CreateRequestService {
    //TODO: this should be config driven along with the workflows the menu item should appear in
    static requiredPermissions = ["PayrunRequestCreate", "ThreadUpdateAll"];

    constructor(private permissionService: PermissionService, private formBuilder: FormBuilder) {}

    async filterOptions(
        threadType: string,
        role: Role,
        createRequestConfiguration: ICreateRequestMenu[],
    ): Promise<ICreateRequestMenu[]> {
        const filteredOptions = await this.getFilteredOptions(threadType, role, createRequestConfiguration);
        const options = this.mapOptionsTemplates(threadType, filteredOptions);

        return options.filter((option) => {
            if (option.type === RequestTypes.TemplateRequest && !option.templates?.length) {
                return false;
            } else {
                return true;
            }
        });
    }

    initControls(template: ICreateRequestMenuTemplateDefinition, form: FormGroup<IRequestForm>): void {
        if (!template) {
            return;
        }

        const { vaultTitle, cardDescription, requestItems } = template;
        if (vaultTitle || cardDescription) {
            form.patchValue({
                title: vaultTitle || "",
                cardDescription: cardDescription || "",
            });
        }

        if (requestItems?.length) {
            this.initRequestItems(requestItems, form);
        }
    }

    getRequestAccountName(data: ICreateRequestModalData): string {
        return data.thread.account?.label || "";
    }

    private initRequestItems(requestItems: string[], form: FormGroup<IRequestForm>): void {
        form.setControl(
            "requestItems",
            this.formBuilder.array(
                requestItems.map(
                    (val) =>
                        new FormGroup({
                            requestItem: new FormControl(null),
                            description: new FormControl(val, [Validators.required]),
                            completed: new FormControl({ value: false, disabled: true }),
                        }),
                ),
            ),
        );
    }

    private async getFilteredOptions(
        threadType: string,
        role: Role,
        createRequestConfiguration: ICreateRequestMenu[],
    ): Promise<ICreateRequestMenu[]> {
        if (threadType.includes(ThreadTypes.Payroll)) {
            const rolePermissions$ = this.permissionService.hasSomePermission(
                role,
                CreateRequestService.requiredPermissions,
            );
            const rolePermissions = await rolePermissions$.pipe(take(1)).toPromise();

            // if one of the roles has one of the required permissions, allow
            if (rolePermissions) {
                return createRequestConfiguration;
            }
        }

        return createRequestConfiguration.filter(
            (option) =>
                option.type !== RequestTypes.PayrunRequest && option.type !== RequestTypes.CreateBankFileRequest,
        );
    }

    private mapOptionsTemplates(threadType: string, options: ICreateRequestMenu[]): ICreateRequestMenu[] {
        return options.map((option: ICreateRequestMenu) => {
            if (option?.templates) {
                return {
                    ...option,
                    templates: option.templates.filter((val) => val.type.some((type) => type === threadType)),
                };
            }

            return option;
        });
    }
}
