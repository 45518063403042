import { Component, Inject } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { GA_EVENTS } from "../../../analytics/services/gtagAnalytics.service";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { CreateCustomerRequestModalComponent } from "../create-customer-request-modal/create-customer-request-modal.component";
import { Router } from "@angular/router";
import { commonModalConfig } from "../../constants/modal.constants";
import { ITimeline } from "@visoryplatform/threads";

interface ISuccessModalData {
    thread: ITimeline;
}

@Component({
    selector: "success",
    templateUrl: "./success-modal.component.html",
    styleUrls: ["./success-modal.component.scss"],
})
export class SuccessModalComponent {
    readonly gaEvents = GA_EVENTS;

    timeline: ITimeline;

    constructor(
        @Inject(MAT_DIALOG_DATA) private dialogData: ISuccessModalData,
        private dialogRef: MatDialogRef<SuccessModalComponent>,
        private dialog: MatDialog,
        private router: Router,
    ) {
        this.timeline = this.dialogData.thread;
    }

    close(): void {
        this.dialogRef.close();
    }

    create(): void {
        this.dialogRef.close();
        this.dialog.open(CreateCustomerRequestModalComponent, commonModalConfig).afterClosed().subscribe();
    }

    async view(): Promise<void> {
        this.dialogRef.close();
        await this.router.navigate(["/workflows", this.timeline.id]);
    }

    async goToWorkflowsPage(): Promise<void> {
        this.dialogRef.close();
        await this.router.navigate(["/workflows"]);
    }
}
