import { Component } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
@Component({
    selector: "vc-end-session-modal",
    templateUrl: "./vc-end-session-modal.component.html",
    styleUrls: ["./vc-end-session-modal.component.scss"],
})
export class VcEndSessionModalComponent {
    constructor(private dialogRef: MatDialogRef<VcEndSessionModalComponent, boolean>) {}

    endSession() {
        this.dialogRef.close(true);
    }

    close() {
        this.dialogRef.close();
    }
}
