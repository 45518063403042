import { BUSINESS_DAY_MS, IWorkflowToken, RequestAttachmentType, WorkflowTokenTypes } from "@visoryplatform/threads";

import { ConfigTokenExtensionToken } from "@visoryplatform/workflow-core";
import { FrequencyLabel } from "../../account/constants/configuration-settings.constants";
import { RequestTypes } from "projects/default-plugins/vault/types/CreateRequest";

export const payrunCustomerUploadsAbaToken: IWorkflowToken = {
    id: "d77452e1-50ef-4e6d-b9a8-e6d4a48cd15e",
    label: "Customer uploads bank file",
    type: WorkflowTokenTypes.Payrun,
    configTokens: {
        [ConfigTokenExtensionToken.RFI_CARD_VARIATION]: {
            extensionType: RequestAttachmentType.Rfi,
            modalData: {
                requestType: RequestTypes.TemplateRequest,
                templateId: "72624941-1ef9-48eb-a1ee-1f2cbb410205",
            },
        },
    },
};

export const payrunVisoryUploadsAbaToken: IWorkflowToken = {
    id: "4b885a54-c429-4243-9c11-04f58dabfbfc",
    label: "Visory uploads bank file",
    type: WorkflowTokenTypes.Payrun,
    configTokens: {
        [ConfigTokenExtensionToken.RFI_CARD_VARIATION]: {
            extensionType: RequestAttachmentType.Rfi,
            modalData: {
                requestType: RequestTypes.TemplateRequest,
                templateId: "e92b8d49-4a0a-4314-a5eb-33af79c8ab87",
            },
        },
    },
};

export const payrunWeekly: IWorkflowToken = {
    id: "gg818dd6-6b2a-4e3c-b0df-2040b4c6a9e8",
    label: FrequencyLabel.weekly,
    type: WorkflowTokenTypes.Payrun,
    affectsScheduling: true,
    configTokens: {
        [ConfigTokenExtensionToken.SLA_VARIATION]: {
            duration: 3 * BUSINESS_DAY_MS,
        },
    },
};

export const payrunFortnightly: IWorkflowToken = {
    id: "tt818dd6-6b2a-4e3c-b0df-2040b4c6a9e8",
    label: FrequencyLabel.fortnightly,
    type: WorkflowTokenTypes.Payrun,
    affectsScheduling: true,
    customFrequency: FrequencyLabel.fortnightly,
    configTokens: {
        [ConfigTokenExtensionToken.SLA_VARIATION]: {
            duration: 8 * BUSINESS_DAY_MS,
        },
    },
};

export const payrunMonthly: IWorkflowToken = {
    id: "aB818dd6-6b2a-4e3c-b0df-2040b4c6a9e8",
    label: FrequencyLabel.monthly,
    type: WorkflowTokenTypes.Payrun,
    affectsScheduling: true,
    configTokens: {
        [ConfigTokenExtensionToken.SLA_VARIATION]: {
            duration: 18 * BUSINESS_DAY_MS,
        },
    },
};

export const payrunWorkflowRFITokens = [payrunCustomerUploadsAbaToken, payrunVisoryUploadsAbaToken];

export const payrunFrequecyTokens = [payrunWeekly, payrunFortnightly, payrunMonthly];
