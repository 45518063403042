import { CdkStepper } from "@angular/cdk/stepper";
import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "stepper-footer",
    templateUrl: "./stepper-footer.component.html",
    styleUrls: ["./stepper-footer.component.scss"],
})
export class StepperFooterComponent {
    @Input() endStepLabel?: string;
    @Input() selectedIndex: number;
    @Input() totalSteps: number;
    @Input() disabled?: boolean;
    @Output() completed = new EventEmitter<void>();

    backStepLabel = "Back";
    nextStepLabel = "Next";

    constructor(public stepper: CdkStepper) {}

    next(selectedIndex: number, totalSteps: number): void {
        if (selectedIndex === totalSteps - 1) {
            this.completed.emit();
        }
    }
}
