<div class="request-common-request-message-view">
    <div
        class="request-common-request-message-view-field"
        [ngClass]="{ 'request-common-request-message-view-field--internal': card?.isInternal }"
    >
        <quill-view
            [content]="card?.description | allowWhitelistLinks"
            aria-label="message"
        ></quill-view>
    </div>

    <thread-card-replies
        #threadCardRepliesComponent
        [showCompose]="focusReplyInput"
        [replies]="replies"
        [card]="card"
        [thread]="thread"
        [userId]="userId$ | async"
        [readonly]="card?.status === cardStatuses.Disabled"
        [replyControl]="replyMessage"
        [isInternal]="card?.isInternal"
        (replySaved)="replySaved.emit()"
    >
    </thread-card-replies>
</div>
