import { Component, Inject, OnInit } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { ThreadCardService } from "./../../../../../portal-modules/src/lib/threads-ui/services/thread-card.service";
import { FormControl, FormGroup } from "@angular/forms";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";

export interface IReopenRequestParams {
    threadId: string;
    cardId: string;
    analyticsPrefix: string;
}

@Component({
    selector: "reopen-request-modal",
    templateUrl: "./reopen-request-modal.component.html",
    styleUrls: ["./reopen request-modal.component.scss"],
})
export class ReopenRequestModalComponent implements OnInit {
    form = new FormGroup({
        message: new FormControl(""),
    });
    public loader = new Loader();
    threadId: string;
    cardId: string;
    errorMessage: string;

    constructor(
        private threadCardService: ThreadCardService,
        private dialogRef: MatDialogRef<ReopenRequestModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IReopenRequestParams,
    ) {}

    ngOnInit() {
        this.threadId = this.data.threadId;
        this.cardId = this.data.cardId;
    }

    async onContinue() {
        this.loader.show();
        try {
            await this.threadCardService
                .replyCard(this.threadId, this.cardId, this.form.get("message").value)
                .toPromise();
            this.loader.hide();
            this.dialogRef.close(true);
        } catch (err) {
            this.loader.hide();
            this.errorMessage = "Sorry, something went wrong with your request. Please try again.";
        }
    }

    close() {
        this.dialogRef.close(false);
    }
}
