import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { Component, Input, OnChanges, SimpleChanges, forwardRef } from "@angular/core";
import {
    IWorkflowConfiguration,
    IWorkflowDesignType,
    WorkflowConfigTokenService,
    WorkflowConfigTokens,
    WorkflowTokenContent,
} from "@visoryplatform/threads";

import { SelectWorkflowTokenControl } from "../../types/SelectDesignType";
import { Subscription } from "rxjs";
import { environmentCommon } from "../../../../../../lib/environment/environment.common";

interface WorkflowFormControls {
    [key: string]: FormControl;
}

type SelectWorkflowTokenChange = (obj: SelectWorkflowTokenControl) => void;

const CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectWorkflowTokenComponent),
    multi: true,
};

const VALIDATORS = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => SelectWorkflowTokenComponent),
    multi: true,
};

@Component({
    selector: "select-workflow-token",
    templateUrl: "./select-workflow-token.component.html",
    styleUrls: ["./select-workflow-token.component.scss"],
    providers: [CONTROL_VALUE_ACCESSOR, VALIDATORS],
})
export class SelectWorkflowTokenComponent implements ControlValueAccessor, Validator, OnChanges {
    @Input() selectedDesignId?: string;
    @Input() selectedConfigurationId?: string;
    @Input() workflowDesignTypes?: IWorkflowDesignType[];
    @Input() workflowConfigurations?: IWorkflowConfiguration[];

    onChange: (obj: SelectWorkflowTokenControl) => void;
    onTouch: () => void;
    validatorFn: () => void;

    readonly selectPackagePlaceholder = "Select a workflow package";
    readonly workflowTokenKeys: WorkflowConfigTokens = environmentCommon.workflowConfigTokens;

    workflowPackagesReadOnly = false;

    /** List of controls to loop and render in template */
    workflowTokenControls: WorkflowTokenContent[] = [];

    /** Map to keep track of which variation has been filled */
    workflowTokensSelectedMap = new Map<string, string | null>();

    formSub: Subscription;
    form = new FormGroup<WorkflowFormControls>({}, Validators.required);

    ngOnChanges(changes: SimpleChanges): void {
        const { workflowConfigurations, workflowDesignsTypes, selectedConfigurationId, selectedDesignId } = changes;

        if (workflowConfigurations || workflowDesignsTypes || selectedDesignId || selectedConfigurationId) {
            this.workflowTokenControls = this.getWorkflowTokenControlsExcludingMandatory();
            this.buildForm(this.selectedConfigurationId);
        }

        if (selectedConfigurationId) {
            this.workflowPackagesReadOnly = !!selectedConfigurationId?.currentValue;
        }

        if (selectedDesignId) {
            this.form.reset();
        }
    }

    validate(_control: AbstractControl): ValidationErrors | null {
        const hasMissingFields = Array.from(this.workflowTokensSelectedMap.values()).find((id) => id == null);
        if (hasMissingFields) {
            return { invalid: true };
        }
        return null;
    }

    registerOnValidatorChange?(fn: () => void): void {
        this.validatorFn = fn;
    }

    writeValue(obj: SelectWorkflowTokenControl): void {
        this.form.patchValue(obj);
    }

    registerOnChange(fn: SelectWorkflowTokenChange): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    onWorkflowTokenSelected(event: { id: string }, field: string): void {
        if (this.onTouch) {
            this.onTouch();
        }

        this.workflowTokensSelectedMap.set(field, event.id);
        const workflowTokenIds = Array.from(this.workflowTokensSelectedMap.values()).filter((id) => id != null);

        if (workflowTokenIds) {
            this.onChange({ workflowTokenIds });
        }
    }

    private getWorkflowTokenControlsExcludingMandatory(): WorkflowTokenContent[] {
        return this.getWorkflowTokenControls().filter(WorkflowConfigTokenService.excludeMandatory);
    }

    private getWorkflowTokenControls(): WorkflowTokenContent[] {
        if (this.selectedConfigurationId) {
            return this.getConfigurationWorkflowTokenControls(this.selectedConfigurationId);
        } else {
            return this.getDesignWorkflowTokenControls(this.selectedDesignId);
        }
    }

    private getDesignWorkflowTokenControls(designId: string): WorkflowTokenContent[] {
        const designType = this.findWorkflowDesignType(designId);
        if (!designType?.workflowToken) {
            return [];
        }

        return this.workflowTokenKeys[designType.workflowToken];
    }

    private getConfigurationWorkflowTokenControls(configId: string): WorkflowTokenContent[] {
        const config = this.workflowConfigurations?.find((config) => config.id === configId);
        if (!config?.workflowTokens?.length) {
            return [];
        }

        return this.getDesignWorkflowTokenControls(config.designId);
    }

    private findWorkflowDesignType(selectedDesignId: string | undefined): IWorkflowDesignType | undefined {
        return this.workflowDesignTypes?.find((design) => design.workflowDesignId === selectedDesignId);
    }

    private buildForm(selectedConfigurationId: string | undefined): void {
        this.workflowTokenControls.forEach((_, idx) => {
            this.form.addControl(`workflowTokenId${idx}`, new FormControl(""));
            this.workflowTokensSelectedMap.set(`workflowTokenId${idx}`, null);
        });

        if (selectedConfigurationId) {
            const workflowConfig = this.workflowConfigurations?.find((config) => config.id === selectedConfigurationId);
            const workflowTokenIds = workflowConfig?.workflowTokens;
            if (!workflowConfig || !workflowTokenIds) {
                return null;
            }

            workflowTokenIds.forEach((_, idx: number) =>
                this.form.get(`workflowTokenId${idx}`)?.setValue(workflowTokenIds[idx]),
            );
        }
    }
}
