import {
    environmentCommon as common,
    EnvironmentSpecificConfig,
} from "../../projects/portal-modules/src/lib/environment/environment.common";
import { SIGMA_FEATURE_CONFIG } from "./sigma-feature-flags";

const appName = "Visory";

const threadsUrl = "https://threads-backend.visory.findex.dev/qa";
const vaultUrl = "https://vault-backend.visory.findex.dev/qa";
const authUrl = "https://api.auth.findex.dev/qa";
const notificationsUrl = "https://notifications.visory.findex.dev/qa-user";
const notificationsWsUrl = "wss://notifications-ws.visory.findex.dev/qa";
const openMeasuresUrl = "https://qa-om-backend.visory.findex.dev/qa";
const tokensUrl = "https://qa-tokens-backend.visory.findex.dev/qa";

export const environmentCommon = common;
export const environment: EnvironmentSpecificConfig = {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    appVersion: require("../../package.json").version,
    featureFlags: SIGMA_FEATURE_CONFIG,
    production: false,
    enableServiceWorkerRefresh: true,
    appName,
    aiName: "Delphi",
    appTheme: "sigma",
    appUrl: "https://qa.visory.dev",
    stage: "qa",
    sentry: "https://2960dfcd9c1642cea3737b18ad2bc0ec@o516902.ingest.sentry.io/5623962",
    analytics: {
        google: {
            tagManagerId: "GTM-5GVFBCN",
            tagManagerAuth: "LiHy5Kuz9Ee9q2EI1wNEaQ",
            tagManagerPreview: "env-3",
        },
    },
    theme: {
        onboardingCentreAligned: false,
        formSubmitActionOutlined: true,
    },
    searchOptimization: {
        url: "",
    },
    threadsEndpoints: {
        base: threadsUrl,
    },
    commonEndpoints: {
        base: threadsUrl + "-common",
    },
    workflowEndpoints: {
        base: threadsUrl + "-workflow",
    },
    sigmaEndpoints: {
        base: threadsUrl + "-app/sigma",
    },
    videoChatEndpoints: {
        base: threadsUrl + "-video",
    },
    paymentsEndpoints: {
        base: threadsUrl + "-payments",
    },
    calendarEndpoints: {
        base: threadsUrl + "-calendar",
    },
    vaultEndpoints: {
        base: vaultUrl,
    },
    vaultThreadsEndpoints: {
        base: threadsUrl + "-vault",
    },
    notificationsEndpoints: {
        base: notificationsUrl,
        websockets: notificationsWsUrl,
    },
    publicEndpoints: {
        base: threadsUrl + "-public",
        cachedBaseImages: "https://qa.visory.dev/profiles",
        cachedBaseSystem: "https://qa.visory.dev/system",
    },
    tokensEndpoints: {
        base: tokensUrl,
    },
    threadsWebsockets: "wss://threads-ws.visory.findex.dev/qa",
    registration: {
        redirectUrl: "https://qa.visory.dev/register/email-success",
    },
    emailVerifyUrl: "https://qa.visory.dev/profile/verify-email",
    auth: {
        base: authUrl,
        userPoolId: "ap-southeast-2_EzRUAN9ED",
        userPoolWebClientId: "7ilsaqrcsethoshpvu1iaqqnjr",
        protectedResources: [threadsUrl, vaultUrl, authUrl, notificationsUrl, openMeasuresUrl],
        includeCredentialsEndpoints: [threadsUrl],
        forgotPasswordRedirect: "https://qa.visory.dev/reset-password",
        authenticationFlowType: "USER_SRP_AUTH",
    },
    activeDirectory: {
        clientId: "8a6c1119-d3cc-4252-95ca-9da3689f3f9c",
    },
    appId: "sigma",
    opentokApiKey: "46424152",
    payments: {
        publishableApiKey:
            "pk_test_51HRSojFWvxwGxwztHoFSpQL8fMWpDbM0H8RoDFGM2R9yoeSwaE9LRkq2HSpiK5ucB3SFHtSzUih2d4eyfDAPgFvU00EcinWyv0",
        taxRateIds: {
            aud: "txr_1HSBK3FWvxwGxwztD47IgWk0",
            nzd: "txr_1HZPQ3FWvxwGxwzthuqkNysa",
            sgd: "txr_1N5dluFWvxwGxwztHNUKSj1A",
            usd: "txr_1Nkx85FWvxwGxwztqxhpCvcp",
        },
    },
    openMeasures: {
        endpoint: openMeasuresUrl,
    },
    policyUrl: "https://visory-website.qa.findex.solutions/privacy-policy",
    termsUrl: "https://visory-website.qa.findex.solutions/terms-of-business",
    calendarLocation: "Sigma VC",
    errorRedirectUrl: "https://qa.visory.dev/error",
    emailTimelineUrl: "https://qa.visory.dev/timelines",
    onboardingVideoUrl: "https://player.vimeo.com/video/546331178?app_id=122963",
    whitelistedUrls: [
        "https://*.visory.com.au",
        "https://*.xero.com",
        "https://www.myob.com",
        "https://myob.com",
        "https://*.receipt-bank.com",
        "https://g.page/r",
        "https://app.useberry.com",
        "https://findexgrp-my.sharepoint.com",
        "https://findexgrp.sharepoint.com",
        "https://www.ato.gov.au",
        "https://www.loom.com",
        "https://gusto.com",
        "https://bill.com",
        "https://qbo.intuit.com",
        "https://quickbooks.intuit.com",
        "https://anz.com.au",
        "https://www.nab.com.au",
        "https://www.commbank.com.au",
        "https://www.westpac.com.au",
        "https://app.clickup.com",
        "https://visory.yourpayroll.com.au",
        "https://doc.clickup.com",
    ],
    virtualBackgroundPresets: {
        office: { src: "assets/images/visory/virtual-backgrounds/vb_office.jpg" },
        office_gray: { src: "assets/images/visory/virtual-backgrounds/vb_office_gray.jpg" },
        office_white: { src: "assets/images/visory/virtual-backgrounds/vb_office_white.jpg" },
        visory_purple: { src: "assets/images/visory/virtual-backgrounds/vb_visory_purple.jpg" },
        visory: { src: "assets/images/visory/virtual-backgrounds/vb_visory.jpg" },
        desk: { src: "assets/images/visory/virtual-backgrounds/vb_desk.jpg" },
        desk_gray: { src: "assets/images/visory/virtual-backgrounds/vb_desk_gray.jpg" },
        nature: { src: "assets/images/visory/virtual-backgrounds/vb_nature.jpg" },
        pool: { src: "assets/images/visory/virtual-backgrounds/vb_pool.jpg" },
    },
    videoCallAppUrl: "https://qa.visory.dev/video-call/index.html",
    launchDarkly: {
        clientSideId: "65ead9bd602c8410295b4300",
    },
};
