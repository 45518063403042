<div *ngIf="xeroIntegration$ | async; else noIntegration">
    <form
        *ngIf="account$ | async as account"
        [formGroup]="form"
        (ngSubmit)="save(account, form.value)"
        class="fx-form"
    >
        <h2>Sources</h2>
        <div
            class="fx-form-field"
            formArrayName="sources"
            *ngFor="let source of form.controls.sources.controls; let i = index"
        >
            <div class="fx-form-field-icon-container">
                <i class="las la-balance-scale-left"></i>
                <label class="fx-form-field-content-label">Source {{ i + 1 }}</label>
            </div>

            <div
                class="fx-form-field-content"
                [formGroup]="source"
            >
                <div class="fx-form-field-content-control">
                    <label class="fx-form-field-content-label">{{ sourcePrefix$ | async }}.</label>
                    <input
                        autocomplete="name"
                        class="fx-form-input"
                        formControlName="sourceId"
                        placeholder="Source ID"
                    />
                </div>
            </div>
        </div>

        <h2>Checks</h2>
        <div
            class="fx-form-field"
            formArrayName="resultTransforms"
            *ngFor="let resultTransform of form.controls.resultTransforms.controls"
        >
            <div class="fx-form-field-icon-container">
                <i class="las la-balance-scale-left"></i>
                <label class="fx-form-field-content-label">{{ resultTransform.value.label }}</label>
            </div>
            <div
                class="fx-form-field-content"
                [formGroup]="resultTransform"
            >
                <div class="fx-form-field-content-control">
                    <input
                        autocomplete="name"
                        class="fx-form-input"
                        formControlName="resultTransformId"
                        placeholder="Transform result ID"
                    />
                </div>
            </div>
        </div>

        <div class="form-controls">
            <button
                class="fx-btn fx-btn--primary"
                type="submit"
                [disabled]="loader.counter"
            >
                Save
            </button>
        </div>
    </form>
</div>

<ng-template #noIntegration>Please configure the Xero integration</ng-template>
