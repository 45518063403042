<div *ngIf="!groups.length">
    <loader-icon
        color="light"
        [static]="true"
    ></loader-icon>
</div>

<div
    *ngIf="!downloadCount"
    class="vault-attachments-empty"
>
    Attachments on this message have been removed.
</div>

<div
    class="files-container"
    *ngIf="downloadCount"
>
    <ng-container *ngFor="let group of groups; trackBy: trackGroup">
        <div *ngIf="group.items.length && !editable">
            <div
                *ngFor="let item of group.items; trackBy: trackItem"
                class="file"
            >
                <vault-card-item
                    [icon]="group.displayName === DocumentCategory.Report ? 'la-chart-bar' : 'la-file'"
                    [item]="item"
                    [canRename]="false"
                    [canDelete]="false"
                    [disabled]="disabled"
                    [canSign]="canSign"
                    [disableMarkAsReport]="isSignActionInGroup"
                    [disableRequestSignature]="isReportInGroup"
                    [isReport]="group.displayName === DocumentCategory.Report"
                    (download)="download.emit(item)"
                    (preview)="preview.emit(item)"
                    (sign)="sign.emit(item)"
                >
                </vault-card-item>
            </div>
        </div>

        <div
            class="editable"
            *ngIf="editable"
        >
            <div
                class="file"
                *ngFor="let item of group.items; trackBy: trackItem"
            >
                <vault-card-item
                    [icon]="group.displayName === DocumentCategory.Report ? 'la-chart-bar' : 'la-file'"
                    [item]="item"
                    [canRename]="editable && !item.signed"
                    [canCreateReports]="editable && !item.signed && canCreateReports"
                    [canDelete]="editable && !item.signed"
                    [disabled]="disabled"
                    [canSign]="canSign"
                    [disableMarkAsReport]="isSignActionInGroup"
                    [disableRequestSignature]="isReportInGroup"
                    [isReport]="group.displayName === DocumentCategory.Report"
                    (download)="download.emit(item)"
                    (preview)="preview.emit(item)"
                    (sign)="sign.emit(item)"
                    (delete)="delete.emit(item)"
                    (rename)="renameItem(item, $event)"
                    (markAsSign)="markDocumentAsSign(item)"
                    (markAsReport)="markDocumentAsReport(item)"
                >
                </vault-card-item>
            </div>
        </div>
    </ng-container>
</div>
