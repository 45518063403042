import { BrandedEmailTemplateData } from "./email-templates/BrandedEmailTemplateData";

export enum DeliveryMethod {
  Email = "email",
  Sms = "sms",
  Digest = "digest",
  Websocket = "websocket",
}
export type PossibleDeliveryData =
  | EmailData
  | SmsData
  | DigestData
  | WebsocketData;
export type EmailData = MjmlEmail | TemplatedEmail;

type BaseEmail = {
  to: string[];
  cc?: string[];
  bcc?: string[];
};

export type MjmlEmail = BaseEmail & {
  type: "mjml";
  subject: string;
  textTemplate: string;
  mjmlTemplate: string;
  templateData: any;
};

export type TemplatedEmail = { type: "templated" } & (
  | BrandedEmail
  | BlocksEmail
); // add future templates here

type BrandedEmail = BaseEmail & {
  templateName: "BrandedEmail";
  templateData: BrandedEmailTemplateData;
};

type BlocksEmail = BaseEmail & {
  templateName: "BlocksEmail";
  templateData: BlocksTemplateData;
};

export type WebsocketData = {
  metadata: { [key: string]: string | boolean | null };
};

export type SmsData = {
  /**
   * E.164 formatted phone numbers
   * @items {"type":"string","pattern":"^\\+(?:[0-9] ?){6,14}[0-9]$"}
   */
  recipients: string[];
  template: string;
  templateData: any;
};

export type DigestData = {
  emailAddress: string;
  appName: string;
  appUrl: string;
  recipientName: string;
  recipientTimezone: string;
  groupName: string;
  groupUrl: string;
  block: ContentBlock;
    theme: {
        primaryColor: string;
        textColor: string;
        logoUrl: string;
        logoWidth: number;
        buttonBackgroundColor: string;
        buttonTextColor: string;
        buttonBorderColor: string;
        appUrl: string;
        appName: string;
        footerHtml: string;
        socialIcons: {
            linkUrl: string;
            imageUrl: string;
        }[];
  };
};

export type BlocksTemplateData = {
  subject: string;
  blocks: BlockNotification[];
  theme: {
    primaryColor: string;
    textColor: string;
    appName: string;
    appUrl: string;
    logoUrl: string;
    logoWidth: number;
    buttonBackgroundColor?: string;
    buttonTextColor?: string;
    buttonBorderColor?: string;
    footerHtml: string;
    socialIcons: {
      linkUrl: string;
      imageUrl: string;
    }[];
  };
};

export type HeaderBlock = {
  isHeader: true; //this used to be a string called type by handlebars doesn't appear to do string comparison without helpers
  data: {
    text: string;
  };
};

export type GroupBlock = {
  isGroup: true;
  data: {
    title: string;
    url: string;
  };
  children?: BlockNotification[];
};

export type ContentBlock = {
  isContent: true;
  data: {
    title: string;
    subtitle: string;
    imageUrl: string;
    badgeText: string;
    body: string;
    secondaryContent?: boolean;
    border?: boolean;
  };
  children?: BlockNotification[];
};

export type ButtonBlock = {
  isButton: boolean;
  data: {
    label: string;
    url: string;
  };
};

export type BlockNotification =
  | HeaderBlock
  | GroupBlock
  | ContentBlock
  | ButtonBlock;
