import { Component, Input, OnDestroy } from "@angular/core";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { Observable, Subscription } from "rxjs";
import { UserAssigneeService } from "../../services/user-assignee.service";
import { ITimeline } from "@visoryplatform/threads";
import { ActivatedRoute } from "@angular/router";
import { map, switchMap } from "rxjs/operators";
import { Paginator } from "projects/portal-modules/src/lib/shared/services/paginator";
import { IPaginated } from "@visoryplatform/datastore-types";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { FormRecord, FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
    selector: "bulk-replace-user-workflows",
    templateUrl: "./bulk-replace-user-workflows.component.html",
    styleUrls: ["./bulk-replace-user-workflows.component.scss"],
    providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: BulkReplaceUserWorkflowsComponent }],
})
export class BulkReplaceUserWorkflowsComponent implements OnDestroy, ControlValueAccessor {
    @Input() newUserIds: string[];

    loader = new Loader();

    tableHeaders = {
        service: "Service",
        reviewChange: "Change",
        account: "Account",
        timelineTitle: "Workflow",
        replace: "Replace",
    };

    form = new FormRecord<FormControl<boolean>>({});

    tableData = new MatTableDataSource<ITimeline>();

    paginator = new Paginator<ITimeline>(10);
    userTimelines$: Observable<ITimeline[]>;
    userId$: Observable<string>;

    private userTimelinesSubscription: Subscription;
    private onChange?: (excludeThreadIds: string[]) => void;
    private onTouch?: () => void;

    constructor(private userAssigneeService: UserAssigneeService, private route: ActivatedRoute) {
        this.userId$ = this.route.params.pipe(map((params) => params.userId));

        this.userTimelines$ = this.paginator.wrap();
        this.paginator.refresh((page) => this.getThreadListing(page));

        this.userTimelinesSubscription = this.userTimelines$.subscribe((timelines) => {
            const threadIds = timelines.map((timeline) => timeline.id);
            for (const threadId of threadIds) {
                if (!this.form.controls[threadId]) {
                    this.form.addControl(threadId, new FormControl<boolean>(true));
                }
            }

            this.tableData.data = timelines;
        });

        this.form.valueChanges.subscribe(() => {
            const excludeThreadIds = Object.entries(this.form.controls)
                .filter(([_, control]) => !control.value)
                .map(([threadId]) => threadId);
            this.onChange?.(excludeThreadIds);
            this.onTouch?.();
        });
    }

    ngOnDestroy(): void {
        this.userTimelinesSubscription?.unsubscribe();
    }

    writeValue(excludeThreadIds: string[]): void {
        for (const threadId of excludeThreadIds) {
            if (!this.form.controls[threadId]) {
                this.form.addControl(threadId, new FormControl<boolean>(false));
            } else {
                this.form.controls[threadId].setValue(false);
            }
        }
    }

    registerOnChange(fn: (excludeThreadIds: string[]) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    private getThreadListing(page: string): Observable<IPaginated<ITimeline>> {
        return this.userId$.pipe(
            switchMap((userId) => this.loader.wrap(this.userAssigneeService.listTimelines(userId, page))),
        );
    }
}
