import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { VAULT_BASE, VaultModule as VaultSdkModule } from "@visoryplatform/vault";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { VaultCardModule } from "./components/vault-card/vault-card.module";
import { DocumentListComponent } from "./components/document-list/document-list.component";
import { SigningModalComponent } from "./components/signing-modal/signing-modal.component";
import { FilePreviewComponent } from "./components/file-preview/file-preview.component";
import { PdfPreviewComponent } from "./components/pdf-preview/pdf-preview.component";
import { HtmlPreviewComponent } from "./components/html-preview/html-preview.component";
import { CompleteRequestModalComponent } from "./components/upload/complete-rfi-modal/complete-request-modal.component";

import { CreateRfiRequestComponent } from "./components/request/create-rfi-request/create-rfi-request.component";
import { ActionRfiRequestComponent } from "./components/request/action-rfi-request/action-rfi-request.component";
import { RequestTodoActionsComponent } from "./components/request/request-todo-actions/request-todo-actions.component";
import { QuillModule } from "ngx-quill";
import { DocumentPreviewModalComponent } from "./components/document-preview/document-preview.component";
import { RouterModule } from "@angular/router";
import { AnalyticsModule } from "projects/portal-modules/src/lib/analytics";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { FindexAuthModule } from "projects/portal-modules/src/lib/findex-auth";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui";
import { SharedModule } from "projects/portal-modules/src/lib/shared";
import { ThreadsUiModule } from "projects/portal-modules/src/lib/threads-ui";
import { ENVIRONMENT } from "src/app/injection-token";
import { PLUGIN_FACTORY } from "projects/portal-modules/src/lib/plugins";
import { VaultPlugin } from "./vault.plugin";
import { FxTableModule } from "../../portal-modules/src/lib/fx-table/fx-table.module";
import { VaultSignFocusComponent } from "./components/vault-sign-focus/vault-sign-focus.component";
import { FileInputModule } from "./components/file-input/file-input.module";
import { InlineEditModule } from "./components/inline-edit/inline-edit.module";
import { FxTodoListModule } from "projects/portal-modules/src/lib/fx-todolist/fx-todolist.module";
import { RequestResponseComponent } from "./components/request/request-response/request-response.component";
import { RequestListComponent } from "./components/request-list/request-list.component";
import { MessageAttachModalComponent } from "./components/message-attach-modal/message-attach-modal.component";
import { TextFieldModule } from "@angular/cdk/text-field";
import { ReopenRequestModalComponent } from "./components/request/reopen-request/reopen-request-modal.component";
import { PreCreateRequestComponent } from "./components/request/pre-create-request/pre-create-request.component";
import { PreCreateRequestItemComponent } from "./components/request/pre-create-request-item/pre-create-request-item.component";
import { VaultRoutingModule } from "./vault-routing.module";
import { VaultListRouteComponent } from "./components/vault-list-route/vault-list-route.component";
import { VaultListTableComponent } from "./components/vault-list-table/vault-list-table.component";
import { ConfirmDeleteDialogComponent } from "./components/confirm-delete-dialog/confirm-delete-dialog.component";
import { RequestReplyComponent } from "./components/request/request-reply/request-reply.component";
import { RequestViewReplyComponent } from "./components/request/request-view-reply/request-view-reply.component";
import { PayrollModule } from "../payroll/payroll.module";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { PlatformModule } from "@angular/cdk/platform";
import { RequestHeaderComponent } from "./components/request/request-header/request-header.component";
import { RequestSubheaderComponent } from "./components/request/request-subheader/request-subheader.component";
import { RfiTodosComponent } from "./components/request/rfi-todos/rfi-todos.component";
import { RequestAttachmentsComponent } from "./components/request/request-attachments/request-attachments.component";
import { ActionPayrunRequestComponent } from "./components/request/action-payrun-request/action-payrun-request.component";
import { ActionRequestComponent } from "./components/request/action-request/action-request.component";
import { EditRfiRequestComponent } from "./components/request/edit-rfi-request/edit-rfi-request.component";
import { EditRequestTitleComponent } from "./components/request/edit-request-title/edit-request-title.component";
import { EditPayrunRequestComponent } from "./components/request/edit-payrun-request/edit-payrun-request.component";
import { CreatePayrunRequestComponent } from "./components/request/create-payrun-request/create-payrun-request.component";
import { RequestComponent } from "./components/request/request/request.component";
import { SharedPipesModule } from "../../portal-modules/src/lib/shared/pipes/shared-pipes.module";
import { EditMessageModalComponent } from "./components/edit-message-modal/edit-message-modal.component";
import { ValidateMarkFileAsReportPipe } from "./pipes/validate-mark-as-report.pipe";
import { CanDeleteAttachmentPipe } from "./pipes/can-delete-attachment.pipe";

@NgModule({
    exports: [
        PdfPreviewComponent,
        FilePreviewComponent,
        CreateRfiRequestComponent,
        EditRfiRequestComponent,
        ActionRfiRequestComponent,
        RequestTodoActionsComponent,
        HtmlPreviewComponent,
        MessageAttachModalComponent,
        EditMessageModalComponent,
        ReopenRequestModalComponent,
        PreCreateRequestComponent,
        PreCreateRequestItemComponent,
        VaultListRouteComponent,
        VaultListTableComponent,
        ConfirmDeleteDialogComponent,
        RequestReplyComponent,
        RequestViewReplyComponent,
        RequestSubheaderComponent,
        RequestHeaderComponent,
        RfiTodosComponent,
    ],
    declarations: [
        RequestListComponent,
        RequestResponseComponent,
        SigningModalComponent,
        CompleteRequestModalComponent,
        DocumentPreviewModalComponent,
        FilePreviewComponent,
        PdfPreviewComponent,
        DocumentListComponent,
        VaultSignFocusComponent,
        CreateRfiRequestComponent,
        CreatePayrunRequestComponent,
        ActionRequestComponent,
        ActionRfiRequestComponent,
        ActionPayrunRequestComponent,
        RequestTodoActionsComponent,
        HtmlPreviewComponent,
        MessageAttachModalComponent,
        EditMessageModalComponent,
        ReopenRequestModalComponent,
        PreCreateRequestComponent,
        PreCreateRequestItemComponent,
        VaultListRouteComponent,
        VaultListTableComponent,
        ConfirmDeleteDialogComponent,
        RequestReplyComponent,
        RequestViewReplyComponent,
        RequestHeaderComponent,
        RequestSubheaderComponent,
        RfiTodosComponent,
        RequestAttachmentsComponent,
        EditRfiRequestComponent,
        EditPayrunRequestComponent,
        EditRequestTitleComponent,
        CreatePayrunRequestComponent,
        RequestComponent,
        ValidateMarkFileAsReportPipe,
        CanDeleteAttachmentPipe,
    ],
    providers: [
        {
            provide: VAULT_BASE,
            useFactory: (environment: EnvironmentSpecificConfig) => environment.vaultEndpoints.base,
            deps: [ENVIRONMENT],
        },
        { provide: PLUGIN_FACTORY, useClass: VaultPlugin, multi: true },
    ],
    imports: [
        CommonModule,
        PlatformModule,
        VaultSdkModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        FindexAuthModule,
        FxUiModule,
        ThreadsUiModule,
        FindexUiModule,
        AnalyticsModule,
        SharedModule,
        QuillModule,
        RouterModule,
        FxTableModule,
        VaultCardModule,
        FileInputModule,
        InlineEditModule,
        FxTodoListModule,
        TextFieldModule,
        VaultRoutingModule,
        PayrollModule,
        MatSelectModule,
        SharedPipesModule,
    ],
})
export class VaultModule {}
