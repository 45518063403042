import { Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

interface IToastModalData {
    message: string;
    description: string;
    buttonLabel: string;
}
@Component({
    selector: "toast",
    templateUrl: "./ui-toast.component.html",
    styleUrls: ["./ui-toast.component.scss"],
})
export class UiToastComponent {
    message: string;
    description: string;
    buttonLabel: string;
    constructor(@Inject(MAT_DIALOG_DATA) data: IToastModalData, private dialogRef: MatDialogRef<IToastModalData>) {
        this.buttonLabel = data.buttonLabel;
        this.message = data.message;
        this.description = data.description;
    }

    closeDialog() {
        this.dialogRef.close(true);
    }
}
