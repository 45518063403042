import { Component, Inject, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { ICardTaskDetail, IThread } from "@visoryplatform/threads";
import { map, take } from "rxjs/operators";
import { AuthService } from "../../../findex-auth";
import { CloseThreadService } from "./close-thread-prompt.service";
import { SystemStepId } from "@visoryplatform/workflow-core";
export interface CloseThreadPromptParams {
    thread: IThread;
    workflowStepName: string;
}

@Component({
    selector: "app-close-thread-prompt",
    templateUrl: "./close-thread-prompt.component.html",
    styleUrls: ["./close-thread-prompt.component.scss"],
    providers: [CloseThreadService],
})
export class CloseThreadPromptComponent implements OnInit {
    threads: IThread[];
    selectedThread: IThread = null;
    loading = true;
    error: string;
    hasUpcomingMeetings: boolean;

    workflowStepName: string;
    pendingTasks: ICardTaskDetail[];

    systemStepIds = SystemStepId;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: CloseThreadPromptParams,
        private dialogRef: MatDialogRef<CloseThreadPromptComponent>,
        private authService: AuthService,
        private closeThreadService: CloseThreadService,
    ) {
        this.workflowStepName = data.workflowStepName;
    }

    async ngOnInit() {
        const userId = await this.authService
            .getUser()
            .pipe(
                map((val) => val.id),
                take(1),
            )
            .toPromise();

        this.pendingTasks = await this.closeThreadService.getPendingTasks(this.data.thread.id);
        if (!this.pendingTasks?.length) {
            this.hasUpcomingMeetings = await this.closeThreadService.threadHasUpcomingMeetings(this.data.thread.id);
            if (this.hasUpcomingMeetings) {
                this.threads = await this.closeThreadService.findThreadsToMigrateMeetings(this.data.thread, userId);
            }
        }

        this.loading = false;
    }

    async copyMeetingsCards(targetThreadId: string) {
        this.loading = true;
        this.error = null;
        try {
            await this.closeThreadService.migrateCalendarCards(this.data.thread.id, targetThreadId);
            this.dialogRef.close(true);
        } catch (err) {
            this.error = "Sorry, something went wrong";
        } finally {
            this.loading = false;
        }
    }

    close(val: boolean) {
        this.dialogRef.close(val);
    }
}
