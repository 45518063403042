import { Component } from "@angular/core";
import { Observable } from "rxjs";
import { UserAssigneeService } from "../../services/user-assignee.service";
import { IWorkflowConfiguration, Role } from "@visoryplatform/threads";
import { ActivatedRoute } from "@angular/router";
import { map, switchMap } from "rxjs/operators";
import { Loader } from "../../../../portal-modules/src/lib/shared/services/loader";
import { Paginator } from "projects/portal-modules/src/lib/shared/services/paginator";
import { IPaginated } from "@visoryplatform/datastore-types";

@Component({
    selector: "list-user-configurations",
    templateUrl: "./list-user-configurations.component.html",
    styleUrls: ["./list-user-configurations.component.scss"],
})
export class ListUserConfigurationsComponent {
    loader = new Loader();
    paginator = new Paginator<IWorkflowConfiguration>(10);
    workflowConfigs$: Observable<IWorkflowConfiguration[]>;

    role$: Observable<Role>;
    userId$: Observable<string>;

    constructor(private userTimelinesService: UserAssigneeService, private route: ActivatedRoute) {
        this.userId$ = this.route.params.pipe(map((params) => params.userId));

        this.workflowConfigs$ = this.paginator.wrap();
        this.paginator.refresh((page) => this.getThreadListing(page));
    }

    private getThreadListing(page: string): Observable<IPaginated<IWorkflowConfiguration>> {
        return this.userId$.pipe(
            switchMap((userId) => this.loader.wrap(this.userTimelinesService.listConfigurations(userId, page))),
        );
    }
}
