import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { IPayRunReport, IVaultRequestCardState } from "@visoryplatform/threads";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { Observable } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { PayrunReportDataService } from "../../services/payrun-report-data.service";
import { IPayrunReportLineData } from "../../interfaces/IPayrunReportLineData";

@Component({
    selector: "payrun-report-edit-request",
    templateUrl: "./payrun-report-edit-request.component.html",
    styleUrls: ["./payrun-report-edit-request.component.scss"],
})
export class PayrunReportEditRequestComponent implements OnInit {
    @Input() state$: Observable<IVaultRequestCardState>;
    @Output() updateControl = new EventEmitter();

    report$: Observable<IPayRunReport>;
    tableData$: Observable<MatTableDataSource<IPayrunReportLineData>>;
    loader = new Loader();

    private readonly dataSource = new MatTableDataSource<IPayrunReportLineData>();

    constructor(private payrunReportDataService: PayrunReportDataService) {}

    ngOnInit() {
        this.report$ = this.state$.pipe(
            switchMap((state) => {
                const fileId = state.attachments.fileId;
                const vaultId = state.vaultId;

                return this.payrunReportDataService.downloadVaultPayRunReport(vaultId, fileId);
            }),
            shareReplay(1),
        );

        this.tableData$ = this.report$.pipe(
            map((report: IPayRunReport) => {
                const dataSource = this.dataSource;
                dataSource.data = report.lines
                    .sort((a, b) => a.employeeName.localeCompare(b.employeeName))
                    .map((reportLine) => ({
                        ...reportLine,
                        currency: this.payrunReportDataService.getCurrencyByRegion(report.region),
                        hideRowChild: true,
                    }));
                return dataSource;
            }),
        );
    }
}
