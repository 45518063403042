import { IVariationLabel, RoleAssigneeVariations } from "@visoryplatform/threads";
import { Pipe, PipeTransform } from "@angular/core";

import { ConfigTokenExtensionToken } from "@visoryplatform/workflow-core";

@Pipe({ name: "workflowVariationStepLabel" })
export class WorkflowVariationStepLabelPipe implements PipeTransform {
    private roleVariationLabel = "Role automation";
    private variationLabels = {
        [ConfigTokenExtensionToken.SLA_VARIATION]: "SLA variation",
        [ConfigTokenExtensionToken.RFI_CARD_VARIATION]: "RFI card variation",
    };

    constructor() {
        const roleVariations = this.getRoleVariations();
        this.variationLabels = { ...this.variationLabels, ...roleVariations };
    }

    transform(variation: IVariationLabel): string {
        const tokenType: string = this.variationLabels[variation.token];

        if (!tokenType) {
            console.warn(`No variation label found for config token: ${variation.token}, ensure config token is setup`);
            return "";
        }

        const variationLabel = `${tokenType} - ${variation.label}`;
        return variationLabel;
    }

    private getRoleVariations(): Record<string, string> {
        const assigneeVariationsArray = Object.values(RoleAssigneeVariations);
        return assigneeVariationsArray.reduce<Record<string, string>>((acc, key) => {
            return {
                ...acc,
                [key]: this.roleVariationLabel,
            };
        }, {});
    }
}
