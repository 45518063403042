import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { environmentCommon } from "src/environments/environment";
import { IGlobalSearchCard } from "@visoryplatform/threads";

const TEXT_HIGHT_COLOR = "#FCE5F1";

@Component({
    selector: "global-search-card",
    templateUrl: "./global-search-card.component.html",
    styleUrls: ["./global-search-card.component.scss"],
})
export class GlobalSearchCardComponent implements OnChanges {
    @Input() message: IGlobalSearchCard;
    @Input() searchTerm;

    quillStyles = environmentCommon.quillConfig.styling;
    textContent = "";

    ngOnChanges(_changes: SimpleChanges): void {
        if (this.message?.description && this.searchTerm) {
            this.textContent = this.highlightText(this.message.description, this.searchTerm);
        } else {
            this.textContent = this.message.description;
        }
    }

    highlightText(text: string, searchTerm: string): string {
        const searchTerms = searchTerm.split(" ");
        const termsMatcher = searchTerms.join("|");
        const regex = new RegExp(`(${termsMatcher})`, "gi");
        return text.replace(regex, `<span style='background-color: ${TEXT_HIGHT_COLOR};'>$1</span>`);
    }
}
