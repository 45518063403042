export const commonModalConfig = {
    disableClose: false,
    backdropClass: "modal-backdrop",
    panelClass: ["threads-sidebar", "mat-dialog-no-styling"],
    closeOnNavigation: true,
    maxWidth: "100%",
    maxHeight: "100%",
    minHeight: "100%",
    height: "100vh",
    data: {},
};
