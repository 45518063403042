import { Component, Inject } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import {
    environmentCommon,
    EnvironmentSpecificConfig,
} from "projects/portal-modules/src/lib/environment/environment.common";
import { ICustomer, ICustomerUpdateParams } from "@visoryplatform/payments-service-sdk";
import { ENVIRONMENT } from "src/app/injection-token";
import { PaymentService } from "projects/default-plugins/payment/services/payment.service";

@Component({
    selector: "app-edit-billing-contact-details",
    templateUrl: "./edit-billing-contact-details.component.html",
    styleUrls: ["./edit-billing-contact-details.component.scss"],
})
export class EditBillingContactDetailsComponent {
    form = new UntypedFormGroup({
        name: new UntypedFormControl("", [Validators.required]),
        // TODO: this is disabled pending ED-826
        // email: new UntypedFormControl("", [Validators.required])
        address: new UntypedFormControl("", [Validators.required]),
        city: new UntypedFormControl("", [Validators.required]),
        postcode: new UntypedFormControl("", [Validators.required]),
        state: new UntypedFormControl("", [Validators.required]),
        country: new UntypedFormControl(null, [Validators.required]),
    });

    readonly theme = this.environment.theme;

    errorMessage: any;
    loading: any;
    acceptedCountries = environmentCommon.acceptedCountries;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: { name: string; email: string; customerId: string; address: ICustomer["address"] },
        private dialogRef: MatDialogRef<EditBillingContactDetailsComponent>,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private paymentService: PaymentService,
    ) {
        if (data.name && data.email && data.address) {
            const { line1, city, postal_code: postcode, state, country } = data.address;
            this.form.setValue({
                name: data.name,
                address: line1,
                city,
                postcode,
                state,
                country,
                // TODO: this is disabled pending ED-826
                // email: data.email
            });
        }
    }

    close() {
        this.dialogRef.close(false);
    }

    async update() {
        this.loading = true;
        try {
            const UntypedFormControls = this.form.controls;
            const details: ICustomerUpdateParams = {
                name: UntypedFormControls.name.value,
                address: {
                    city: UntypedFormControls.city.value,
                    country: UntypedFormControls.country.value,
                    line1: UntypedFormControls.address.value,
                    postal_code: UntypedFormControls.postcode.value,
                    state: UntypedFormControls.state.value,
                },
            };
            const updateCustomer = await this.paymentService.updateCustomer(this.data.customerId, details).toPromise();
            this.dialogRef.close(updateCustomer);
            this.loading = false;
        } catch (err) {
            this.errorMessage = "Sorry, something went wrong";
            this.loading = false;
        }
    }
}
