import { Component } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";

@Component({
    selector: "app-confirm-delete-dialog",
    templateUrl: "./confirm-delete-dialog.component.html",
    styleUrls: ["./confirm-delete-dialog.component.scss"],
})
export class ConfirmDeleteDialogComponent {
    readonly gaEvents = GA_EVENTS;

    constructor(private dialogRef: MatDialogRef<ConfirmDeleteDialogComponent>) {}

    acceptPrompt() {
        this.dialogRef.close(true);
    }

    close() {
        this.dialogRef.close();
    }
}
