<loader-icon
    *ngIf="loader.counter"
    color="light"
    [showBackground]="true"
></loader-icon>

<sidebar-modal-layout
    header="New request"
    (close)="close()"
>
    <div
        class="customer-request-inner-container"
        [formGroup]="form"
    >
        <div class="customer-request-body">
            <div class="customer-request-subtitle">
                Let us know how we can help! Your Visory team will respond as soon as possible.
            </div>

            <div
                class="customer-request-account-dropdown"
                *ngIf="accounts$ | async; let accounts"
            >
                <ng-container *ngIf="accounts.length > 1">
                    <label class="fx-form-label">Account:</label>
                    <div class="v-select-container">
                        <v-select
                            placeholder="Select an account"
                            [options]="accounts"
                            [optionContent]="accountDropdownOptionContent"
                            [tuiTextfieldCleaner]="false"
                            formControlName="account"
                        >
                        </v-select>

                        <ng-template
                            #accountDropdownOptionContent
                            let-option
                        >
                            {{ option.label }}
                        </ng-template>
                    </div>
                    <div class="customer-request-account-dropdown-subtitle">
                        Please select the account this request relates to.
                    </div>
                </ng-container>
            </div>

            <div class="quill-modal">
                <quill-editor-wrapper
                    [message]="form.controls.message"
                    [autoFocusOnInit]="!isMobileView"
                    [messageSizeQuotaInKB]="messageSizeQuotaInKB"
                ></quill-editor-wrapper>
            </div>

            <div class="customer-request-footer">
                <div class="customer-request-footer-buttons customer-request-footer-buttons--mobile">
                    <button
                        [analyticsClick]="gaEvents.CUSTOMER_REQUEST_CREATE_CANCEL"
                        (click)="close()"
                        class="fx-btn fx-btn--white"
                    >
                        Cancel
                    </button>
                    <button
                        class="fx-btn fx-btn--primary"
                        role="button"
                        [analyticsClick]="gaEvents.CUSTOMER_REQUEST_CREATE_SEND"
                        (click)="create()"
                        [disabled]="!form.valid || loader.counter"
                    >
                        <i class="fx-btn-icon las la-paper-plane"></i>
                        Send
                    </button>
                </div>
            </div>
        </div>
    </div>
</sidebar-modal-layout>
