import { CdkStep } from "@angular/cdk/stepper";
import { Component, Input, QueryList } from "@angular/core";

@Component({
    selector: "stepper-breadcrumbs",
    templateUrl: "./stepper-breadcrumbs.component.html",
    styleUrls: ["./stepper-breadcrumbs.component.scss"],
})
export class StepperBreadcrumbsComponent {
    @Input() steps: QueryList<CdkStep>;
    @Input() selectedIndex: number;
}
