import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { QuillModules } from "ngx-quill";
import matchUrl from "match-url-wildcard";
import { filter, map, switchMap, takeUntil } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { environmentCommon, EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { SubscriberBaseComponent } from "../../../shared/components/subscriber-base.component";
import { PermissionService } from "../../services/permissions.service";
import { AuthService } from "../../../findex-auth";

@Component({
    selector: "quill-editor-wrapper",
    templateUrl: "./quill-editor-wrapper.component.html",
    styleUrls: ["./quill-editor-wrapper.component.scss"],
})
export class QuillEditorWrapperComponent extends SubscriberBaseComponent implements OnInit {
    @Output() userInputEmitter = new EventEmitter<FormControl<string>>();
    @Output() userBlurEmitter = new EventEmitter<FormControl>();
    @Output() error = new EventEmitter<boolean>();

    @Input() message: FormControl;
    @Input() placeholder: string;
    @Input() inline = false;
    @Input() readOnly = false;
    @Input() autoFocusOnInit = true;
    @Input() messageSizeQuotaInKB = 200;

    toolbar = false;
    modules: QuillModules = environmentCommon.quillConfig.toolbarState.withToolbar;
    quillStyles = environmentCommon.quillConfig.styling;
    characterError: boolean;

    constructor(
        private authService: AuthService,
        private permissionService: PermissionService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {
        super();
    }

    ngOnInit() {
        this.authService
            .getUser()
            .pipe(
                filter((user) => !!user),
                map((user) => user.globalRole),
                switchMap((role) => this.permissionService.checkPermissions(role, "CreateHyperLink")),
                filter((hasPermission) => !!hasPermission),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe(() => this.setToolbarWithLink());
    }

    toggleToolbar() {
        if (!this.readOnly) {
            this.toolbar = !this.toolbar;
        }
    }

    autoFocus(quill: any) {
        if (!this.readOnly && this.autoFocusOnInit) {
            quill.focus();
        }
    }

    onChange() {
        this.validateMessageInput();
        this.userInputEmitter.emit(this.message);
    }

    validateMessageInput() {
        const message = this.message.value;
        const messageSize = this.calculateMessageSizeInKB(message);
        const isMessageSizeNotValid = messageSize > this.messageSizeQuotaInKB;

        this.characterError = isMessageSizeNotValid;
        this.error.emit(isMessageSizeNotValid);
    }

    private setToolbarWithLink() {
        const { withToolbar } = environmentCommon.quillConfig.toolbarState;
        const isUrlValid = (url: string) => {
            const { whitelistedUrls } = this.environment;
            return whitelistedUrls.some((rule) => matchUrl(url, rule));
        };
        const linkHandler = function (value: any) {
            if (!value) {
                return;
            }

            const href = prompt("Enter the URL");
            if (isUrlValid(href)) {
                this.quill.format("link", href, "user");
            } else {
                this.quill.format("link", false, "user");
            }
        };

        this.modules = {
            toolbar: {
                container: [...withToolbar.toolbar, ["link"]],
                handlers: {
                    link: linkHandler,
                },
            },
            imageCompress: {
                ...withToolbar.imageCompress,
            },
        };
    }

    private calculateMessageSizeInKB(payload: string): number {
        if (!payload) {
            return 0;
        }

        const bytesInAKB = 1024;
        const payloadLength = payload.length;
        const kb = (payloadLength / bytesInAKB).toFixed(2);

        return Number(kb);
    }
}
