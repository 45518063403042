export enum LaunchDarklyFeatureFlags {
    EnableInternalChat = "enable-internal-chat",
    EnableMemories = "enable-memories",
    EnableTransactionQueries = "enable-transaction-queries",
    EnableCustomerOnboarding = "enable-customer-onboarding",
    EnableDeplhiAlerts = "enable-delphi-alerts",
    EnableTransformersCard = "enable-transformer-card-type",
    EnableUniversalInsights = "enable-universal-insights",
    EnableWorkflowDashboardDueDate = "enable-workflow-dashboard-due-date",
    EnableWorkflowDashboardTasks = "enable-workflow-dashboard-tasks",
    EnableWorkflowListAndStepper = "enable-workflow-list-and-stepper",
    EnableConvertMessageToWorkflow = "enable-convert-message-to-workflow",
    EnableAIAssistant = "enable-ai-assistant",
    EnableDelphiBranding = "enable-delphi-branding",
    EnableWorkflowRelevantMemories = "enable-workflow-relevant-memories",
    EnableBulkChangeAssignees = "enable-bulk-change-assignees",
    EnableMonthEndWorkflowWithRoles = "enable-month-end-workflow-with-roles",
    EnableBillApproval = "enable-bills-approval",
    EnableForwardingMessageOrReply = "enable-forwarding-a-message-or-reply",
    EnableWorkflowResourceAllocation = "enable-workflow-resource-allocation",
    EnableCustomerNewRequest = "enable-customer-new-request",
    EnableGlobalCustomerNewRequest = "enable-global-customer-new-request",
    EnableNewAccountWorkflowsTab = "enable-new-account-workflows-tab",
    EnableQualityTeamRole = "enable-quality-team-role",
    EnableSuccessManagerRole = "enable-success-manager-role",
    EnablePartnerRole = "enable-partner-role",
    EnablePartnerFindexRole = "enable-partner-findex-role",
    EnableLeadRole = "enable-lead-role",
    EnableExpertRole = "enable-expert-role",
    EnableGlobalSearch = "enable-global-search",
}
