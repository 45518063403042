<thread-card
    [title]=""
    [thread]="cardResources.thread$ | async"
    [card]="cardResources.card$ | async"
    [loading]="!!loader.counter"
    [inCardReplies]="false"
    [isInternal]="true"
>
    <div
        *ngIf="cardResources.state$ | async as state"
        (click)="openModal()"
        [ngClass]="{ 'instructions-panel--active': isThreadActive$ | async }"
        class="instructions-panel"
    >
        <div class="instructions-panel-header">
            <h4 class="instructions-heading">{{ state.label }}</h4>
        </div>
    </div>

    <button
        *ngIf="hasPermissionToAction$ | async"
        class="instructions-button fx-btn fx-btn--primary"
        (click)="openModal()"
    >
        REVIEW
    </button>

    <div card-loader>
        <loader-icon color="light"></loader-icon>
    </div>
</thread-card>
