import { Component, Inject, Input, OnChanges, SimpleChanges } from "@angular/core";
import { RelevantMemories } from "@visoryplatform/threads";
import { Observable } from "rxjs";
import { MemoriesService } from "../../services/memories.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { ModalRelevantMemoriesComponent } from "../modal-relevant-memories/modal-relevant-memories.component";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { FeatureFlagService } from "projects/portal-modules/src/lib/feature-flags/services/feature-flags.service";
import { LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";

@Component({
    selector: "banner-relevant-memories",
    templateUrl: "./banner-relevant-memories.component.html",
    styleUrls: ["./banner-relevant-memories.component.scss"],
})
export class BannerRelevantMemoriesComponent implements OnChanges {
    @Input() threadId: string;
    @Input() accountId: string;

    loader = new Loader();
    contextMemories$: Observable<RelevantMemories>;
    brandingEnabled$: Observable<boolean>;
    aiName: string;

    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private memoriesService: MemoriesService,
        private dialog: MatDialog,
        private featureFlagService: FeatureFlagService,
    ) {
        this.aiName = this.environment.aiName;
        this.brandingEnabled$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableDelphiBranding);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.threadId) {
            this.contextMemories$ = this.loader.wrap(this.memoriesService.getThreadRelevantMemories(this.threadId));
        }
    }

    openModal(contextMemory: RelevantMemories): void {
        const data = {
            contextMemory,
            accountId: this.accountId,
        };

        const config = {
            disableClose: false,
            backdropClass: "modal-backdrop",
            panelClass: ["threads-sidebar", "mat-dialog-no-styling"],
            closeOnNavigation: true,
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            height: "100vh",
            width: "1200px",
            data,
        };

        this.dialog.open(ModalRelevantMemoriesComponent, config);
    }
}
