import * as Sentry from "@sentry/angular";

import {
    ACCOUNT_ROUTE_LIBRARY,
    APP_ROUTE_LIBRARY,
    BANNER_LIBRARY,
    CARD_LIBRARY,
    CREATE_CARD_LIBRARY,
    ENVIRONMENT,
    EXTENSION_MENU_LIBRARY,
    FOCUS_WIZARD_LIBRARY,
    INSIGHTS_ROUTE_LIBRARY,
    MOBILE_VERIFY_SERVICE,
    PROFILE_ROUTE_LIBRARY,
    SENTRY_ERROR_HANDLER,
    TASK_ACTION_LIBRARY,
    THEME,
    THREAD_LIBRARY,
    WORKFLOW_CONFIGURATION_LIBRARY,
} from "./injection-token";
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import {
    FreshmarketerAnalyticService,
    GaAnalyticsService,
    GtagAnalyticsService,
    HotjarAnalyticsService,
} from "projects/portal-modules/src/lib/analytics";
import { MessageService, PortalUiModule } from "@visoryplatform/portal-ui";

import { ANALYTICS_SERVICE } from "projects/portal-modules/src/lib/analytics/services/IAnalyticsService";
import { AppComponent } from "./app.component";
import { AppMaterialModule } from "./app-material.module";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { CognitoMobileVerifyService } from "projects/portal-modules/src/lib/shared/services/mobile-verify/cognito-mobile-verify.service";
import { CreateThreadModule } from "projects/portal-modules/src/lib/threads-ui/modules/create-thread";
import { FindexAuthModule } from "projects/portal-modules/src/lib/findex-auth";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui";
import { HttpClientModule } from "@angular/common/http";
import { IllustrationsModule } from "projects/portal-modules/src/lib/illustrations";
import ImageCompress from "quill-image-compress";
import { Libraries } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MsalRedirectComponent } from "@azure/msal-angular";
import { NotificationsModule } from "projects/portal-modules/src/lib/notifications";
import { OPEN_MEASURES_ENDPOINT } from "projects/default-plugins/insights/services/open-measures.service";
import { OverlayModule } from "@angular/cdk/overlay";
import { PluginsModule } from "projects/portal-modules/src/lib/plugins";
import Quill from "quill";
import { QuillModule } from "ngx-quill";
import { ReactiveFormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { SharedModule } from "projects/portal-modules/src/lib/shared/shared.module";
import { SharedPluginsModule } from "projects/default-plugins/shared-plugins.module";
import { ShepherdService } from "angular-shepherd";
import { SigmaModule } from "src/solutions/sigma";
import { ThreadsErrorHandler } from "../../projects/portal-modules/src/lib/shared/services/threads-error-handler";
import { ThreadsUiModule } from "projects/portal-modules/src/lib/threads-ui";
import { TuiRootModule } from "@taiga-ui/core";
import { UserProfileModule } from "projects/portal-modules/src/lib/user-profile/user-profile.module";
import { VaultModule } from "@visoryplatform/vault";
import { environment } from "../environments/environment";
import { environmentCommon } from "../../projects/portal-modules/src/lib/environment/environment.common";
import { themeFactory } from "./app-theme-factory";
import { CustomerRequestModule } from "projects/portal-modules/src/lib/customer-request/customer-request.module";

Quill.register("modules/imageCompress", ImageCompress);

@NgModule({
    declarations: [AppComponent],
    imports: [
        QuillModule.forRoot({
            modules: {
                toolbar: environmentCommon.quillConfig.toolbarState.withToolbar.toolbar,
            },
        }),
        BrowserModule,
        AppRoutingModule,
        AppMaterialModule,
        BrowserAnimationsModule,
        HttpClientModule,
        OverlayModule,
        FindexAuthModule,
        FindexUiModule,
        IllustrationsModule,
        UserProfileModule,
        ThreadsUiModule,
        SharedModule,
        SigmaModule,
        PortalUiModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        PluginsModule,
        SharedPluginsModule,
        VaultModule,
        CreateThreadModule,
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: environment.enableServiceWorkerRefresh,
            registrationStrategy: "registerImmediately",
        }),
        MatCheckboxModule,
        TuiRootModule,
        NotificationsModule,
        CustomerRequestModule,
    ],
    providers: [
        { provide: Window, useValue: window },
        { provide: Sentry.TraceService, deps: [Router] },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        { provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [Sentry.TraceService], multi: true },
        { provide: SENTRY_ERROR_HANDLER, useValue: Sentry.createErrorHandler({ showDialog: false }) },
        { provide: ErrorHandler, useClass: ThreadsErrorHandler },
        { provide: ShepherdService, useClass: ShepherdService },
        { provide: ENVIRONMENT, useValue: environment },
        { provide: THEME, useFactory: themeFactory, deps: [ENVIRONMENT] },
        { provide: LOCALE_ID, useValue: "en-AU" },
        { provide: OPEN_MEASURES_ENDPOINT, useValue: environment.openMeasures.endpoint },
        { provide: ANALYTICS_SERVICE, useClass: GtagAnalyticsService, multi: true },
        { provide: ANALYTICS_SERVICE, useClass: GaAnalyticsService, multi: true },
        { provide: ANALYTICS_SERVICE, useClass: HotjarAnalyticsService, multi: true },
        { provide: ANALYTICS_SERVICE, useClass: FreshmarketerAnalyticService, multi: true },
        { provide: MOBILE_VERIFY_SERVICE, useClass: CognitoMobileVerifyService },
        {
            provide: CREATE_CARD_LIBRARY,
            useFactory: (libraries: Libraries) => libraries.createCard,
            deps: [Libraries],
        },
        {
            provide: EXTENSION_MENU_LIBRARY,
            useFactory: (libraries: Libraries) => libraries.extensionMenu,
            deps: [Libraries],
        },
        { provide: CARD_LIBRARY, useFactory: (libraries: Libraries) => libraries.cardViews, deps: [Libraries] },
        { provide: THREAD_LIBRARY, useFactory: (libraries: Libraries) => libraries.threadViews, deps: [Libraries] },
        { provide: APP_ROUTE_LIBRARY, useFactory: (libraries: Libraries) => libraries.appRoutes, deps: [Libraries] },
        {
            provide: FOCUS_WIZARD_LIBRARY,
            useFactory: (libraries: Libraries) => libraries.focusWizardViews,
            deps: [Libraries],
        },
        {
            provide: ACCOUNT_ROUTE_LIBRARY,
            useFactory: (libraries: Libraries) => libraries.accountRoutes,
            deps: [Libraries],
        },
        {
            provide: PROFILE_ROUTE_LIBRARY,
            useFactory: (libraries: Libraries) => libraries.profileRoutes,
            deps: [Libraries],
        },
        {
            provide: INSIGHTS_ROUTE_LIBRARY,
            useFactory: (libraries: Libraries) => libraries.insightsRoutes,
            deps: [Libraries],
        },
        {
            provide: TASK_ACTION_LIBRARY,
            useFactory: (libraries: Libraries) => libraries.taskActions,
            deps: [Libraries],
        },
        {
            provide: BANNER_LIBRARY,
            useFactory: (libraries: Libraries) => libraries.banners,
            deps: [Libraries],
        },
        {
            provide: WORKFLOW_CONFIGURATION_LIBRARY,
            useFactory: (libraries: Libraries) => libraries.workflowConfigurationViews,
            deps: [Libraries],
        },
        MessageService,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
