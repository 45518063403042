import { Inject, Injectable, TemplateRef } from "@angular/core";
import { defer, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Account, ContactType, IStaffProfile, Role } from "@visoryplatform/threads";
import { environmentCommon, EnvironmentSpecificConfig } from "../../environment/environment.common";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { ENVIRONMENT } from "src/app/injection-token";
import { ComponentType } from "@angular/cdk/portal";
import { AccountsService } from "../../threads-ui/services/accounts.service";
import { PortalService } from "../../shared/services/portal.service";
import { CheckStaffRoleDialogComponent } from "../components/check-staff-role-dialog/check-staff-role-dialog.component";
import { AppUser, LoginDialogComponent } from "../../findex-auth";

export interface ISignupUserOnboardingDetails {
    threadType: string;
    interestedServices: string[];
    businessName: string;
    staffId: string;
    start: string;
    end: string;
}
export interface SignUpUserPayload {
    givenName: string;
    familyName: string;
    emailAddress: string;
    mobileNumber?: string;
    password: string;
}

export interface SignupBusinessPayload {
    businessName?: string;
    industryTypes?: string[];
    selectedStaff?: IStaffProfile;
    userContactType?: ContactType;
}
interface CreateUserResponse {
    data: { status: string };
    userId?: string;
    message?: string;
}
interface IUserAccountDetails {
    account: Account;
    userContactType: ContactType;
}
@Injectable({
    providedIn: "root",
})
export class OnboardingService {
    constructor(
        private portalService: PortalService,
        private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private accountsService: AccountsService,
        private dialog: MatDialog,
    ) {}

    async createTrialUser(
        details: SignUpUserPayload,
        onboardingDetails?: ISignupUserOnboardingDetails,
    ): Promise<{ errorMessage?: string; signupSuccessful: boolean }> {
        try {
            const { base } = this.environment.sigmaEndpoints;
            const { signUp } = environmentCommon.sigmaEndpoints;
            const url = `${base}${signUp}`;
            const email = details.emailAddress.toLowerCase();
            const emailParam = encodeURIComponent(email);
            const body = {
                givenName: details.givenName,
                familyName: details.familyName,
                emailAddress: email,
                password: details.password,
                mobileNumber: details.mobileNumber,
                userPoolClientId: this.environment.auth.userPoolWebClientId,
                redirectUrl: `${this.environment.registration.redirectUrl}?emailAddress=${emailParam}`,
                errorRedirectUrl: this.environment.errorRedirectUrl,
                themeName: this.environment.appTheme,
                ...onboardingDetails,
                targetTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            };
            const result = await this.http.post<CreateUserResponse>(url, body).toPromise();
            const status = result && result.data ? result.data.status : "ERROR";
            switch (status) {
                case "OK":
                    return { signupSuccessful: true };
                default:
                    return { errorMessage: result.message, signupSuccessful: false };
            }
        } catch (error) {
            const errorMessage = "Sorry, something went wrong";
            return { errorMessage, signupSuccessful: false };
        }
    }

    getCompletionDetails(userId: string): Observable<SignupBusinessPayload> {
        return defer(() => this.lazyInitData(userId));
    }

    updateCompletionDetails(userId: string, details: SignupBusinessPayload): Observable<void> {
        return defer(() => this.patchUserData(userId, details));
    }

    requiresOnboarding(onboardingDetails: SignupBusinessPayload, role: Role): boolean {
        if (!this.environment.featureFlags.requiresOnboarding) {
            return false;
        }
        if (role !== Role.Client && !!role) {
            return false;
        }
        if (!onboardingDetails.businessName || !onboardingDetails.selectedStaff || !onboardingDetails.industryTypes) {
            return true;
        }
        return false;
    }

    initOnboardingCompletion(component: ComponentType<unknown> | TemplateRef<unknown>, step?: string): void {
        if (!this.dialog.openDialogs.length) {
            const options: MatDialogConfig = {
                maxWidth: "100%",
                maxHeight: "100%",
                panelClass: ["fx-onboarding-modal-dialog"],
                disableClose: true,
                closeOnNavigation: true,
                hasBackdrop: true,
                data: {
                    step,
                },
            };
            this.dialog.open(component, options);
        }
    }

    initVisoryOnboardingModal(component: ComponentType<unknown> | TemplateRef<unknown>): void {
        const notLoginDialogs = this.dialog.openDialogs.filter(
            (dialog) => !(dialog.componentInstance instanceof LoginDialogComponent),
        );
        if (!notLoginDialogs.length) {
            const options: MatDialogConfig = {
                maxWidth: "100%",
                maxHeight: "100%",
                panelClass: ["onboarding-wizard-modal-dialog"],
                disableClose: true,
                closeOnNavigation: true,
                hasBackdrop: true,
                autoFocus: false,
            };
            this.dialog.open(component, options);
        }
    }

    checkStaffRole(user: AppUser): void {
        if (user?.id.includes("azuread") && (!user?.globalRole || user?.globalRole === Role.Client)) {
            const options: MatDialogConfig = {
                maxWidth: "420px",
                disableClose: true,
                closeOnNavigation: true,
                hasBackdrop: true,
                autoFocus: false,
            };
            this.dialog.open(CheckStaffRoleDialogComponent, options);
        }
    }

    private async patchUserData(userId: string, updated: SignupBusinessPayload): Promise<void> {
        const userData = await this.lazyInitData(userId);

        for (const key in updated) {
            if (updated[key] == null) {
                continue;
            }
            userData[key] = updated[key];
        }

        await this.portalService.updateContactAccountMetadata(userId, userData);
    }

    private async lazyInitData(userId: string): Promise<SignupBusinessPayload> {
        const accounts = await this.accountsService.listAllAccountsByContact(userId).toPromise();
        if (!accounts) {
            return null;
        }

        //TODO: need UI for multiple accounts/move this page to the account screen
        const primaryAccount = this.findAccount(accounts, userId);

        return {
            businessName: primaryAccount?.account?.label || "",
            industryTypes: [],
            ...primaryAccount?.account?.metadata,
            userContactType: primaryAccount?.userContactType || ContactType.Secondary,
        };
    }

    private findAccount(accounts: Account[], userId: string): IUserAccountDetails {
        const primaryAccount = accounts.find((account) => {
            const primaryContactId = account.metadata?.contactInfo?.primaryContact;
            account.contacts.find((contact) => contact.id === userId && contact.id === primaryContactId);
        });

        if (primaryAccount) {
            return { account: primaryAccount, userContactType: ContactType.Primary };
        }

        const secondaryAccount = accounts.find((account) => {
            const primaryContactId = account.metadata?.contactInfo?.primaryContact;
            account.contacts.find((contact) => contact.id === userId && contact.id !== primaryContactId);
        });
        return { account: secondaryAccount, userContactType: ContactType.Secondary };
    }
}
