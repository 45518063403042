import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { BILL_APPROVAL_CARD_TYPE, BillApprovalCreate, IThreadCard, ITimeline } from "@visoryplatform/threads";
import { DialogRef, DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";
import { ICreateCardEvent } from "projects/portal-modules/src/lib/threads-ui/components/create-card/create-card.component";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { RequestStatuses } from "projects/default-plugins/vault/components/request/constants/request.constants";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { DateTime } from "luxon";
import { Invoice } from "@visoryplatform/copilot";
import { debounceTime, startWith, switchMap, map, shareReplay, take, filter } from "rxjs/operators";
import { CreateBillApprovalCardDescription } from "../../constants";
import { BillApprovalService } from "../../services/bill-approval.service";
import { EMPTY, Observable, Subscription, combineLatest } from "rxjs";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import {
    BILL_APPROVAL_DATE_FORMAT,
    BillApprovalDatePickerFormControl,
    BILL_APPROVAL_DEFAULT_END_DATE,
    BILL_APPROVAL_DEFAULT_START_DATE,
} from "../../interfaces/BillApproval";

@Component({
    selector: "create-bill-approval",
    templateUrl: "./create-bill-approval.component.html",
    styleUrls: ["./create-bill-approval.component.scss"],
})
export class CreateBillApprovalComponent implements OnInit, OnDestroy {
    readonly dateFormat = "yyyy-MM-dd";

    dialogRef: DialogRef;
    thread: ITimeline;
    dialogSub: Subscription;
    response: Invoice[];
    invoices$: Observable<Invoice[]>;
    form: FormGroup<{
        cardDescription: FormControl<string>;
        plannedPaymentDate: FormControl<string>;
        range: FormControl<BillApprovalDatePickerFormControl>;
        invoices: FormArray<FormControl<Invoice>>;
    }>;

    loader = new Loader();
    tableLoader = new Loader();
    RequestStatuses = RequestStatuses;

    enableMemories$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableWorkflowRelevantMemories);

    constructor(
        private injector: Injector,
        private dialogService: DialogService,
        private threadCardService: ThreadCardService,
        private featureFlagService: FeatureFlagService,
        private billApprovalService: BillApprovalService,
    ) {
        const initialStartDate = BILL_APPROVAL_DEFAULT_START_DATE;
        const initialEndDate = BILL_APPROVAL_DEFAULT_END_DATE;
        const plannedPaymentDate = new FormControl<string>("", Validators.required);

        const invoices = new FormArray<FormControl<Invoice>>([]);
        const cardDescription = new FormControl<string>(CreateBillApprovalCardDescription);
        const range = new FormControl<BillApprovalDatePickerFormControl>(
            {
                startDate: initialStartDate,
                endDate: initialEndDate,
            },
            Validators.required,
        );

        this.form = new FormGroup({
            cardDescription,
            invoices,
            plannedPaymentDate,
            range,
        });
    }

    async ngOnInit(): Promise<void> {
        const dialogRef$ = this.dialogService.getRef(this.injector);
        const thread$ = this.dialogService.getData<ICreateCardEvent>(this.injector).pipe(map((data) => data.thread));

        this.dialogSub = combineLatest([dialogRef$, thread$]).subscribe(([dialogRef, thread]) => {
            this.dialogRef = dialogRef;
            this.thread = thread;
        });

        const valueChanges$ = this.form.controls.range.valueChanges.pipe(
            debounceTime(150),
            startWith(this.form.controls.range.value),
        );

        this.invoices$ = combineLatest([thread$, valueChanges$]).pipe(
            filter(([_, formVal]) => !!formVal?.startDate || !!formVal?.endDate),
            switchMap(([thread, formVal]) => this.listInvoices(thread.id, formVal.startDate, formVal.endDate)),
            shareReplay(1),
        );
    }

    ngOnDestroy(): void {
        this.dialogSub?.unsubscribe();
    }

    create(): void {
        const { range, plannedPaymentDate, cardDescription: description } = this.form.value;
        const startDate = range.startDate;
        const endDate = range.endDate;

        const fromDate = DateTime.fromISO(startDate).toFormat(BILL_APPROVAL_DATE_FORMAT);
        const toDate = DateTime.fromISO(endDate).toFormat(BILL_APPROVAL_DATE_FORMAT);
        const plannedDate = DateTime.fromISO(plannedPaymentDate).toFormat(BILL_APPROVAL_DATE_FORMAT);
        const invoices = this.response;

        const payload = {
            label: description,
            invoices,
            fromDate,
            toDate,
            plannedDate,
        };

        this.loader
            .wrap(
                this.threadCardService.createCard<BillApprovalCreate, IThreadCard>(
                    this.thread.id,
                    BILL_APPROVAL_CARD_TYPE,
                    payload,
                ),
            )
            .pipe(take(1))
            .subscribe((card) => this.dialogRef.close(card.id));
    }

    handleUpdate(response: Invoice[]): void {
        this.response = response;
    }

    private listInvoices(threadId: string, startDate: string, endDate: string) {
        const start = this.parseDate(startDate);
        const end = this.parseDate(endDate);

        if (!start || !end || start > end) {
            return EMPTY;
        }

        const startIso: string = start.toFormat(BILL_APPROVAL_DATE_FORMAT);
        const endIso: string = end.toFormat(BILL_APPROVAL_DATE_FORMAT);

        const invoices$ = this.billApprovalService
            .listInvoices(threadId, startIso, endIso)
            .pipe(map((invoices) => invoices.sort((a, b) => this.compareInvoices(a, b))));

        return this.tableLoader.wrap(invoices$);
    }

    private parseDate(date?: string | DateTime): DateTime {
        if (typeof date === "string") {
            return DateTime.fromISO(date);
        } else {
            return date;
        }
    }

    private compareInvoices(a: Invoice, b: Invoice): number {
        const contactCompare = this.compareBillContacts(a, b);
        const areContactsSame = contactCompare === 0;

        if (areContactsSame) {
            return this.compareBillDueDates(a, b);
        }

        return contactCompare;
    }

    private compareBillContacts(a: Invoice, b: Invoice): number {
        const aContact = a?.contact?.name;
        const bContact = b?.contact?.name;

        return aContact?.localeCompare(bContact) || 0;
    }

    private compareBillDueDates(a: Invoice, b: Invoice): number {
        const aDueDate = a?.dueDate;
        const bDueDate = b?.dueDate;

        return aDueDate?.localeCompare(bDueDate) || 0;
    }
}
