import { Component, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChildren } from "@angular/core";
import { Loader } from "../../../shared/services/loader";
import { CardReply, ICardReadStatus, IThread, IThreadCard, ReadStatus } from "@visoryplatform/threads";
import { ReplyComponent } from "../reply/reply.component";
import { UiCardService } from "../../services/ui-card.service";
import { combineLatest, Observable, ReplaySubject } from "rxjs";
import { TaskNotificationsService } from "../../../notifications";
import { filter, map, switchMap } from "rxjs/operators";
import { IReplyUi } from "../../interfaces/IReplyUi";

@Component({
    selector: "thread-card-replies-list",
    templateUrl: "./thread-card-replies-list.component.html",
    styleUrls: ["./thread-card-replies-list.component.scss"],
})
export class ThreadCardRepliesListComponent implements OnChanges, OnInit {
    @ViewChildren("replyComponents") replyComponents: QueryList<ReplyComponent>;

    @Input() replies: CardReply[] = [];
    @Input() thread: IThread;
    @Input() userId: string;
    @Input() card: IThreadCard;
    @Input() readonly = false;

    loader = new Loader();
    // eslint-disable-next-line rxjs/no-ignored-replay-buffer
    repliesSource = new ReplaySubject<CardReply[]>();
    repliesUi$: Observable<IReplyUi[]>;

    constructor(private uiCardService: UiCardService, private taskNotificationsService: TaskNotificationsService) {}

    ngOnInit(): void {
        this.repliesUi$ = this.getRepliesUi(this.thread.id, this.card.id);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.replies && this.replies?.length) {
            this.repliesSource.next(this.replies);
        }
    }

    toggleReplyLoader(isLoading: boolean): void {
        if (isLoading) {
            this.loader.show();
        } else {
            this.loader.hide();
        }
    }

    replyTrack(_index: number, reply: CardReply): string {
        return reply.id;
    }

    private getRepliesUi(threadId: string, cardId: string): Observable<IReplyUi[]> {
        const repliesUi$ = combineLatest([this.getRepliesStatus(threadId, cardId), this.repliesSource]).pipe(
            map(([repliesStatus, replies]) => this.mapToRepliesUi(repliesStatus, replies)),
        );
        return repliesUi$;
    }

    private mapToRepliesUi(repliesStatus: Record<string, ReadStatus>, replies: CardReply[]): IReplyUi[] {
        return replies.map((reply) => this.mapToReplyUi(repliesStatus, reply));
    }

    private mapToReplyUi(repliesStatus: Record<string, ReadStatus>, reply: CardReply): IReplyUi {
        const isNotOwner = reply.actor !== this.userId;
        const replyStatus = reply.id ? repliesStatus[reply.id] : null;
        const isUnresolved = this.getReplyParticipantUnresolved(replyStatus);
        const isUnread = isNotOwner && isUnresolved;
        const channel = this.getReplyChannel(reply);

        const replyUi: IReplyUi = { ...reply, isUnread, isUnresolved, channel, replyStatus };

        return replyUi;
    }

    private getReplyParticipantUnresolved(readStatus: ReadStatus): boolean {
        return readStatus?.unresolved?.participantIds?.includes(this.userId) ?? false;
    }

    private getReplyChannel(reply: CardReply): string {
        const threadId = this.thread.id;
        const cardId = this.card.id;
        return "activity/threads/" + threadId + "/cards/" + cardId + "/cardReplies/" + reply.id + "";
    }

    private getRepliesStatus(threadId: string, cardId: string): Observable<Record<string, ReadStatus>> {
        return this.taskNotificationsService.getCardReadStatus(threadId, cardId).pipe(
            switchMap((readStatus) => this.getCardRepliesReadStatusUpdates(readStatus, threadId, cardId)),
            filter((status) => !!status),
        );
    }

    private getCardRepliesReadStatusUpdates(
        cardReadStatus: ICardReadStatus,
        threadId: string,
        cardId: string,
    ): Observable<Record<string, ReadStatus>> {
        return this.uiCardService.getCardRepliesReadStatusUpdates(cardReadStatus.replies, threadId, cardId);
    }
}
