import { Inject, Injectable } from "@angular/core";
import {
    MatLegacyDialog as MatDialog,
    MatLegacyDialogConfig as MatDialogConfig,
} from "@angular/material/legacy-dialog";
import { ITimeline, IThread, Role } from "@visoryplatform/threads";
import {
    CARD_REPLY_EXTENSION_TYPE,
    CARD_VIEW_EXTENSION_TYPE,
    CardViewExtensionHelpers,
    CREATE_CARD_EXTENSION_TYPE,
    CreateCardExtensionHelpers,
    IExtension,
    InputType,
    IWorkflow,
    IWorkflowAction,
    SystemStepId,
    ValidationResult,
    WorkflowTypesService,
} from "@visoryplatform/workflow-core";
import { combineLatest } from "rxjs";
import { map, take } from "rxjs/operators";
import { CREATE_CARD_LIBRARY } from "src/app/injection-token";
import { CreateCardExtension, ILibrary } from "../../../plugins";
import { TaskActionService } from "../../../shared/components/actionable-card/task-action.service";
import { ThreadUpdateService } from "../../../shared/services/thread-update-service";
import { ThreadCardService } from "../thread-card.service";
import { CardResources } from "../../interfaces/IUiCard";
import { UiCardService } from "../ui-card.service";
import { WorkflowApiService } from "./workflow-api.service";
import { ComponentType } from "@angular/cdk/portal";
import { DialogService } from "../../../shared/services/dialog.service";

type ActionRegistry = {
    [action: string]: {
        component: ComponentType<any>;
        config: MatDialogConfig;
    };
};

type ActionResult = {
    validation: ValidationResult;
    workflow?: IWorkflow;
};

//Max number of app components on a step. Required because we use the modal at the moment
const MAX_OUTPUT_RESOLUTION_RETRIES = 2;

@Injectable({
    providedIn: "root",
})
export class WorkflowExtensionService {
    private readonly actionRegistry: ActionRegistry = {};

    constructor(
        @Inject(CREATE_CARD_LIBRARY) private createCardExtensions: ILibrary<CreateCardExtension>,
        private workflowApi: WorkflowApiService,
        private dialog: DialogService,
        private matDialog: MatDialog,
        private taskActionService: TaskActionService,
        private threadUpdateService: ThreadUpdateService,
        private threadCardService: ThreadCardService,
        private uiCardService: UiCardService,
    ) {}

    async handleAction(
        thread: ITimeline,
        role: Role,
        workflow: IWorkflow,
        stepId: string,
        actionId: string,
    ): Promise<ActionResult> {
        return await this.retryAction(thread, role, workflow, stepId, actionId);
    }

    async cancelTimeline(thread: ITimeline, role: Role): Promise<void> {
        const workflow = thread.workflow;
        const currentStep = workflow.steps[workflow.currentStepId];
        if (!currentStep) {
            console.error("No current step found.");
            return;
        }

        const cancelAction = currentStep.actions.find((action) => action.toStepId === SystemStepId.Cancelled);

        if (!cancelAction) {
            throw new Error("Timeline is not able to be cancelled.");
        }

        await this.handleAction(thread, role, thread.workflow, currentStep.id, cancelAction.id);
    }

    registerAction(action: string, component: ComponentType<any>, config: MatDialogConfig<any>): void {
        this.actionRegistry[action] = {
            component,
            config,
        };
    }

    private async retryAction(
        thread: ITimeline,
        role: Role,
        workflow: IWorkflow,
        stepId: string,
        actionId: string,
        retryCount = 0,
    ): Promise<ActionResult> {
        console.log({ thread, role, workflow, stepId, actionId });

        const action = workflow.steps?.[stepId]?.actions?.find((action) => action.id === actionId);
        if (!action) {
            return null;
        }

        const actionResult = await this.resolveAction(thread, action.id, action.toStepId);
        if (!actionResult) {
            return null;
        }

        const updated = await this.workflowApi.performAction(thread.id, stepId, actionId);
        if (!updated) {
            throw new Error("Sorry, we were not able to transition step");
        }

        if (this.isValidationResult(updated)) {
            return await this.processValidationResult(thread, role, workflow, stepId, action, updated, retryCount);
        } else {
            const validation: ValidationResult = { isValid: true };
            return { validation, workflow: updated };
        }
    }

    private async processValidationResult(
        thread: ITimeline,
        role: Role,
        workflow: IWorkflow,
        stepId: string,
        action: IWorkflowAction,
        validation: ValidationResult,
        retryCount = 0,
    ): Promise<ActionResult> {
        if (validation.isValid === false) {
            try {
                const extensionsToRun = validation.messages
                    .filter((validation) => validation?.runWithExtensionId)
                    .map((validation) => validation?.runWithExtensionId);

                const result = extensionsToRun.length
                    ? await this.resolveOutputsWithIds(stepId, workflow, extensionsToRun, thread, role)
                    : await this.resolveAllOutputs(thread, role, workflow, stepId);

                if (!result) {
                    return null;
                }

                if (!action.autoTransition) {
                    if (retryCount >= MAX_OUTPUT_RESOLUTION_RETRIES) {
                        return { workflow, validation };
                    }

                    return this.retryAction(thread, role, workflow, stepId, action.id, retryCount + 1);
                }
            } catch (err) {
                console.error("Could not resolve outputs");
                throw err;
            }
        }

        return { validation: validation };
    }

    private isValidationResult(result: IWorkflow | ValidationResult): result is ValidationResult {
        return "isValid" in result;
    }

    private async resolveOutputsWithIds(
        stepId: string,
        workflow: IWorkflow,
        extensionsToRun: string[],
        thread: ITimeline,
        role: Role,
    ): Promise<boolean> {
        const currentStepId = stepId;
        const currentStep = workflow.steps[stepId];
        for (const extensionid of extensionsToRun) {
            const findExtension = currentStep.extensions.find((extension) => extension.id == extensionid);
            const result = await this.runExtension(thread, currentStepId, role, findExtension);
            if (!result) {
                return false;
            }
        }
        return true;
    }

    private async resolveAllOutputs(
        thread: ITimeline,
        role: Role,
        workflow: IWorkflow,
        currentStepId: string,
    ): Promise<boolean> {
        const step = workflow.steps[currentStepId];

        for (const extension of step.extensions) {
            const result = await this.runExtension(thread, currentStepId, role, extension);
            if (!result) {
                return false;
            }
        }

        return true;
    }

    //TODO: replace with workflow extension, time constraints
    /** @deprecated */
    private async resolveAction(thread: IThread, actionId: string, toStepId: string): Promise<boolean> {
        const actionHandler = this.actionRegistry[toStepId];
        if (actionHandler) {
            const validationResult = await this.workflowApi.isActionValid(
                thread.id,
                thread.workflow.currentStepId,
                actionId,
            );

            //Need to action the TODO above, this caters for steps that have validators AND this old action thing
            //Lets the validator run first
            if (validationResult?.isValid) {
                const config = {
                    ...actionHandler.config,
                    data: { ...actionHandler.config.data, thread, workflowStepName: toStepId },
                };

                return await this.matDialog.open<boolean>(actionHandler.component, config).afterClosed().toPromise();
            }
        }
        return true;
    }

    private async runExtension(thread: ITimeline, stepId: string, role: Role, extension: IExtension): Promise<boolean> {
        if (extension?.type === CREATE_CARD_EXTENSION_TYPE) {
            return await this.createCardExtension(thread, stepId, role, extension);
        } else if (extension?.type === CARD_VIEW_EXTENSION_TYPE) {
            return await this.viewCardExtension(thread, stepId, role, extension);
        } else if (extension?.type === CARD_REPLY_EXTENSION_TYPE) {
            return await this.replyCardExtension(thread, stepId, extension);
        }
        return true;
    }

    private async replyCardExtension(thread: ITimeline, stepId: string, extension: IExtension): Promise<boolean> {
        const inputs = await this.workflowApi.resolveInputs(thread.id, stepId, extension.id);

        const cardId = CardViewExtensionHelpers.getCardId(inputs);
        if (!cardId) {
            console.warn("No card ID found for extension", thread.id, stepId);
            return true;
        }

        const extensionInput = WorkflowTypesService.firstStaticInput(extension?.inputs);
        if (WorkflowTypesService.isCardReplyExtensionInput(extensionInput)) {
            const replyData = extensionInput.data.replyData;
            const cardReply = await this.threadCardService.replyCard(thread.id, cardId, replyData.message).toPromise();

            await this.workflowApi.executeExtension(thread.id, stepId, extension.id, { replyId: cardReply.id });
        }

        return true;
    }

    private async viewCardExtension(
        thread: ITimeline,
        stepId: string,
        role: Role,
        extension: IExtension,
    ): Promise<boolean> {
        if (!thread) {
            return true;
        }
        const inputs = await this.workflowApi.resolveInputs(thread.id, stepId, extension.id);
        const cardId = CardViewExtensionHelpers.getCardId(inputs);
        if (!cardId) {
            console.warn("No card ID found for extension", thread.id, stepId);
            return true;
        }

        const viewCardInput = inputs.find(
            (input) => input?.data != null && typeof input.data === "object" && "extensionAction" in input?.data,
        );
        const extensionType = viewCardInput.data["extensionAction"];
        const modalData = viewCardInput.data["modalData"];
        const cardResources = await this.getCardResources(thread, cardId, role);
        return await this.taskActionService.action(extensionType, cardResources, modalData);
    }

    private async getCardResources(thread: ITimeline, cardId: string, role: Role): Promise<CardResources> {
        const thread$ = this.threadUpdateService.getUpdatesByThread(thread);
        const card$ = this.threadCardService.getCard(thread.id, cardId);

        return combineLatest([card$, thread$])
            .pipe(
                take(1),
                map(([card]) => this.uiCardService.getCardResources(thread.id, thread$, card, role)),
            )
            .toPromise();
    }

    private async createCardExtension(
        thread: ITimeline,
        stepId: string,
        role: Role,
        extension: IExtension,
    ): Promise<boolean> {
        if (extension?.outputs?.some((output) => CreateCardExtensionHelpers.isCardIdExtensionOutput(output))) {
            console.warn("Card has already been created, skipping extension");
            return true;
        }

        const extensionInput = WorkflowTypesService.firstStaticInput(extension?.inputs);

        if (extensionInput !== null && extensionInput.type === InputType.Static) {
            const cardType = extensionInput?.data?.extensionType;
            const modalData = extensionInput?.data?.modalData;
            const createCard = this.createCardExtensions.resolve(cardType);
            const disableEmails = true;

            const data = { thread, stepId, role, ...createCard?.data, ...modalData, disableEmails };

            const cardId = await this.dialog
                .open<string>(createCard.componentRef, {
                    data,
                    ...createCard.config,
                })
                .pipe(take(1))
                .toPromise();

            if (cardId) {
                await this.workflowApi.executeExtension(thread.id, stepId, extension.id, { cardId });
            } else {
                return false;
            }
        }

        return true;
    }
}
