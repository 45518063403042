import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, Inject, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { VaultService } from "@visoryplatform/vault";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { Observable } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { IVaultItem } from "../../interfaces/IVaultItem";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { Role } from "@visoryplatform/threads";

export interface DocumentPreviewModalData {
    item: IVaultItem;
    isSigned: boolean;
    isSignable: boolean;
}

@Component({
    selector: "app-document-preview-modal",
    templateUrl: "./document-preview.component.html",
    styleUrls: ["./document-preview.component.scss"],
})
export class DocumentPreviewModalComponent implements OnInit {
    trustedHtml$: Observable<SafeHtml>;
    isSignable = false;

    role$: Observable<Role>;

    name = "";
    title = "";
    agreed = false;
    errorMessage: string;

    userId: string;
    readonly theme = this.environment.theme;

    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        @Inject(MAT_DIALOG_DATA) private data: DocumentPreviewModalData,
        private domSanitizer: DomSanitizer,
        private dialogRef: MatDialogRef<DocumentPreviewModalComponent>,
        private vaultService: VaultService,
        private http: HttpClient,
        private elementRef: ElementRef,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        this.role$ = this.authService.getUser().pipe(
            filter((user) => !!user),
            map((user) => user.globalRole),
        );

        this.loadPreview();
    }

    loadPreview() {
        const { vaultId, fileId, filename } = this.data.item.previewItem;
        this.trustedHtml$ = this.vaultService.getDownloadUrl(vaultId, fileId, filename).pipe(
            switchMap((url) => this.http.get<string>(url)),
            map((htmlText) => this.domSanitizer.bypassSecurityTrustHtml(htmlText)),
            tap(() => this.elementRef.nativeElement.scrollIntoView()),
        );

        this.isSignable = this.data.isSignable;
    }

    close() {
        this.dialogRef.close();
    }

    accept() {
        this.errorMessage = "";

        if (!this.name || !this.title || !this.agreed) {
            this.errorMessage = "Please ensure all fields are complete.";
            return;
        }

        this.dialogRef.close({
            name: this.name,
            title: this.title,
            agreed: this.agreed,
        });
    }

    closeModal() {
        return this.dialogRef.close();
    }
}
