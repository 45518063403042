import { Pipe, PipeTransform } from "@angular/core";

import { IWorkflowToken } from "@visoryplatform/threads";

@Pipe({ name: "workflowVariationConfigTokenLabels" })
export class WorkflowVariationConfigTokenLabelsPipe implements PipeTransform {
    transform(workflowTokens?: IWorkflowToken[]): string[] | null {
        if (!workflowTokens?.length) {
            return null;
        }

        const mappedTokens = workflowTokens.map((token) => token.label);
        return mappedTokens;
    }
}
