import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { IVaultListItem, Role } from "@visoryplatform/threads";
import { VaultService } from "@visoryplatform/vault";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { RenameFileModalComponent } from "projects/portal-modules/src/lib/threads-ui/components/create-card/rename-file-modal/rename-file-modal.component";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { Observable, of } from "rxjs";
import { filter, map, shareReplay, switchMap } from "rxjs/operators";
import { environmentCommon } from "src/environments/environment";
import { DocumentCategory } from "../../enums/DocumentCategory";
import { VaultCardDeleteItem, VaultCardRenameItem } from "../../types/EditCardRequests";
import { ConfirmDeleteDialogComponent } from "../confirm-delete-dialog/confirm-delete-dialog.component";
import { Router } from "@angular/router";
import { EmptyStateImageType } from "projects/portal-modules/src/lib/empty-state/components/empty-state.component";
import { MatSortable } from "@angular/material/sort";
import { IPaginatorSort } from "projects/portal-modules/src/lib/shared/interfaces/IPaginatorSort";

enum VaultListTableHeaders {
    FileName = "File name",
    AccountName = "Account name",
    Workflow = "Workflow",
    DateAdded = "Date added",
    UploadedBy = "Uploaded by",
}

enum VaultType {
    VaultRequest = "vault-request",
    Vault = "vault",
}

@Component({
    selector: "app-vault-list-table",
    templateUrl: "./vault-list-table.component.html",
    styleUrls: ["./vault-list-table.component.scss"],
})
export class VaultListTableComponent implements OnInit, OnChanges {
    @Input() documents: IVaultListItem[];
    @Input() loader = new Loader();

    @Output() sortByClick = new EventEmitter<IPaginatorSort>();

    readonly vaultType = VaultType;
    readonly gaEvents = GA_EVENTS;
    readonly documentCategory = DocumentCategory;
    readonly tableHeaders = VaultListTableHeaders;
    readonly emptyStateImages = EmptyStateImageType;

    tableData = new MatTableDataSource<IVaultListItem>();
    userId$: Observable<string>;
    role$: Observable<Role>;
    currentPage = 1;
    itemsPerPage = 10;
    totalPages: number;

    constructor(
        private dialog: MatDialog,
        private router: Router,
        private authService: AuthService,
        private cardService: ThreadCardService,
        private vaultService: VaultService,
    ) {}

    ngOnInit(): void {
        this.loader.show();

        const user$ = this.authService.getUser().pipe(
            filter((user) => !!user),
            shareReplay(1),
        );

        this.role$ = user$.pipe(
            filter((user) => !!user),
            map((user) => user.globalRole),
        );

        this.userId$ = user$.pipe(
            filter((user) => !!user),
            map((user) => user.id),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.documents?.currentValue) {
            this.tableData.data = this.getPaginatedData();
            this.resetTable();
            if (this.loader.counter) {
                this.loader.hide();
            }
        }
    }

    async download(itemId: string, fileName: string, vaultId: string): Promise<void> {
        const downloadWindow = window.open("", "_self");
        downloadWindow.location.href = await this.vaultService.getDownloadUrl(vaultId, itemId, fileName).toPromise();
    }

    async downloadItem(itemId: string, fileName: string, vaultId: string): Promise<void> {
        this.loader.show();
        await this.download(itemId, fileName, vaultId);
        this.loader.hide();
    }

    getPaginatedData(): IVaultListItem[] {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;

        this.getTotalPages();

        return this.documents.slice(startIndex, endIndex);
    }

    prevPage(): void {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.tableData.data = this.getPaginatedData();
        }
    }

    nextPage(): void {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
            this.tableData.data = this.getPaginatedData();
        }
    }

    getTotalPages(): void {
        this.totalPages = Math.ceil(this.documents?.length / this.itemsPerPage);
    }

    resetTable(): void {
        this.currentPage = 1;
    }

    renameDocument(threadId: string, document: IVaultListItem): void {
        this.dialog
            .open(RenameFileModalComponent, {
                data: { description: document.file.displayName },
                panelClass: ["centered-modal"],
            })
            .afterClosed()
            .pipe(
                switchMap((updatedDescription: string) => {
                    if (!updatedDescription || updatedDescription === document.file.displayName) {
                        return of(null);
                    }

                    const { vault } = environmentCommon.cardsEndpoints;
                    const data: VaultCardRenameItem = {
                        vaultId: document.vaultId,
                        fileId: document.file.id,
                        editAction: "rename",
                        displayName: updatedDescription,
                    };

                    return this.loader.wrap(
                        this.cardService.editCard<VaultCardRenameItem>(threadId, vault, document.cardId, data),
                    );
                }),
            )
            .subscribe();
    }

    deleteItem(threadId: string, vaultId: string, fileId: string, cardId: string): void {
        this.dialog
            .open(ConfirmDeleteDialogComponent, {
                panelClass: ["centered-modal"],
            })
            .afterClosed()
            .pipe(
                switchMap((val) => {
                    if (!val) {
                        return of(null);
                    }

                    const { vault } = environmentCommon.cardsEndpoints;
                    const data: VaultCardDeleteItem = {
                        vaultId: vaultId,
                        fileId: fileId,
                        editAction: "delete",
                    };

                    return this.loader.wrap(
                        this.cardService.editCard<VaultCardDeleteItem>(threadId, vault, cardId, data),
                    );
                }),
            )
            .subscribe();
    }

    async sign(threadId: string, cardId: string, vaultId: string, fileId: string): Promise<void> {
        await this.router.navigate(["timelines", threadId, "cards", cardId], {
            queryParams: {
                vaultId,
                fileId,
            },
        });
    }

    onSortByClick(config: MatSortable): void {
        this.sortByClick.emit({ sort: config.id, order: config.start });
    }

    trackDocument(_index: number, document: IVaultListItem): string {
        return `${document.cardId}/${document.vaultId}/${document.file?.id}/${document.file?.fileName}`;
    }
}
