import { NgModule } from "@angular/core";
import { AllowWhitelistLinksPipe } from "./allow-whitelist-links.pipe";
import { DateTimePipe } from "./date-time.pipe";
import { FeatureEnabledPipe } from "./feature-enabled.pipe";
import { FindInPipe } from "./find-in.pipe";
import { FormatOrdinalDatePipe } from "./format-ordinal-date.pipe";
import { IsAllSelectedPipe } from "./is-all-selected.pipe";
import { IsSystemNamePipe } from "./is-system-name.pipe";
import { OrderByPipe } from "./order-by.pipe";
import { ParseStringDatePipe } from "./parse-string-date.pipe";
import { PermissionsPipe } from "./permissions.pipe";
import { ReversePipe } from "./reverse.pipe";
import { RruleFrequencyDescriptionPipe } from "./rrule-frequency-description.pipe";
import { RruleFrequencyPipe } from "./rrule-frequency.pipe";
import { SentenceCasePipe } from "./sentence-case.pipe";
import { StripHtmlLinksPipes } from "./strip-html-links.pipe";
import { StripHtmlPipe } from "./strip-html.pipe";
import { ThreadRolePipe } from "./thread-role.pipe";
import { TimezoneLabelPipe } from "./timezone-label.pipe";
import { TrackPropertyPipe } from "./track-property.pipe";
import { TruncatePipe } from "./truncate-string.pipe";
import { UserShortNamePipe } from "./user-short-name.pipe";

const exportDeclarations = [
    UserShortNamePipe,
    FindInPipe,
    FormatOrdinalDatePipe,
    IsSystemNamePipe,
    ParseStringDatePipe,
    PermissionsPipe,
    ReversePipe,
    StripHtmlPipe,
    StripHtmlLinksPipes,
    AllowWhitelistLinksPipe,
    IsAllSelectedPipe,
    TruncatePipe,
    TrackPropertyPipe,
    OrderByPipe,
    ThreadRolePipe,
    FeatureEnabledPipe,
    TimezoneLabelPipe,
    SentenceCasePipe,
    DateTimePipe,
    RruleFrequencyPipe,
    RruleFrequencyDescriptionPipe,
];

@NgModule({
    imports: [],
    declarations: [...exportDeclarations],
    exports: [...exportDeclarations],
    providers: [
        TruncatePipe,
        StripHtmlPipe,
        StripHtmlLinksPipes,
        FormatOrdinalDatePipe,
        AllowWhitelistLinksPipe,
        IsAllSelectedPipe,
    ],
})
export class SharedPipesModule {}
