import { Component, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

@Component({
    selector: "calendar-empty-services-modal",
    templateUrl: "./calendar-empty-services-modal.component.html",
    styleUrls: ["./calendar-empty-services-modal.component.scss"],
})
export class CalendarEmptyServicesModalComponent implements OnInit {
    constructor(private dialogRef: MatDialogRef<any>) {}

    ngOnInit() {}

    async close() {
        this.dialogRef.close();
    }
}
