export enum PayRunReportInfo {
    NetPayroll = "The total amount of net payments that will be made to employees this pay run",
    PreviousNetPayroll = "The total amount of net payments that was made to employees in the last pay run",
    TotalHours = "Total hours of all types in this pay",
    GrossEarnings = "Total earnings this pay before taxes, benefits and other payroll deductions are withheld from wages.",
    TaxWithheld = "Total tax withheld for this pay.",
    KiwiSaver = "Total of Employee contribution, Employer contribution & Employer Superannuation Contributions Tax in this pay",
    Stsl = "Total deducted this pay for study and training support loans including HECS, HELP, and VET FEE-HELP.",
    StudentLoan = "Total Student Loan amounts in this pay",
    SuperGuarantee = "Employers contribution to superannuation in this pay",
    NetEarnings = "Earnings this pay after all taxes and expenses have been deducted.",
}

export enum PayRunProviders {
    EmploymentHero = "keypay",
}

export enum PayRunExpandedCardTitles {
    Earnings = "Earnings",
    Leave = "Leave details",
    Deductions = "Deductions",
    Payments = "Bank payments",
    KiwiSaver = "KiwiSaver payments",
    StudentLoan = "Student Loan",
}

export enum PayRunEarningsViewType {
    Summary = "summary",
    Breakdown = "breakdown",
}

export enum PayrunKiwiSaverTitles {
    EmployeeContribution = "Employee Contribution",
    EmployerContribution = "Employer Contribution",
    EsctContribution = "Esct Contribution",
}

export enum PayrunStudentLoanTitles {
    SL = "SL",
    SLCIR = "SLCIR",
    SLBOR = "SLBOR",
}

export enum PayrunStudentLoanTooltip {
    SL = "Student Loan",
    SLCIR = "SLCIR Comissioner deduction",
    SLBOR = "SLBOR Voluntary contribution",
}

export enum PayrunTableHeaders {
    Employee = "Employee",
    TotalHours = "Total Hours",
    GrossEarnings = "Gross Earnings",
    Tax = "Tax",
    StudentLoan = "Student Loan",
    STSL = "STSL",
    KiwiSaver = "KiwiSaver",
    SuperGuarantee = "Super Guarantee",
    NetEarnings = "Net Earnings",
    Approve = "Approve",
}

export enum PayrunReportRegion {
    NZ = "nz", // en-NZ
    AUS = "aus", // en-AUS
}
