<loader-icon
    *ngIf="loader.counter"
    color="light"
    [showBackground]="true"
></loader-icon>
<div
    class="message-attach"
    *ngIf="modalData"
>
    <div class="message-attach-header">
        <h1>New message or attachment</h1>
        <div
            class="message-attach-header-close"
            (click)="close()"
            analyticsClick="gaEvents.APP_CREATE_MESSAGE_CLOSE"
        >
            <i class="las la-times icon-size"></i>
        </div>
    </div>

    <div class="message-attach-subhead">Everyone on the workflow can read your message.</div>
    <div class="message-attach-message quill-modal">
        <quill-editor-wrapper
            [message]="messageFormControl"
            [autoFocusOnInit]="!isMobileView"
            [messageSizeQuotaInKB]="messageSizeQuotaInKB"
            (error)="quillError = $event"
        ></quill-editor-wrapper>
    </div>

    <div
        *ngIf="hasPermissionToCreateVaultCard$ | async"
        class="message-attach-file-upload"
    >
        <file-input
            [analyticsPrefix]="gaEvents.APP_CREATE_MESSAGE_FILE_UPLOAD"
            (file)="attachFile($event)"
        ></file-input>
    </div>

    <div class="message-attach-attachments">
        <create-card-document
            *ngIf="documents.length || attachmentErrorMessages.length"
            [role]="role$ | async"
            [documents]="documents"
            [errorMessages]="attachmentErrorMessages"
            [markAllAsSignature]="markAllAsSignature"
            [markAllAsReport]="markAllAsReport"
            (remove)="removeFile($event)"
            (rename)="renameFile($event)"
            (markAsReport)="markAsReport($event)"
        ></create-card-document>
    </div>
    <div class="message-attach-banners">
        <warning-banner
            *ngIf="
                markAllAsSignature &&
                (hasPermissionToRequestSignature$ | async) &&
                requiredUploadCount &&
                documents?.length !== requiredUploadCount
            "
        >
            <ng-container *ngIf="requiredUploadCount === 1; else plural"
                >Upload only {{ requiredUploadCount }} file to proceed. The uploaded file will be marked as signature
                required.</ng-container
            >
            <ng-template #plural>
                Upload only {{ requiredUploadCount }} files to proceed. The uploaded files will be marked as signature
                required.</ng-template
            >
        </warning-banner>
        <warning-banner *ngIf="!(documents | validateMarkFileAsReport : markAllAsReport)">
            You need to mark at least one PDF file as a report to proceed.
        </warning-banner>
    </div>
    <div class="message-attach-footer">
        <div class="message-attach-footer-buttons message-attach-footer-buttons--mobile">
            <button
                analyticsClick="gaEvents.APP_CREATE_MESSAGE_CLOSE"
                (click)="close()"
                class="fx-btn fx-btn--white"
            >
                Cancel
            </button>
            <button
                [disabled]="
                    quillError ||
                    (messageFormControl.value === '' && documents?.length === 0) ||
                    (requiredUploadCount && documents?.length !== requiredUploadCount) ||
                    !(documents | validateMarkFileAsReport : markAllAsReport)
                "
                (click)="createCard()"
                class="fx-btn fx-btn--primary"
                role="button"
                analyticsClick="gaEvents.APP_CREATE_MESSAGE"
            >
                Send Message
            </button>
        </div>
    </div>
</div>
