<div
    class="card"
    [ngClass]="{
        'card--inverted': invertTheme,
        'card--conversation': conversation,
        'card--align-left': align === 'left',
        'card--align-right': align === 'right',
        'card--align-centre': align === 'centre'
    }"
>
    <div
        class="card-avatar"
        *ngIf="name | isSystemName"
    >
        <fx-avatar
            class="system-avatar"
            [border]="false"
        ></fx-avatar>
    </div>
    <div
        class="card-avatar"
        *ngIf="!(name | isSystemName) && (image || name)"
    >
        <fx-avatar
            [name]="name"
            [image]="image"
            [border]="false"
        ></fx-avatar>
    </div>
    <div
        class="card-panel"
        [ngClass]="{ 'card-panel--line-card': lineCard }"
    >
        <div
            class="card-main-content"
            [ngClass]="{ 'card-main-content-removed': lineCard, 'card-main-content--internal': isInternal }"
            [unreadStatus]="isUnread"
        >
            <div
                *ngIf="isInternal"
                class="card-main-content-internal-banner"
            >
                <i class="la la-eye"></i>
                <span>Not visible to customers</span>
            </div>
            <div
                *ngIf="!lineCard"
                class="card-main-content-header"
            >
                <div
                    class="card-name"
                    *ngIf="name"
                >
                    {{ name }}
                </div>

                <div>
                    <div class="card-main-content-header-right">
                        <!-- TODO swap to TaigaUI Pill once Taiga is introduced to project-->
                        <div
                            class="card-main-content-header-right-status-badge"
                            [unreadStatus]="isUnread"
                        >
                            <fx-badge
                                label="Unread"
                                type="info"
                            ></fx-badge>
                        </div>

                        <div
                            class="card-main-content-header-right-edited"
                            *ngIf="edited"
                        >
                            Edited
                        </div>

                        <div
                            *ngIf="timestamp && !lineCard"
                            class="card-main-content-header-right-timestamp"
                        >
                            {{ timestamp | date : "dd/MM/yy, h:mm a" }}
                        </div>

                        <div class="card-main-content-header-right-menu">
                            <ng-content select="[card-actions]"></ng-content>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="card-title"
                *ngIf="title"
            >
                {{ title }}
            </div>

            <div
                class="card-loader"
                *ngIf="loading; else defaultContent"
            >
                <ng-content select="[card-loader]"></ng-content>
            </div>

            <ng-template #defaultContent>
                <ng-container *ngIf="!(edit | async); else editing">
                    <div
                        class="card-description"
                        *ngIf="description && showDescription"
                    >
                        <read-more [showFullDescription]="showFullDescription">
                            <quill-view
                                *ngIf="!useQuillHtml"
                                [style]="quillStyles"
                                [content]="description | allowWhitelistLinks"
                                aria-label="message"
                            >
                            </quill-view>
                            <quill-view-html
                                *ngIf="useQuillHtml"
                                [style]="quillStyles"
                                [content]="description | allowWhitelistLinks"
                                aria-label="message"
                            >
                            </quill-view-html>
                        </read-more>
                    </div>
                </ng-container>
                <ng-template #editing>
                    <div class="card-edit-panel">
                        <quill-editor
                            [styles]="quillStyles"
                            analyticsClick="app_typeamessage"
                            [placeholder]="'Type a description here...'"
                            aria-label="message"
                            (onEditorCreated)="$event.focus()"
                            [ngModel]="description"
                            (onContentChanged)="updatedDescription = $event.html"
                        >
                        </quill-editor>
                        <div class="card-edit-panel-buttons">
                            <button
                                class="fx-btn fx-btn--primary fx-btn--white"
                                type="button"
                                role="button"
                                (click)="cancelEdit($event)"
                            >
                                Discard
                            </button>
                            <button
                                class="fx-btn fx-btn--primary-outlined"
                                type="button"
                                role="button"
                                [disabled]="!updatedDescription || description === updatedDescription"
                                (click)="saveEdit($event)"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </ng-template>
            </ng-template>

            <div class="card-content">
                <ng-content></ng-content>
            </div>
        </div>

        <div
            class="card-removed-timestamp"
            *ngIf="timestamp && lineCard"
        >
            {{ timestamp | date : "dd/MM/yy, h:mm a" }}
        </div>

        <ng-content select="[card-replies]"></ng-content>
    </div>
</div>
