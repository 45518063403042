<form
    *ngIf="form"
    [formGroup]="form"
>
    <ng-container formGroupName="invoiceItems">
        <div class="bills threads threads--with-hover-state">
            <fx-table
                *ngIf="tableData?.data?.length"
                [tableData]="tableData"
                [trackBy]="'id' | trackProperty"
                [rowClickable]="true"
                [sortEnabled]="false"
            >
                <span *fxColumn="'contact.name'; label: 'Supplier'; mobileCollapse: true; let bill"
                    ><div class="bills-col">
                        <span class="mobile-cell-label">Supplier: </span>{{ bill.contact.name }}
                    </div>
                </span>
                <span *fxColumn="'reference'; label: 'Reference'; mobileCollapse: true; let bill"
                    ><div class="bills-col">
                        <span class="mobile-cell-label">Reference: </span>{{ bill.invoiceNumber }}
                    </div>
                </span>
                <span *fxColumn="'date'; label: 'Issued date'; mobileCollapse: true; let bill"
                    ><div class="bills-col">
                        <span class="mobile-cell-label">Issued date: </span>{{ bill.date | date : "dd/MM/yyyy" }}
                    </div>
                </span>
                <span *fxColumn="'dueDate'; label: 'Due date'; mobileCollapse: true; let bill"
                    ><div class="bills-col">
                        <span class="mobile-cell-label">Due date: </span>{{ bill.dueDate | date : "dd/MM/yyyy" }}
                    </div>
                </span>
                <span *fxColumn="'amountDue'; label: 'Amount due'; mobileCollapse: true; let bill"
                    ><div class="bills-col">
                        <span class="mobile-cell-label">Amount due: </span>{{ bill.amountDue | currency }}
                    </div>
                </span>

                <ng-container *ngIf="allowUpdate && form?.controls?.invoiceItems as invoiceItems">
                    <ng-container
                        *fxColumn="
                            'approve';
                            label: 'Approve';
                            sortable: true;
                            mobileCollapse: true;
                            alignRight: true;
                            mobileGrid: true;
                            hjSupressData: true;
                            let bill
                        "
                    >
                        <div
                            class="bills-col"
                            *ngIf="invoiceItems.controls[bill.invoiceID] as employeeControl"
                        >
                            <span class="mobile-cell-label">Approve: </span>
                            <div
                                class="table-checkbox"
                                [formGroupName]="bill.invoiceID"
                            >
                                <fx-checkbox
                                    class="todo-item-green-checkbox"
                                    [checked]="employeeControl.value.approved"
                                    (change)="updateValue(employeeControl, $event, true)"
                                    [disabled]="state.isCompleted || allowEdit"
                                >
                                </fx-checkbox>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="allowUpdate && form?.controls?.invoiceItems as invoiceItems">
                    <ng-container
                        *fxColumn="
                            'decline';
                            label: 'Decline';
                            sortable: true;
                            mobileCollapse: true;
                            alignRight: true;
                            mobileGrid: true;
                            hjSupressData: true;
                            let bill
                        "
                    >
                        <div
                            class="bills-col"
                            *ngIf="invoiceItems.controls[bill.invoiceID] as employeeControl"
                        >
                            <span class="mobile-cell-label">Decline: </span>
                            <div
                                class="table-checkbox"
                                [formGroupName]="bill.invoiceID"
                            >
                                <fx-checkbox
                                    class="todo-item-red-checkbox"
                                    [checked]="employeeControl.value.declined"
                                    (change)="updateValue(employeeControl, $event, false)"
                                    [disabled]="state.isCompleted || allowEdit"
                                >
                                </fx-checkbox>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="allowEdit">
                    <span *fxColumn="'remove'; label: ''; mobileCollapse: true; let bill">
                        <div
                            class="bills-col"
                            (click)="removeInvoice(tableData.data, bill)"
                        >
                            <i
                                [ngClass]="{
                                    'disable-icon':
                                        form.controls?.invoiceItems?.controls[bill.invoiceID]?.value.approved ||
                                        form.controls?.invoiceItems?.controls[bill.invoiceID]?.value.declined
                                }"
                                class="las la-trash icon-size"
                            ></i>
                        </div>
                    </span>
                </ng-container>
            </fx-table>
        </div>
    </ng-container>
</form>
