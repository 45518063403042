import { BUSINESS_DAY_MS, IWorkflowToken, WorkflowTokenTypes } from "@visoryplatform/threads";

import { ConfigTokenExtensionToken } from "@visoryplatform/workflow-core";
import { FrequencyLabel } from "../../account/constants/configuration-settings.constants";

export const basMonthly: IWorkflowToken = {
    id: "2df125d9-0d95-4024-bbc4-8ae26f5f7946",
    label: FrequencyLabel.monthly,
    type: WorkflowTokenTypes.Bas,
    affectsScheduling: true,
    configTokens: {
        [ConfigTokenExtensionToken.SLA_VARIATION]: {
            duration: 0,
        },
    },
};

export const basQuarterly: IWorkflowToken = {
    id: "763126f3-fc8d-47f3-b8c9-ebce5a2d7180",
    label: FrequencyLabel.quarterly,
    type: WorkflowTokenTypes.Bas,
    affectsScheduling: true,
    customFrequency: FrequencyLabel.quarterly,
    configTokens: {
        [ConfigTokenExtensionToken.SLA_VARIATION]: {
            duration: 20 * BUSINESS_DAY_MS,
        },
    },
};

export const basFrequencyTokens = [basMonthly, basQuarterly];
