import { Component } from "@angular/core";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { UserAssigneeService } from "../../services/user-assignee.service";
import { map, shareReplay, switchMap, take } from "rxjs/operators";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastSeverity, ToastSummary } from "projects/portal-modules/src/lib/shared/constants/toast.constants";
import { MessageService } from "@visoryplatform/portal-ui";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ActivatedRoute } from "@angular/router";
import { combineLatest, EMPTY, Observable, of } from "rxjs";
import { ConfirmModalComponent } from "projects/portal-modules/src/lib/shared/components/confirm-modal/confirm-modal.component";
import { MatDialogConfig } from "@angular/material/dialog";
import { GA_EVENTS_PREFIX } from "projects/portal-modules/src/lib/analytics";
@Component({
    selector: "bulk-replace-user",
    templateUrl: "./bulk-replace-user.component.html",
    styleUrls: ["./bulk-replace-user.component.scss"],
})
export class BulkReplaceUserComponent {
    userId$: Observable<string>;
    loader = new Loader();

    tableHeaders = {
        service: "Service",
        reviewChange: "Review change",
        account: "Account",
        timelineTitle: "Workflow",
        replace: "Replace",
    };

    form = new FormGroup({
        updateConfigurations: new FormControl<boolean>(true, [Validators.required]),
        updateActiveWorkflows: new FormControl<boolean>(true, [Validators.required]),
        keepAsParticipant: new FormControl<boolean>(false, [Validators.required]),
        newUsers: new FormControl<string[]>([], [Validators.required]),
        excludeThreadIds: new FormControl<string[]>([]),
        excludeConfigurationIds: new FormControl<string[]>([]),
    });

    constructor(
        private dialog: MatDialog,
        private userAssigneeService: UserAssigneeService,
        private messageService: MessageService,
        private route: ActivatedRoute,
    ) {
        this.userId$ = this.route.params.pipe(
            map((params) => params.userId),
            shareReplay(1),
        );
    }

    async confirmBulkAndReplace(): Promise<void> {
        if (this.form.invalid) {
            return;
        }

        const dialogConfig = await this.getConfirmModalConfig();
        this.dialog
            .open(ConfirmModalComponent, dialogConfig)
            .afterClosed()
            .pipe(
                switchMap((value) => {
                    if (!value) {
                        return EMPTY;
                    }

                    const bulkUpdateAssignees$ = this.updateActiveWorkflows().pipe(take(1));
                    const bulkUpdateConfigurations$ = this.updateConfigurations().pipe(take(1));
                    return this.loader.wrap(combineLatest([bulkUpdateAssignees$, bulkUpdateConfigurations$]));
                }),
            )
            .subscribe(() => {
                this.messageService.add({
                    severity: ToastSeverity.Success,
                    summary: ToastSummary.Success,
                    detail: "Success! It may take some time for bulk changes to be reflected. You will receive a notification when it is completed.",
                });
            });
    }

    private updateActiveWorkflows(): Observable<void | null> {
        const { newUsers, keepAsParticipant, excludeThreadIds, updateActiveWorkflows } = this.form.value;
        if (!updateActiveWorkflows) {
            return of<null>(null);
        }

        return this.userId$.pipe(
            switchMap((userId) => {
                return this.userAssigneeService.replaceUserWorkflows(
                    userId,
                    keepAsParticipant,
                    newUsers,
                    excludeThreadIds,
                );
            }),
        );
    }

    private updateConfigurations(): Observable<void | null> {
        const { newUsers, excludeConfigurationIds, updateConfigurations } = this.form.value;
        if (!updateConfigurations) {
            return of<null>(null);
        }

        return this.userId$.pipe(
            switchMap((userId) => {
                return this.userAssigneeService.replaceUserConfigurations(userId, newUsers, excludeConfigurationIds);
            }),
        );
    }

    private async getConfirmModalConfig(): Promise<MatDialogConfig> {
        const [userConfigurations, userWorkflows] = await this.getAllTotalUser().toPromise();
        const workflowText = userWorkflows === 1 ? "workflow" : `workflows`;
        const configurationsText = userConfigurations === 1 ? "configuration" : `configurations`;

        const areYouSureText = `Bulk replacing will affect ${userWorkflows} ${workflowText} and ${userConfigurations} ${configurationsText}.`;

        return {
            data: {
                confirmText: "confirm",
                declineText: "Cancel",
                promptText: "Are you sure you want to bulk change?",
                areYouSureText,
                analyticsPrefix: GA_EVENTS_PREFIX.BULK_REPLACE_CONFIRM_MODAL,
            },
            panelClass: ["centered-modal"],
            width: "420px",
        };
    }

    private getAllTotalUser(): Observable<number[]> {
        const { updateActiveWorkflows, updateConfigurations } = this.form.value;
        const totalUserConfigurations$ = this.loader
            .wrap(this.getTotalUserConfigurations(updateConfigurations))
            .pipe(take(1));
        const totalUserActiveWorkflows$ = this.loader
            .wrap(this.getTotalUserActiveWorkflows(updateActiveWorkflows))
            .pipe(take(1));

        return combineLatest([totalUserConfigurations$, totalUserActiveWorkflows$]);
    }

    private getTotalUserConfigurations(updateConfigurations: boolean): Observable<number> {
        if (!updateConfigurations) {
            return of(0);
        }

        const { excludeConfigurationIds } = this.form.value;

        return this.userId$.pipe(
            switchMap((userId) => this.userAssigneeService.listConfigurations(userId, 0, 0)),
            map((paginated) => paginated?.total || 0),
            map((total) => total - excludeConfigurationIds.length),
        );
    }

    private getTotalUserActiveWorkflows(updateActiveWorkflows: boolean): Observable<number> {
        if (!updateActiveWorkflows) {
            return of(0);
        }

        const { excludeThreadIds } = this.form.value;

        return this.userId$.pipe(
            switchMap((userId) => this.userAssigneeService.listTimelines(userId, 0, 0)),
            map((paginated) => paginated?.total || 0),
            map((total) => total - excludeThreadIds.length),
        );
    }
}
