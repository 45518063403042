import { Component, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { AnalyticsService } from "projects/portal-modules/src/lib/analytics";

@Component({
    selector: "change-password-dialog",
    templateUrl: "./change-password-dialog.component.html",
    styleUrls: ["./change-password-dialog.component.scss"],
})
export class ChangePasswordDialogComponent implements OnInit {
    constructor(
        private dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
        private analyticsService: AnalyticsService,
    ) {}

    ngOnInit(): void {
        this.recordAnalyticsEvent("displayed");
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("change-password", category);
    }

    public closeDialog() {
        this.dialogRef.close();
    }
}
