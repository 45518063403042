import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { ENVIRONMENT } from "../../../../../app/injection-token";
import { EnvironmentSpecificConfig } from "../../../../../../projects/portal-modules/src/lib/environment/environment.common";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { OnboardingWizardComponent } from "../../onboarding-wizard/onboarding-wizard.component";
import { Router } from "@angular/router";

@Component({
    selector: "email-verification-sent",
    templateUrl: "./email-verification-sent.component.html",
    styleUrls: ["./email-verification-sent.component.scss"],
})
export class EmailVerificationSentComponent {
    @Input() emailAddress: string;
    @Input() verifyEmailMessage: { error: boolean; message: string };
    @Output() emitResendEmail = new EventEmitter<void>();
    readonly textResource = this.environment.featureFlags.text;
    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private dialogRef: MatDialogRef<OnboardingWizardComponent>,
        private router: Router,
    ) {}

    backToLogin(): void {
        this.router.navigate(["/login"]);
        this.dialogRef.close();
    }
}
