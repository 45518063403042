import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, OnDestroy } from "@angular/core";
import { WebsocketService } from "./websocket.service";
import { Observable, Subject } from "rxjs";
import { Notification, PossibleDeliveryData } from "@visoryplatform/notifications-core";
import { filter, map, share } from "rxjs/operators";
import {
    NOTIFICATIONS_APPID,
    NOTIFICATIONS_URL,
    NOTIFICATIONS_WS_HEADERS,
    NOTIFICATIONS_WS_URL,
} from "../injection-tokens";
import { IPaginated } from "@visoryplatform/datastore-types";
import { NotificationsPage } from "./NotificationPage";
import { PaginatedDataset } from "@visoryplatform/datastore-types-frontend";

@Injectable({ providedIn: "root" })
export class NotificationsService implements OnDestroy {
    private readonly websocket: WebsocketService;
    private websocketUpdates$: Observable<Notification>;
    private notificationSubject = new Subject<Notification[]>();

    constructor(
        @Inject(NOTIFICATIONS_APPID) private appId: string,
        @Inject(NOTIFICATIONS_WS_URL) wsEndpoint: string,
        @Inject(NOTIFICATIONS_WS_HEADERS) wsHeaders: Observable<any>,
        @Inject(NOTIFICATIONS_URL) private httpEndpoint: string,
        private http: HttpClient,
    ) {
        if (!appId || !wsEndpoint || !wsHeaders || !httpEndpoint) {
            throw new Error("Missing required provider for a notifications injection token");
        }

        this.websocket = new WebsocketService(wsHeaders, wsEndpoint);
        this.websocketUpdates$ = this.websocket.getEvents<Notification>().pipe(
            filter((notification) => this.isNotification(notification)),
            share(),
        );
    }

    static compareNotifications(a: Notification, b: Notification): number {
        const aCreated = new Date(a.createdAt).getTime();
        const bCreated = new Date(b.createdAt).getTime();

        return bCreated - aCreated;
    }

    getUnreadCount(channel?: string): Observable<number> {
        const url = `${this.httpEndpoint}/user/notifications/unresolved/count`;
        const params = {
            appId: this.appId,
            channelStartsWith: channel || "",
        };
        return this.http.get<{ unreadCount: number }>(url, { params }).pipe(map((response) => response.unreadCount));
    }

    getNotifications(
        channel?: string,
        next?: string,
        limit?: number,
        showUnreadOnly?: boolean,
    ): Observable<IPaginated<Notification<PossibleDeliveryData>>> {
        const url = `${this.httpEndpoint}/notifications`;
        const params = {
            appId: this.appId,
            channelStartsWith: channel || "",
            next: next || "",
            limit: limit?.toString() || "",
            showUnreadOnly: showUnreadOnly?.toString() || "",
        };
        return this.http.get<IPaginated<Notification>>(url, { params });
    }

    subscribeToChannel(channel?: string): Observable<Notification[]> {
        return this.websocketUpdates$.pipe(
            filter((notification: Notification) => (channel ? notification.channel.startsWith(channel) : true)),
            map((notification: Notification<PossibleDeliveryData>) => [notification]),
        );
    }

    getCurrentNotifications(
        channelPrefix?: string,
        limit?: number,
        shouldShowUnreadOnly?: boolean,
    ): PaginatedDataset<Notification> {
        return new NotificationsPage(channelPrefix, limit, shouldShowUnreadOnly, this);
    }

    async markAllAsRead(channelPrefix: string): Promise<void> {
        const url = `${this.httpEndpoint}/user/notifications/resolve`;
        const params = {
            appId: this.appId,
            channelStartsWith: channelPrefix || "",
        };
        await this.http.post<void>(url, {}, { params }).toPromise();
    }

    markAsRead(channel: string): void {
        this.websocket.send("markAsRead", {
            appId: this.appId,
            channelStartsWith: channel,
        });
    }

    markAsUnread(notificationId: string): void {
        this.websocket.send("markAsUnread", {
            notificationId,
        });
    }

    markAsDeleted(notificationId: string): void {
        this.websocket.send("markAsDeleted", {
            notificationId,
        });
    }

    deleteInChannel(channel: string): void {
        this.websocket.send("deleteInChannel", {
            appId: this.appId,
            channelStartsWith: channel,
        });
    }

    ngOnDestroy(): void {
        if (this.notificationSubject) {
            this.notificationSubject.complete();
        }
        if (this.websocket) {
            this.websocket.close();
        }
    }

    updateNotifications(current: Notification[], updates: Notification[]): Notification[] {
        if (!updates || !Array.isArray(updates)) {
            return [];
        }

        for (const update of updates) {
            const existingNotification = current.find((existing) => existing.id === update.id);

            if (existingNotification) {
                Object.assign(existingNotification, update);
            } else {
                current.push(update);
            }
        }

        return current.sort((a, b) => NotificationsService.compareNotifications(a, b));
    }

    private isNotification(notification: Notification): boolean {
        // Mainly just to filter out errors/noise
        const hasProperties = !!(notification.id && notification.createdAt && notification.deliveryMethod);

        if (!hasProperties) {
            console.warn("Unknown notification event", notification);
        }

        return hasProperties;
    }
}
