import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { CHAT_STAGE, OPENTOK_API_KEY } from "@visoryplatform/video-chat-sdk";
import { VcCardComponent } from "./components/vc-card/vc-card.component";
import { VcModalComponent } from "./components/vc-modal/vc-modal.component";
import { VideoCallCardComponent } from "./components/video-call-card/video-call-card.component";
import { VideoCallEndSessionModalComponent } from "./components/video-call-end-session-modal/video-call-end-session-modal.component";
import { MeetingDetailsComponent } from "./components/meeting-details/meeting-details.component";
import { RecordingListComponent } from "./components/recording-list/recording-list.component";
import { RecordingLinkComponent } from "./components/recording-link/recording-link.component";
import { RecordingProcessingModalComponent } from "./components/recording-processing-modal/recording-processing-modal.component";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { BrowserModule } from "@angular/platform-browser";
import { PreCallDetailsComponent } from "./components/pre-call-details/pre-call-details.component";
import { VcEndSessionModalComponent } from "./components/vc-end-session-modal/vc-end-session-modal.component";
import { SharedModule } from "projects/portal-modules/src/lib/shared";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui";
import { ThreadsUiModule } from "projects/portal-modules/src/lib/threads-ui";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { VideoChatModule as VcSdkModule } from "@visoryplatform/video-chat-sdk";
import { VideoChatPlugin } from "./video-chat.plugin";
import { PLUGIN_FACTORY } from "projects/portal-modules/src/lib/plugins";
import { FxTableModule } from "../../portal-modules/src/lib/fx-table/fx-table.module";
import { MatLegacySnackBarModule as MatSnackBarModule } from "@angular/material/legacy-snack-bar";
import { VirtualBackgroundSelectorComponent } from "./components/virtual-background-selector/virtual-background-selector.component";

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        MatDialogModule,
        MatSnackBarModule,
        ThreadsUiModule,
        FindexUiModule,
        FxUiModule,
        DragDropModule,
        SharedModule,
        VcSdkModule,
        FxTableModule,
    ],
    exports: [
        MeetingDetailsComponent,
        VcEndSessionModalComponent,
        RecordingLinkComponent,
        RecordingProcessingModalComponent,
    ],
    declarations: [
        PreCallDetailsComponent,
        VcCardComponent,
        VcModalComponent,
        VideoCallCardComponent,
        VideoCallEndSessionModalComponent,
        MeetingDetailsComponent,
        VcEndSessionModalComponent,
        RecordingListComponent,
        RecordingLinkComponent,
        RecordingProcessingModalComponent,
        VirtualBackgroundSelectorComponent,
    ],
    providers: [
        {
            provide: CHAT_STAGE,
            useFactory: (environment: EnvironmentSpecificConfig) => environment.stage,
            deps: [ENVIRONMENT],
        },
        {
            provide: OPENTOK_API_KEY,
            useFactory: (environment: EnvironmentSpecificConfig) => environment.opentokApiKey,
            deps: [ENVIRONMENT],
        },
        {
            provide: OPENTOK_API_KEY,
            useFactory: (environment: EnvironmentSpecificConfig) => environment.opentokApiKey,
            deps: [ENVIRONMENT],
        },
        { provide: PLUGIN_FACTORY, useClass: VideoChatPlugin, multi: true },
    ],
})
export class VideoChatModule {}
