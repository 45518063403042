import { Frequency, RRule } from "rrule";

import { FrequencyLabel } from "../../account/constants/configuration-settings.constants";
import { IWorkflowToken } from "@visoryplatform/threads";

export class SlaVariationsService {
    mapSlaToRRuleFrequency(workflowToken?: IWorkflowToken): string | null {
        if (!workflowToken) {
            return null;
        }

        const [frequency, interval] = this.getFrequencyAndInterval(workflowToken);

        const freq = this.getRruleFrequencyOption(frequency);

        const rruleString = new RRule({
            freq,
            interval,
        }).toString();

        return rruleString;
    }

    private getRruleFrequencyOption(frequency: string): Frequency {
        switch (frequency) {
            case FrequencyLabel.weekly:
                return Frequency.WEEKLY;
            case FrequencyLabel.monthly:
                return Frequency.MONTHLY;
            default:
                throw new Error(`Unsupported frequency: ${frequency}`);
        }
    }

    private getFrequencyAndInterval(workflowToken: IWorkflowToken): [string, number] {
        // Since we do not support a fortnightly frequency, we map it to weekly
        if (workflowToken?.customFrequency === FrequencyLabel.fortnightly) {
            const fortnightlyInterval = 2;
            return [FrequencyLabel.weekly, fortnightlyInterval];
        }

        if (workflowToken?.customFrequency === FrequencyLabel.quarterly) {
            const quarterlyInterval = 3;
            return [FrequencyLabel.monthly, quarterlyInterval];
        }

        const weeklyInterval = 1;
        return [workflowToken.label, weeklyInterval];
    }
}
