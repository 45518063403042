import { DialogRef } from "@angular/cdk/dialog";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { ITimeline, IntegrationTypes, TRANSFORMER_CARD_TYPE, TransformInput } from "@visoryplatform/threads";
import { DUE_DATE_EXTENSION_TYPE, DueDateExtensionHelpers, WorkflowGraphService } from "@visoryplatform/workflow-core";
import { DateTime } from "luxon";
import { ICreateCardEvent } from "projects/portal-modules/src/lib/threads-ui/components/create-card/create-card.component";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { environmentCommon } from "src/environments/environment";

export type CreateTransformerData = Record<string, unknown>;

@Component({
    selector: "list-transforms",
    template: `Creating card...`,
    styles: [``],
})
export class CreateTransformerComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) private dialogData: ICreateCardEvent,
        private dialogRef: DialogRef<CreateTransformerComponent>,
        private threadCardService: ThreadCardService,
    ) {}

    ngOnInit(): void {
        const { thread } = this.dialogData;

        const tenantId = this.getTenantId(thread);
        const periodEnd = this.getPeriodEnd(thread);

        const transformerConfig = thread.account?.metadata?.transforms;
        if (!transformerConfig) {
            this.dialogRef.close();
            throw new Error("Transformers not configured in account");
        }

        const payload: TransformInput = {
            label: "Xero month end checks",
            sources: transformerConfig.sources.map((source) => ({
                sourceId: `xero.${tenantId}.${source.sourceId}`,
                event: { periodStart: periodEnd, periodEnd: periodEnd },
            })),
            resultTransforms: transformerConfig.resultTransforms,
        };

        this.threadCardService
            .createCard(thread.id, TRANSFORMER_CARD_TYPE, payload)
            .subscribe(() => this.dialogRef.close());
    }

    private getTenantId(timeline: ITimeline): string {
        const integrations = timeline.account?.metadata?.integrations;
        const xeroIntegration = integrations[IntegrationTypes.Xero];

        if (!xeroIntegration) {
            throw new Error("Xero integration not connected");
        }

        const tenantId = xeroIntegration.businessId;
        if (!tenantId || typeof tenantId !== "string") {
            throw new Error("Xero business not connected");
        }

        return tenantId;
    }

    private getPeriodEnd(timeline: ITimeline): string {
        const orderedSteps = WorkflowGraphService.orderWorkflowSteps(timeline.workflow);
        if (!orderedSteps?.length) {
            throw new Error("No workflow steps found");
        }

        const lastDueDateStep = orderedSteps
            .reverse()
            .find((step) => step.extensions.some((ext) => ext.type === DUE_DATE_EXTENSION_TYPE));

        if (!lastDueDateStep) {
            throw new Error("No due date step found");
        }

        const dueDateExtension = lastDueDateStep.extensions.find((ext) => ext.type === DUE_DATE_EXTENSION_TYPE);
        const dueDateInput = dueDateExtension.inputs?.find(DueDateExtensionHelpers.isDueDateInput);

        const lastDueDate = dueDateInput.data;
        const timezone = timeline.account?.metadata?.contactInfo?.timeZone || environmentCommon.defaultTimezone;

        const endOfMonth = DateTime.fromISO(lastDueDate)?.setZone(timezone)?.endOf("month");
        if (!endOfMonth?.isValid) {
            throw new Error("Invalid due date");
        }

        return endOfMonth.toFormat("yyyy-MM-dd");
    }
}
