import { Injectable } from "@angular/core";
import { IWorkflowDesign } from "@visoryplatform/workflow-core";
import { IntegrationTypes, IWorkflowDesignType } from "@visoryplatform/threads";
import { ILaunchDarklyFeatureFlags } from "../../../feature-flags";

@Injectable({
    providedIn: "root",
})
export class DesignTypeService {
    filterDesignTypes(
        designTypes: IWorkflowDesignType[],
        threadType: string,
        connectedIntegrations: IntegrationTypes[],
        featureFlags: ILaunchDarklyFeatureFlags,
    ): IWorkflowDesignType[] {
        const threadTypes = this.filterByThread(designTypes, threadType);
        const enabledDesignTypes = this.filterByFeatureFlag(threadTypes, featureFlags);
        return this.filterByIntegrations(enabledDesignTypes, connectedIntegrations);
    }

    mapTypeToDesign(designTypes: IWorkflowDesignType[], designs: IWorkflowDesign[]): IWorkflowDesign[] {
        const workflowDesigns = designTypes.map((designType) =>
            designs.find((design) => design.id === designType.workflowDesignId),
        );
        return workflowDesigns;
    }

    private filterByThread(designTypes: IWorkflowDesignType[], threadType: string): IWorkflowDesignType[] {
        return designTypes?.filter((designType) => !designType.threadType || designType.threadType === threadType);
    }

    private hasMatchingIntegrations(
        designIntegrations: IntegrationTypes[],
        connectedIntegrations: IntegrationTypes[],
    ): boolean {
        return designIntegrations.some((designIntegrationType) =>
            connectedIntegrations.includes(designIntegrationType),
        );
    }

    private filterByFeatureFlag(
        designTypes: IWorkflowDesignType[],
        featureFlags: ILaunchDarklyFeatureFlags,
    ): IWorkflowDesignType[] {
        return designTypes?.filter((design) => {
            const requiredFlags = design.requiredFeatureFlagStates;

            if (!requiredFlags) {
                return true;
            }

            return Object.entries(requiredFlags).every(([flag, requiredState]) => {
                return featureFlags[flag] === requiredState;
            });
        });
    }

    private filterByIntegrations(
        designTypes: IWorkflowDesignType[],
        connectedIntegrations: IntegrationTypes[],
    ): IWorkflowDesignType[] {
        return designTypes?.filter((design) => {
            if (!design.integrationTypes?.length) {
                return true;
            }

            const designIntegrations = design.integrationTypes;
            const hasIntegrations = this.hasMatchingIntegrations(designIntegrations, connectedIntegrations);

            return design.requiresIntegration ? hasIntegrations : !hasIntegrations;
        });
    }
}
