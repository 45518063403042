<div
    [formGroup]="mobileSortFilters"
    class="mobile-sort"
>
    <div
        *ngIf="sortEnabled"
        class="mobile-sort-filters"
    >
        <v-select
            id="selectedSortBy"
            placeholder="Sort By"
            [options]="sortByOptons"
            [optionContent]="sortByContent"
            [valueContent]="sortByContent"
            formControlName="selectedSortBy"
            [tuiTextfieldCleaner]="false"
            (selectionChanged)="sortBy($event.value, sort?.direction)"
        >
        </v-select>
        <ng-template
            #sortByContent
            let-option
        >
            {{ option.key }}
        </ng-template>

        <v-select
            id="selectedSortDirection"
            placeholder="Sort Direction"
            [options]="SORT_DIRECTION_OPTIONS"
            [optionContent]="sortDirectionsContent"
            [valueContent]="sortDirectionsContent"
            formControlName="selectedSortDirection"
            [tuiTextfieldCleaner]="false"
            (selectionChanged)="sortBy(sort?.active, $event.value)"
        >
        </v-select>
        <ng-template
            #sortDirectionsContent
            let-option
        >
            {{ option.key }}
        </ng-template>
    </div>

    <fx-checkbox
        (change)="toggleSelectAll()"
        *ngIf="selectable === true"
        [checked]="selection.hasValue() && (selectedRowCount | isAllSelected : totalRowsCount)"
        class="fx-table-select-all"
    >
        Select All
    </fx-checkbox>
</div>
<ng-container *ngIf="enableExpandAll">
    <button
        (click)="toggleExpandAllRows(); $event.stopPropagation()"
        aria-label="Expand row button"
        mat-icon-button
        class="fx-table-expand-all-button"
    >
        <i class="las la-angle-{{ allRowsExpanded ? 'up' : 'down' }}"></i>
        <span> {{ allRowsExpanded ? "Collapse" : "Expand" }} all </span>
    </button>
</ng-container>
<table
    *ngIf="displayedColumns$ | async; let displayedColumns"
    mat-table
    matSort
    [dataSource]="tableData"
    [multiTemplateDataRows]="true"
    [trackBy]="trackBy"
    [matSortActive]="sortActive"
    [matSortDirection]="sortDirection"
    (matSortChange)="sortChange($event)"
>
    <ng-container [matColumnDef]="CHECKBOX_COLUMN_ID">
        <th
            *matHeaderCellDef
            class="v-align-top"
            mat-header-cell
        >
            <fx-checkbox
                (change)="toggleSelectAll()"
                [checked]="selection.hasValue() && (selectedRowCount | isAllSelected : totalRowsCount)"
            ></fx-checkbox>
        </th>
        <td
            *matCellDef="let row"
            class="checkbox"
            mat-cell
        >
            <fx-checkbox
                (change)="checkboxClicked(row, $event)"
                [checked]="selection.isSelected(row)"
            ></fx-checkbox>
        </td>
    </ng-container>

    <ng-container
        *ngFor="let column of columns; trackBy: trackColumn"
        [matColumnDef]="column.id"
    >
        <th
            (click)="analyticsSortClick(column)"
            *matHeaderCellDef
            [disabled]="!column.sortable"
            [ngClass]="{ 'header-align-right': column.alignRight }"
            [arrowPosition]="column.alignRight ? 'before' : 'after'"
            mat-header-cell
            mat-sort-header
        >
            {{ column.label }}
            <i
                *ngIf="column.info"
                [tuiHint]="column.info"
                tuiHintDirection="top"
                class="las la-question-circle info-icon"
            >
            </i>
        </th>

        <hr class="row-child-divider" />

        <td
            *matCellDef="let row"
            [ngClass]="{
                mobile: column.mobileCollapse,
                'data-hj-suppress': column.hjSupressData
            }"
            mat-cell
        >
            <div
                class="row-template-container"
                [ngClass]="{
                    'column-align-right': column.alignRight,
                    'expand-button-label': column.expandable
                }"
            >
                <ng-container *ngIf="column.expandable">
                    <button
                        (click)="toggleExpandRow(row); $event.stopPropagation()"
                        aria-label="Expand row button"
                        mat-icon-button
                        class="expand-button"
                    >
                        <i class="las la-angle-{{ !row.hideRowChild ? 'up' : 'down' }} expand-button-icon"></i>
                    </button>
                </ng-container>

                <ng-template
                    [ngTemplateOutletContext]="{ $implicit: row }"
                    [ngTemplateOutlet]="column.template"
                >
                </ng-template>
            </div>
        </td>

        <ng-container *ngIf="enableFooter">
            <td
                *matFooterCellDef
                mat-footer-cell
                [ngClass]="{
                    mobile: column.mobileCollapse,
                    'data-hj-suppress': column.hjSupressData,
                    'column-align-right': column.alignRight
                }"
            >
                <ng-container [ngSwitch]="column?.type">
                    <ng-container *ngSwitchCase="'string'">
                        <span class="mat-footer-column-title"> Total </span>
                    </ng-container>
                    <ng-container>
                        <ng-container *ngSwitchCase="'currency'">
                            <span class="mat-footer-column-cell">
                                {{ getTotal(column.id) | currency : column.currency?.toUpperCase() }}
                            </span>
                        </ng-container>
                        <ng-container *ngSwitchCase="'decimal'">
                            <span class="mat-footer-column-cell">{{ getTotal(column.id) | number }}</span>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </td>
        </ng-container>
    </ng-container>

    <ng-container matColumnDef="rowChild">
        <td
            *matCellDef="let row"
            [attr.colspan]="displayedColumns.length"
            mat-cell
        >
            <ng-template
                [ngTemplateOutletContext]="{ $implicit: row }"
                [ngTemplateOutlet]="rowChild.template"
            ></ng-template>
        </td>
    </ng-container>

    <!-- Header and Row Declarations -->
    <tr
        *matHeaderRowDef="displayedColumns; sticky: stickyHeader"
        class="header"
        mat-header-row
    ></tr>

    <tr
        (click)="rowClick.emit(row)"
        *matRowDef="let row; columns: displayedColumns"
        [class.selected]="selectedRows.has(row)"
        [ngClass]="{
            'mat-header-row--clickable': rowClickable,
            'mat-row--has-child': rowChild && !row?.hideRowChild
        }"
        mat-row
    ></tr>

    <ng-container *ngIf="rowChild">
        <tr
            *matRowDef="let row; columns: ['rowChild']"
            [hidden]="!!row?.hideRowChild"
            class="row-child"
            mat-row
        ></tr>
    </ng-container>

    <ng-container *matRowDef="let row; columns: []">
        <hr
            class="row-child-divider"
            [ngClass]="{ 'row-child-divider--has-child': rowChild }"
        />
    </ng-container>

    <ng-container *ngIf="enableFooter">
        <tr
            *matFooterRowDef="displayedColumns"
            mat-footer-row
            [attr.colspan]="displayedColumns.length"
        ></tr>
    </ng-container>
</table>
