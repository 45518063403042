import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { AnalyticsModule } from "../../../analytics/analytics.module";
import { FindexUiModule } from "../../../findex-ui";
import { UiComponentsModule } from "../../../ui-components";
import { CdkStepperModule } from "@angular/cdk/stepper";

import { ParticipantListComponent } from "./components/participant-list/participant-list.component";
import { ParticipantPickerComponent } from "./components/participant-picker/participant-picker.component";
import { ThreadParticipantsComponent } from "./components/thread-participants/thread-participants.component";
import { ThreadAddParticipantComponent } from "./components/thread-participants/thread-add-participant/thread-add-participant.component";
import { ThreadRemoveParticipantComponent } from "./components/thread-participants/thread-remove-participant/thread-remove-participant.component";
import { SearchParticipantsComponent } from "./components/search-participants/search-participants.component";
import { UiLayoutsModule } from "../../../ui-layouts/ui-layouts.module";
import { SharedPipesModule } from "../../../shared/pipes/shared-pipes.module";
import { ThreadsUiPipesModule } from "../../pipes/threads-ui-pipes.module";
import { ReactiveFormsModule } from "@angular/forms";
import { AddParticipantComponent } from "./components/add-participant/add-participant.component";

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        AnalyticsModule,
        FindexUiModule,
        UiComponentsModule,
        CdkStepperModule,
        UiLayoutsModule,
        UiComponentsModule,
        SharedPipesModule,
        ThreadsUiPipesModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
    ],
    declarations: [
        SearchParticipantsComponent,
        ParticipantListComponent,
        ParticipantPickerComponent,
        ThreadParticipantsComponent,
        ThreadAddParticipantComponent,
        ThreadAddParticipantComponent,
        ThreadRemoveParticipantComponent,
        AddParticipantComponent,
    ],
    exports: [
        SearchParticipantsComponent,
        ParticipantListComponent,
        ParticipantPickerComponent,
        ThreadParticipantsComponent,
        AddParticipantComponent,
    ],
})
export class ThreadParticipantsModule {}
