import { Injectable } from "@angular/core";
import { IAnalyticsService } from "./IAnalyticsService";
import { AppUser } from "../../findex-auth/model/AppUser";

declare global {
    interface Window {
        hj?: any;
    }
}

const HOT_JAR_EVENTS = {
    app_attachfile: ["Documents", "Documents Attach Files"],
    app_deletenotification: ["Notifications", "Delete Notification"],
    app_error400: ["Errors", "400 Bad Request"],
    app_error401: ["Errors", "401 Unauthorised"],
    app_error403: ["Errors", "403 Forbidden Request"],
    app_error500: ["Errors", "500 Internal Server Error"],
    app_error504: ["Errors", "504 Timeout"],
    app_joinvc: ["Meetings", "Meeting Joined", "Meeting Interaction"],
    app_markasreadnotification: ["Notifications", "Mark As Read Notification"],
    app_providedocuments: ["RFI", "RFI Provide Document", "RFI View Document", "RFI Interaction"],
    app_recentnotifications: ["Notifications", "Recent Notifications"],
    app_requestsignature: ["Documents", "Documents Request Signature"],
    app_screensharevc: ["Meetings", "Meeting Screenshare"],
    app_signdocument: ["Documents", "Documents Client Sign"],
    app_tabdocuments: ["Documents", "Documents Tab View"],
    app_viewinsights: ["Insights", "Insights View"],

    calendar_cardreply: ["Meetings", "Meeting Reply", "Meeting Interaction"],
    calendar_createinvitation: ["Meetings", "Meeting Request Created", "Meeting Interaction"],
    calendar_createscheduled: ["Meetings", "Meeting Scheduled Created", "Meeting Interaction"],
    calendar_openmeetingrequest: ["Meetings", "Meeting Request Open Overlay", "Meeting Interaction"],
    calendar_openscheduling: ["Meetings", "Meeting Scheduled Open Overlay", "Meeting Interaction"],
    calendar_viewdetails: ["Meetings", "Meeting View Details", "Meeting Interaction"],

    documents_download: ["Documents", "Document Download File"],

    insights_download: ["Insights", "Insights Download"],

    paymentcard_addpaymentmethod: ["Payments", "Add Payment Method"],
    paymentcard_cancel: ["Payments", "Payment Card Cancel"],
    paymentcard_edit: ["Payments", "Payment Card Edit"],
    paymentcard_paid: ["Payments", "Payment Paid"],
    paymentcard_paymentmethodready: ["Payments", "Payment Method Ready"],
    paymentcard_paynow: ["Payments", "Payment Paynow"],
    paymentcard_view: ["Payments", "Payment View"],

    payments_cardreply: ["Payments", "Payment Reply"],

    payrun_create_request: ["Payrun", "Payrun Create Request", "Payrun Interaction"],
    payrun_refresh: ["Payrun", "Payrun Refresh", "Payrun Interaction"],
    payrun_report_approve: ["Payrun", "Payrun Report Approve", "Payrun Interaction"],
    payrun_confirm_complete: ["Payrun", "Payrun Report Confirm Approve", "Payrun Interaction"],
    payrun_lines_approve_all: ["Payrun", "Payrun Approve All Lines", "Payrun Interaction"],
    payrun_review_pending: ["Payrun", "Payrun Review Pending", "Payrun Interaction"],
    payrun_send_for_review: ["Payrun", "Payrun Send For Review", "Payrun Interaction"],
    payrun_view_completed: ["Payrun", "Payrun View Completed", "Payrun Interaction"],
    payrun_card_expand_line: ["Payrun", "Payrun Employee Line Expanded", "Payrun Interaction"],

    rfi_browse_files: ["RFI", "RFI Interaction"],
    rfi_card_cancel: ["RFI", "RFI Card Cancel", "RFI Interaction"],
    rfi_card_edit: ["RFI", "RFI Card Edit", "RFI Interaction"],
    rfi_card_remove: ["RFI", "RFI Card Remove", "RFI Interaction"],
    rfi_card_view: ["RFI", "RFI Card View", "RFI Interaction"],
    rfi_child_collapse: ["RFI", "RFI Interaction"],
    rfi_close: ["RFI", "RFI Close", "RFI Interaction"],
    rfi_collapse: ["RFI", "RFI Interaction"],
    rfi_complete: ["RFI", "RFI Complete", "RFI Interaction"],
    rfi_confirm_complete: ["RFI", "RFI Confirm Complete", "RFI Interaction"],
    rfi_create_delete: ["RFI", "RFI Create Delete", "RFI Interaction"],
    rfi_create_mark_as_report: [
        "RFI",
        "RFI Create Mark As Report",
        "RFI Interaction",
        "Insights",
        "Insights Mark As Report",
    ],
    rfi_create_rename: ["RFI", "RFI Create Rename", "RFI Interaction"],
    rfi_create_request_blank: ["RFI", "RFI Create Blank Request", "RFI Interaction"],
    rfi_create_request_template: ["RFI", "RFI Create Request From Template", "RFI Interaction"],
    rfi_send_for_review: ["RFI", "RFI Send For Review", "RFI Interaction"],

    vault_cardreply: ["RFI", "RFI Reply", "RFI Interaction"],
};

@Injectable()
export class HotjarAnalyticsService implements IAnalyticsService {
    configId: string;

    init(user: AppUser) {
        this.setUserId(user);
    }

    setUserId(user: AppUser) {
        if (!user) {
            return;
        }
        const userId = user.id;
        const userDetailsHasName = user.details?.givenName && user.details?.familyName;
        const name = userDetailsHasName ? `${user.details.givenName} ${user.details.familyName}` : user.name;
        if (window.hj) {
            window.hj("identify", userId, {
                name,
                role: user?.globalRole ? user.globalRole : "Client",
            });
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    pageViewed(_path: string): void {}

    sendEvent(_category: string, action: string, _label?: string, _value?: number) {
        if (window.hj) {
            for (const [eventName, tagName] of Object.entries(HOT_JAR_EVENTS)) {
                if (action === eventName) {
                    const event = tagName?.join("_")?.replace(/\s+/g, "_");
                    window.hj("event", event);
                }
            }
        }
    }

    triggerSurvey(triggerName: string) {
        if (window.hj) {
            window.hj("trigger", triggerName);
        }
    }
}
