import { Component, Inject } from "@angular/core";
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
} from "@angular/material/legacy-dialog";
import { RelevantMemories } from "@visoryplatform/threads";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { Observable } from "rxjs";
import { ENVIRONMENT } from "src/app/injection-token";

@Component({
    selector: "modal-relevant-memories",
    templateUrl: "./modal-relevant-memories.component.html",
    styleUrls: ["./modal-relevant-memories.component.scss"],
})
export class ModalRelevantMemoriesComponent {
    contextMemory: RelevantMemories;
    accountId: string;
    brandingEnabled$: Observable<boolean>;
    memoriesEnabled$: Observable<boolean>;
    aiName: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: { contextMemory: RelevantMemories; accountId: string },
        @Inject(ENVIRONMENT) public environment: EnvironmentSpecificConfig,
        private dialogRef: MatDialogRef<ModalRelevantMemoriesComponent>,
        private featureFlagService: FeatureFlagService,
    ) {
        this.contextMemory = data.contextMemory;
        this.accountId = data.accountId;
        this.aiName = this.environment.aiName;
        this.brandingEnabled$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableDelphiBranding);
        this.memoriesEnabled$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableMemories);
    }

    close(): void {
        this.dialogRef.close();
    }
}
