<ng-container
    *ngIf="{
        card: card$ | async,
        state: state$ | async,
        thread: thread$ | async,
        form: form$ | async,
        allChecksPass: allChecksPass$ | async
    } as context"
>
    <centered-modal-layout
        [loading]="false"
        [cancelButtonTitle]="buttonLabels.Close"
        [disableSave]="!context.allChecksPass"
        [saveButtonTitle]="buttonLabels.NextStep"
        [loading]="!!loader.counter"
        (save)="dialogRef.close()"
        (close)="dialogRef.close()"
    >
        <div modal-header>
            <request-header
                [title]="context.state?.label"
                [isComplete]="context.allChecksPass"
                [completedStatusLabel]="requestStatuses.Completed"
                [accountName]="context.thread?.account?.label || ''"
            ></request-header>
        </div>

        <div modal-content>
            <fx-todolist-item
                *ngFor="let transfomer of context.state?.resultTransforms; trackBy: trackTransformerResult"
                [ngModel]="true"
                [error]="transfomer.latestResult !== true"
                [shouldShowDelete]="false"
                [disabled]="true"
            >
                <div class="rfi-todo-list-todo">
                    <div
                        [ngClass]="{
                            'rfi-todo-list-todo-details--checked': transfomer.latestResult === true
                        }"
                        class="rfi-todo-list-todo-details"
                    >
                        {{ transfomer.label }}
                    </div>
                </div>
            </fx-todolist-item>
        </div>
    </centered-modal-layout>
</ng-container>
