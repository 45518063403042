import { Component } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

@Component({
    selector: "recording-processing-modal",
    templateUrl: "./recording-processing-modal.component.html",
    styleUrls: ["./recording-processing-modal.component.scss"],
})
export class RecordingProcessingModalComponent {
    constructor(private dialogRef: MatDialogRef<RecordingProcessingModalComponent>) {}

    close() {
        this.dialogRef.close();
    }
}
