<div class="reply">
    <ng-container *ngIf="!editingReply">
        <fx-avatar
            [name]="reply.actor | userToName | async"
            [image]="reply.actor | profilePicture : false | async"
            size="medium"
        >
        </fx-avatar>

        <div
            [ngSwitch]="reply.status === CardStatus.Removed"
            class="reply-body"
        >
            <div class="reply-body-header">
                <div class="reply-body-header-name">{{ reply.actor | userToName | async }}</div>
                <div>
                    <div class="reply-body-header-control-panel">
                        <!-- TODO swap to TaigaUI Pill once Taiga is introduced to project-->
                        <div
                            class="reply-body-header-control-panel-status-badge"
                            [unreadStatus]="isUnread"
                        >
                            <fx-badge
                                label="Unread"
                                type="info"
                            ></fx-badge>
                        </div>

                        <div
                            *ngIf="reply.status === CardStatus.Edited"
                            class="reply-body-header-control-panel-edited"
                        >
                            Edited
                        </div>

                        <div class="reply-body-header-control-panel-timestamp">
                            {{ reply.timestamp | date : "dd/MM/yy, h:mm a" }}
                        </div>
                    </div>
                </div>
            </div>

            <div
                *ngSwitchDefault
                class="reply-body-message"
            >
                <read-more [showFullDescription]="!(thread | isThreadActive)">
                    <quill-view
                        [style]="quillStyles"
                        [content]="reply.message | allowWhitelistLinks"
                        aria-label="message"
                    >
                    </quill-view>
                </read-more>
            </div>

            <div
                *ngSwitchCase="true"
                class="reply-body-details-removed"
            >
                This message has been removed.
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="editingReply">
        <div class="reply-edit">
            <quill-editor-wrapper
                (userInputEmitter)="onUserInput($event)"
                (error)="quillError = $event"
                [message]="editReplyMessage"
                [inline]="true"
            >
            </quill-editor-wrapper>

            <div class="reply-edit-buttons">
                <reply-buttons
                    (discardOutput)="editingReply = false; editReplyMessage.setValue(reply.message)"
                    (submitOutput)="updateReply(reply, editReplyMessage)"
                    [submitDisabled]="!editReplyMessage.value || editReplyMessage.value === reply.message || loading"
                >
                </reply-buttons>
            </div>
        </div>
    </ng-container>

    <div
        *ngIf="(enableContextMenu$ | async) && !editingReply"
        class="reply-menu"
    >
        <threads-context-menu
            [card]="card"
            [reply]="reply"
            [thread]="thread"
            [hideMenu]="!(enableContextMenu$ | async)"
        >
            <ng-container other-actions>
                <a
                    (click)="edit()"
                    *ngIf="canEditReply$ | async"
                    >Edit</a
                >
            </ng-container>
            <ng-container delete-action>
                <a
                    (click)="deleteReply(reply)"
                    *ngIf="canEditReply$ | async"
                    >Delete</a
                >
            </ng-container>
        </threads-context-menu>
    </div>

    <div
        *ngIf="reply.status !== CardStatus.Removed && !editingReply"
        class="reply-read-status"
    >
        <card-read-status [readStatus]="reply?.replyStatus"></card-read-status>
    </div>
</div>
