import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { IAccountListing } from "@visoryplatform/threads";
import { Observable } from "rxjs";
import { Loader } from "../../../shared/services/loader";
import { GA_EVENTS } from "../../../analytics/services/gtagAnalytics.service";
import { AccountsService } from "../../../threads-ui/services/accounts.service";
import { WindowListenersService } from "../../../shared/services/window-listeners.service";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ENVIRONMENT } from "src/app/injection-token";
import { SuccessModalComponent } from "../success-modal/success-modal.component";
import { commonModalConfig } from "../../constants/modal.constants";
import { CustomerRequestService } from "../../services/customer-request.service";
import { AuthService } from "../../../findex-auth";
import { filter, shareReplay, switchMap, take, tap } from "rxjs/operators";

type ICreateCustomerRequestModalForm = {
    account: FormControl<IAccountListing | null>;
    message: FormControl<string>;
};

@Component({
    selector: "create-customer-request-modal",
    templateUrl: "./create-customer-request-modal.component.html",
    styleUrls: ["./create-customer-request-modal.component.scss"],
})
export class CreateCustomerRequestModalComponent implements OnInit {
    readonly gaEvents = GA_EVENTS;
    readonly messageSizeQuotaInKB = 128;
    loader = new Loader();
    isMobileView = false;

    accounts$: Observable<IAccountListing[]>;

    form: FormGroup<ICreateCustomerRequestModalForm> = new FormGroup({
        account: new FormControl<IAccountListing | null>(null, [Validators.required]),
        message: new FormControl<string>("", [Validators.required]),
    });

    constructor(
        private dialogRef: MatDialogRef<CreateCustomerRequestModalComponent>,
        private accountService: AccountsService,
        private windowListenersService: WindowListenersService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private dialog: MatDialog,
        private customerRequestService: CustomerRequestService,
        private authService: AuthService,
    ) {
        this.isMobileView = this.windowListenersService.isWindowSmaller(
            this.environment.featureFlags.windowWidthTabletBreakpoint,
        );
    }

    ngOnInit(): void {
        this.accounts$ = this.loader.wrap(this.accountService.listAllAccounts()).pipe(shareReplay(1));

        this.accounts$
            .pipe(
                filter((accounts) => accounts.length === 1),
                take(1),
            )
            .subscribe((accounts) => {
                const firstAccount = accounts[0];
                this.form.controls.account.setValue(firstAccount);
            });
    }

    close(): void {
        this.dialogRef.close();
    }

    async create(): Promise<void> {
        const user = await this.authService.getUser().pipe(take(1)).toPromise();
        const { account, message } = this.form.value;

        this.loader
            .wrap(this.customerRequestService.createRequest(account.id, user.id, message))
            .pipe(
                tap(() => this.dialogRef.close()),
                switchMap((thread) => {
                    const modalConfig = { ...commonModalConfig, data: { thread } };
                    return this.dialog.open(SuccessModalComponent, modalConfig).afterClosed();
                }),
            )
            .subscribe();
    }
}
