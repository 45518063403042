import { Component, OnInit, ViewChild } from "@angular/core";
import { CalendarView } from "angular-calendar";
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, switchMap, takeUntil } from "rxjs/operators";
import { combineLatest, forkJoin, Observable, of } from "rxjs";
import { Loader } from "../../../shared/services/loader";
import { AccountsService } from "../../services/accounts.service";
import { IAccountListing, Role } from "@visoryplatform/threads";
import { CalendarEventsService } from "../../services/calendar-events.service";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { SubscriberBaseComponent } from "../../../shared/components/subscriber-base.component";
import { AppUser, AuthService } from "../../../findex-auth";
import { CalendarEventsApiService } from "../../services/calendar-events-api.service";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { CalendarEmptyServicesModalComponent } from "projects/default-plugins/calendar/components/calendar-empty-services-modal/calendar-empty-services-modal.component";
import { MatLegacyOption as MatOption } from "@angular/material/legacy-core";
import { environmentCommon } from "../../../environment/environment.common";

export interface ServiceFilterItem {
    label: string;
    value: string;
}

type CalendarFilters = {
    selectedAccounts: string[];
    selectedServices: string[];
};

@Component({
    selector: "lib-calendar-header",
    templateUrl: "./calendar-header.component.html",
    styleUrls: ["./calendar-header.component.scss"],
})
export class CalendarHeaderComponent extends SubscriberBaseComponent implements OnInit {
    @ViewChild("allServicesSelected") private allServicesSelected: MatOption;
    @ViewChild("allAccountsSelected") private allAccountsSelected: MatOption;

    public accounts$: Observable<IAccountListing[]>;
    public accounts: IAccountListing[] = [];
    public calendarFilterSelectAlOptions = environmentCommon.calendarFilterSelectAllOptions;
    public filterTypes = { service: "service", account: "account" };
    public loader = new Loader();
    public roleTypes = Role;
    public selectedAccounts = new UntypedFormControl([]);
    public selectedServices = new UntypedFormControl([]);
    public services$: Observable<ServiceFilterItem[]>;
    public services: ServiceFilterItem[] = [];
    public userRole: Role;
    public view: CalendarView = CalendarView.Month;
    public viewDate: Date;

    public calendarFilters = new UntypedFormGroup({
        selectedAccounts: this.selectedAccounts,
        selectedServices: this.selectedServices,
    });

    constructor(
        private accountsService: AccountsService,
        private eventsService: CalendarEventsService,
        private authService: AuthService,
        private calendarApiService: CalendarEventsApiService,
        private matDialog: MatDialog,
    ) {
        super();
    }

    ngOnInit(): void {
        this.setViewDate();
        this.setFilterChanges();
        this.setFilterValues();
    }

    public changeMonthClicked(): void {
        this.eventsService.setViewDate(this.viewDate);
        this.eventsService.setCalendarEvents(this.viewDate);
    }

    public checkSelected(object1: string, object2: string) {
        return object1 && object2 && object1 === object2;
    }

    public trackService(_index: number, service: ServiceFilterItem): string {
        return service.value;
    }

    public trackAccount(_index: number, account: IAccountListing): string {
        return account.id;
    }

    public toggleOneItem(group: string): void {
        switch (group) {
            case this.filterTypes.account:
                if (this.allAccountsSelected.selected) {
                    this.allAccountsSelected.deselect();
                }
                if (this.selectedAccounts.value?.length === this.accounts.length) {
                    this.allAccountsSelected.select();
                }
                break;

            case this.filterTypes.service:
                if (this.allServicesSelected.selected) {
                    this.allServicesSelected.deselect();
                }
                if (this.selectedServices.value?.length === this.services.length) {
                    this.allServicesSelected.select();
                }
                break;
        }
    }

    public toggleSelectAll(group: string): void {
        switch (group) {
            case this.filterTypes.account:
                if (this.allAccountsSelected.selected) {
                    this.selectedAccounts.patchValue([
                        environmentCommon.calendarFilterSelectAllOptions.allAccounts,
                        ...this.accounts.map((item) => item.id),
                    ]);
                    this.allAccountsSelected.select();
                } else {
                    this.selectedAccounts.patchValue([]);
                    this.allAccountsSelected.deselect();
                }
                break;

            case this.filterTypes.service:
                if (this.allServicesSelected.selected) {
                    this.selectedServices.patchValue([
                        environmentCommon.calendarFilterSelectAllOptions.allServices,
                        ...this.services.map((item) => item.value),
                    ]);
                    this.allServicesSelected.select();
                } else {
                    this.selectedServices.patchValue([]);
                    this.allServicesSelected.deselect();
                }
                break;
        }
    }

    private setViewDate(): void {
        this.eventsService.calendarViewDate$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((viewDate: Date) => (this.viewDate = viewDate));
    }

    private setFilterValues(): void {
        this.accounts$ = this.accountsService.listAllAccounts().pipe(
            map(
                (accounts: IAccountListing[]) =>
                    (this.accounts = accounts.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))),
            ),
            shareReplay(1),
        );
        this.services$ = this.getServices().pipe(
            map(
                (services: ServiceFilterItem[]) =>
                    (this.services = services.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))),
            ),
            shareReplay(1),
        );

        this.loader
            .wrap(forkJoin([this.accounts$, this.services$]))
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([accounts, services]) => this.setSavedFilters(accounts, services));
    }

    private setSavedFilters(accounts: IAccountListing[], services: ServiceFilterItem[]): void {
        this.authService
            .getUser()
            .pipe(
                filter((user: AppUser) => !!user),
                switchMap((user: AppUser) => {
                    this.userRole = user.globalRole;
                    return this.calendarApiService.getSavedCalendarFilters(user.id);
                }),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe((filters) => {
                if (filters && (filters.selectedServices?.length || filters.selectedAccounts?.length)) {
                    this.calendarFilters.setValue({
                        selectedServices: filters.selectedServices ?? [],
                        selectedAccounts: filters.selectedAccounts ?? [],
                    });
                } else {
                    this.setDefaultFilterValues(this.userRole, accounts, services);
                }
            });
    }

    private setDefaultFilterValues(role: Role, accounts: IAccountListing[], services: ServiceFilterItem[]): void {
        const servicesValues = services?.map((service) => service.value);
        const accountIDs = accounts?.map((account) => account.id);
        const selectedServices = role === this.roleTypes.Client ? servicesValues : [];
        const selectedAccounts = role === this.roleTypes.Client ? accountIDs : [];

        this.calendarFilters.setValue({
            selectedServices,
            selectedAccounts,
        });

        if (!selectedServices.length && !selectedAccounts.length) {
            this.showEmptyStateModal();
        }
    }

    private showEmptyStateModal(): void {
        this.matDialog.open(CalendarEmptyServicesModalComponent, {
            disableClose: false,
            backdropClass: "modal-backdrop",
            panelClass: [],
            closeOnNavigation: true,
            hasBackdrop: true,
            height: "auto",
        });
    }

    private setFilterChanges(): void {
        const filters$ = this.calendarFilters.valueChanges.pipe(distinctUntilChanged(), debounceTime(300));
        const userId$ = this.authService.getUser().pipe(
            filter((user: AppUser) => !!user),
            map((user: AppUser) => user.id),
        );

        combineLatest([filters$, userId$])
            .pipe(
                switchMap(([filters, userId]: [CalendarFilters, string]) =>
                    this.eventsService.setCalendarFilters(userId, filters),
                ),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe(() => this.eventsService.setCalendarEvents(this.viewDate));
    }

    private getServices(): Observable<ServiceFilterItem[]> {
        // TODO get this from API somewhere...?!?
        return of([
            {
                label: "Bookkeeping",
                value: "bookkeeping",
            },
            {
                label: "Payroll - Weekly",
                value: "payroll",
            },
            {
                label: "Payroll - Fortnightly",
                value: "payroll_fortnightly",
            },
            {
                label: "Payroll - Monthly",
                value: "payroll_monthly",
            },
            {
                label: "Accounts Payable - Weekly",
                value: "accounts_payable_weekly",
            },
            {
                label: "Accounts Payable - Fortnightly",
                value: "accounts_payable",
            },
            {
                label: "Accounts Payable - Monthly",
                value: "accounts_payable_monthly",
            },
        ]);
    }
}
