<v-toast></v-toast>
<tui-root>
    <ng-container *ngIf="!hideLayout; else loggedOut">
        <div class="fx-body-container">
            <div
                class="fx-l-header"
                *ngIf="!isIframe"
            >
                <ng-container *ngIf="bannerUpdates$ | async as notifications">
                    <list-notification-banners
                        *ngIf="notifications?.length"
                        [notifications]="notifications"
                    >
                    </list-notification-banners>
                </ng-container>

                <findex-header
                    [layoutOpen]="showNavigation$ | async"
                    [showMenuToggle]="showMobileCollapsedIcon$ | async"
                    (logout)="logout()"
                    (menu)="menuAction($event)"
                >
                    <ng-container *ngIf="user$ | async as user">
                        <customer-request-global-button [role]="role"></customer-request-global-button>
                        <div
                            id="slide-menu-notifications"
                            class="item"
                        >
                            <a
                                (click)="toggleRecentNotifications()"
                                [analyticsClick]="gaEvents.APP_RECENTNOTIFICATIONS"
                                class="recent-notifications-link"
                                [ngClass]="{
                                    active: (showNotificationMenu$ | async) || (showNotificationMobileMenu$ | async)
                                }"
                            >
                                <div
                                    class="notification-counter"
                                    *ngIf="unreadNotificationCount"
                                >
                                    {{
                                        unreadNotificationCount > NOTIFICATION_COUNT_LIMIT
                                            ? NOTIFICATION_COUNT_LIMIT + "+"
                                            : unreadNotificationCount
                                    }}
                                </div>
                                <i class="las la-bell"></i>
                            </a>
                        </div>
                        <div
                            class="item add-cursor"
                            cdkOverlayOrigin
                            #userProfileTrigger="cdkOverlayOrigin"
                            (click)="toggleUserProfileDropdown()"
                        >
                            <fx-avatar
                                [ngClass]="{ 'system-avatar': user.id | isSystemName }"
                                [name]="user.name"
                                [image]="user.id | profilePicture | async"
                                size="medium"
                            ></fx-avatar>
                        </div>
                        <ng-template
                            cdkConnectedOverlay
                            (backdropClick)="hideProfileMenu()"
                            cdkConnectedOverlayBackdropClass="transparent-overlay-background"
                            [cdkConnectedOverlayHasBackdrop]="true"
                            [cdkConnectedOverlayOrigin]="userProfileTrigger"
                            [cdkConnectedOverlayOpen]="showProfileMenu$ | async"
                            [cdkConnectedOverlayPositions]="[
                                {
                                    originX: isCollapsedView ? 'end' : 'center',
                                    originY: 'bottom',
                                    overlayX: 'end',
                                    overlayY: 'top',
                                    offsetX: isCollapsedView ? 12 : 0
                                }
                            ]"
                        >
                            <div class="small-overlay-window">
                                <user-profile-dropdown
                                    [user]="user"
                                    [showSwitchAccount]="role | permissions : 'ReadUsers' | async"
                                    [supportEmail]="supportEmail"
                                    (logout)="logout()"
                                    (switchAccount)="switchUser()"
                                ></user-profile-dropdown>
                            </div>
                        </ng-template>
                    </ng-container>
                </findex-header>
            </div>

            <!-- not sure if this naked does anything-->
            <div
                class="fx-l"
                [ngClass]="{ 'fx-l--naked': hideLayout, 'fx-l--hide-nav': (showNavigation$ | async) !== true }"
            >
                <nav class="fx-l-nav">
                    <side-menu (click)="mobileMenuAction(false)">
                        <menu-item
                            id="home-menu-item"
                            icon="la-home"
                            [route]="['/dashboard']"
                            analyticsClick="app_viewdashboard"
                            >Home</menu-item
                        >

                        <menu-item
                            id="workflows-menu-item"
                            icon="la-project-diagram"
                            [route]="['/workflows']"
                            analyticsClick="app_viewtimelines"
                            >Workflows</menu-item
                        >

                        <menu-item
                            id="calendar-menu-item"
                            *ngIf="canViewCalendarPage"
                            icon="la-calendar"
                            [route]="['/calendar']"
                            analyticsClick="app_viewcalendar"
                            >Calendar</menu-item
                        >

                        <menu-item
                            id="accounts-menu-item"
                            *ngIf="showAccounts"
                            icon="la-city"
                            [route]="['/accounts']"
                            analyticsClick="app_viewaccounts"
                            >Accounts</menu-item
                        >

                        <menu-item
                            *ngIf="role | permissions : 'ReadUsers' | async"
                            icon="la-user-circle"
                            [route]="['/admin']"
                            analyticsClick="app_viewadmin"
                            >Users</menu-item
                        >

                        <menu-item
                            *ngFor="let extension of extensions$ | async; trackBy: 'id' | trackProperty"
                            class="app-extension {{ extension.id }}-menu-item"
                            analyticsClick="app_view{{ extension.id }}"
                            [icon]="extension.icon"
                            [route]="['/' + extension.path]"
                            [id]="extension.id + '-menu-item'"
                        >
                            {{ extension.label }}
                        </menu-item>
                        <menu-item
                            *ngIf="isStaffOrAdmin$ | async"
                            icon="la-globe"
                            [route]="['https://community.visory.com.au/home']"
                            [isExternalLink]="true"
                            analyticsClick="app_viewcommunity"
                        >
                            <span label>Community</span>
                        </menu-item>
                    </side-menu>
                </nav>

                <div class="fx-l-canvas">
                    <findex-slide-menu
                        [open]="(showNotificationMobileMenu$ | async) === true"
                        (close)="toggleRecentNotifications()"
                        *ngIf="user$ | async"
                    >
                        <ng-container *ngIf="showNotificationMobileMenu$ | async">
                            <list-notifications
                                *ngIf="notifications$ | async; let notifications; else: loading"
                                [notifications]="notifications"
                                [showUnreadOnly]="showUnreadNotificationsOnly"
                                (getNextPage)="getNextActivityPage()"
                                (open)="openNotification($any($event))"
                                (markAllAsRead)="markAllAsUnread()"
                                (markAsUnread)="recordEvent('unread')"
                                (deleteMessage)="recordEvent('delete')"
                                (markAsRead)="recordEvent('read')"
                                (settingsNavigate)="navigateToSettings()"
                                class="notification-list"
                            >
                            </list-notifications>

                            <ng-template #loading>
                                <loader-icon color="light"></loader-icon>
                            </ng-template>
                        </ng-container>
                    </findex-slide-menu>

                    <div class="fx-l-main">
                        <findex-main [loading]="loader.counter">
                            <router-outlet *ngIf="!isIframe"></router-outlet>
                        </findex-main>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-template #loggedOut>
        <router-outlet *ngIf="!isIframe"></router-outlet>
    </ng-template>
</tui-root>
