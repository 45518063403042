<div class="stepper">
    <stepper-breadcrumbs
        [steps]="steps"
        [selectedIndex]="selectedIndex"
    ></stepper-breadcrumbs>

    <div class="stepper-content">
        <ng-container [ngTemplateOutlet]="selected.content"> </ng-container>
    </div>

    <stepper-footer
        [disabled]="disabled"
        [endStepLabel]="endStepLabel"
        [selectedIndex]="selectedIndex"
        [totalSteps]="steps.length"
        (completed)="completed.emit()"
    ></stepper-footer>
</div>
