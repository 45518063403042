import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { Notification, NotificationState, NotificationTopic, WebsocketData } from "@visoryplatform/notifications-core";
import { NotificationsService } from "../../services/notifications.service";
import { FxContextMenuComponent } from "@visoryplatform/fx-ui";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { FormControl } from "@angular/forms";

@Component({
    selector: "list-notifications",
    templateUrl: "./list-notifications.component.html",
    styleUrls: ["./list-notifications.component.scss"],
})
export class ListNotificationsComponent implements OnInit, OnChanges {
    @Input() notifications: Notification[];
    @Input() showUnreadOnly: FormControl<boolean>;

    @Output() open = new EventEmitter<Notification>();
    @Output() deleteMessage = new EventEmitter();
    @Output() markAsRead = new EventEmitter();
    @Output() markAsUnread = new EventEmitter();
    @Output() markAllAsRead = new EventEmitter();
    @Output() getNextPage = new EventEmitter();
    @Output() settingsNavigate = new EventEmitter();

    @ViewChild("allNotificationsMenu", { static: false })
    menu: FxContextMenuComponent;
    @ViewChild(CdkVirtualScrollViewport)
    scrollViewport: CdkVirtualScrollViewport;

    NotificationState = NotificationState;
    filteredNotifications = [];

    isListEnd = false;

    constructor(private notificationsService: NotificationsService) {}

    public ngOnInit(): void {
        this.filteredNotifications = this.filterNotifications();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes?.notifications?.currentValue) {
            this.filteredNotifications = this.filterNotifications();
        }
    }

    public handleNextPage(): void {
        const end = this.scrollViewport.getRenderedRange().end;
        const total = this.scrollViewport.getDataLength();
        if (end === total && !this.isListEnd) {
            this.isListEnd = true;
            this.getNextPage.emit();
        } else {
            this.isListEnd = false;
        }
    }

    public filterNotifications(): Notification[] {
        return this.notifications.filter(
            (notification) =>
                notification.state !== NotificationState.Deleted &&
                notification.topic !== NotificationTopic.ReadReceipt,
        );
    }

    public markAllNotificationsAsRead(): void {
        this.markAllAsRead.emit();
    }

    public markNotificationUnread(notification: Notification): void {
        if (!(notification.state === NotificationState.Resolved || notification.state === NotificationState.Deleted)) {
            return;
        }

        this.notificationsService.markAsUnread(notification.id);

        notification.state = NotificationState.Delivered;
        this.markAsUnread.emit();
    }

    public markNotificationRead(notification: Notification): void {
        if (notification.state === NotificationState.Resolved || notification.state === NotificationState.Deleted) {
            return;
        }

        this.notificationsService.markAsRead(notification.channel);
        notification.state = NotificationState.Resolved;
        this.markAsRead.emit();
    }

    public deleteNotification(notification: Notification): void {
        this.notificationsService.markAsDeleted(notification.id);
        notification.state = NotificationState.Deleted;
    }

    public openNotification(notification: Notification<WebsocketData>): void {
        this.open.emit(notification);
        this.markNotificationRead(notification);
    }

    public trackNotification(_index: number, notification: Notification): string {
        return notification?.id;
    }
}
