import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Injector } from "@angular/core";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { TaskAction } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { CardResources } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import {
    DefaultPermissions,
    PermissionService,
} from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { of } from "rxjs";
import { switchMap, filter, mergeMap, take } from "rxjs/operators";
import { CardTaskActionLabel, IVcCardState } from "@visoryplatform/threads";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { VideoCallEndSessionModalComponent } from "./components/video-call-end-session-modal/video-call-end-session-modal.component";
import { VideoCallService } from "./services/video-call.service";

const endCallAction = () => (cardResources: Partial<CardResources<IVcCardState>>, injector: Injector) => {
    const matDialog = injector.get(MatDialog);
    const permissionService = injector.get<PermissionService>(PermissionService);
    const videoCallService = injector.get<VideoCallService>(VideoCallService);
    const { role, state$, threadId, cardId, loader } = cardResources;

    return permissionService
        .checkPermissions(role, DefaultPermissions.VcEndSession)
        .pipe(
            switchMap((hasPermission) => {
                if (!hasPermission) {
                    return of(false);
                } else {
                    return showEndSessionModal(matDialog);
                }
            }),
            filter((confirmed) => !!confirmed),
            mergeMap(() => {
                return state$.pipe(
                    take(1),
                    switchMap((state) => {
                        const { sessionKey } = state;
                        return endSession(sessionKey, loader, videoCallService, threadId, cardId);
                    }),
                );
            }),
        )
        .toPromise();
};

export const videoCallEndSessionTaskAction: TaskAction<any> = {
    analyticsEvents: [GA_EVENTS.APP_ENDVCSESSION],
    cardTypes: ["calendar", "video-call"],
    action: endCallAction(),
    buttonLabel: CardTaskActionLabel.EndSession,
};

const endSession = (
    sessionKey: string,
    loader: Loader,
    videoCallService: VideoCallService,
    threadId: string,
    cardId: string,
) => loader.wrap(videoCallService.endSession(sessionKey, threadId, cardId));

const showEndSessionModal = (matDialog: MatDialog) =>
    matDialog
        .open<VideoCallEndSessionModalComponent, null, boolean>(VideoCallEndSessionModalComponent, {
            disableClose: false,
            closeOnNavigation: false,
            hasBackdrop: true,
            panelClass: ["centered-modal"],
        })
        .afterClosed();
