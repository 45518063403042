<div *ngIf="visible">
    <div *ngIf="editMode === true && !!enableCustomQuery">
        <quill-editor-wrapper
            [message]="control"
            placeholder="Query"
        >
        </quill-editor-wrapper>
    </div>

    <div
        *ngIf="(editMode === true && !enableCustomQuery) || !editMode"
        class="query-readonly"
    >
        <quill-view
            [content]="control.value"
            aria-label="Query"
        ></quill-view>
        &nbsp;
        <i
            *ngIf="editMode === true && !enableCustomQuery"
            (click)="enableCustomQuery = true"
            class="las la-edit"
        ></i>
    </div>
</div>
