import { WeekDays } from "@visoryplatform/threads";

export enum Providers {
    legacy = "legacy",
}

export enum FrequencyLabel {
    weekly = "Weekly",
    fortnightly = "Fortnightly",
    monthly = "Monthly",
    quarterly = "Quarterly",
}

export enum WorkflowDesignsEnum {
    accountManagement = "Account Management",
    activityStatement = "Activity statement",
    basClientPays = "Business activity statement - Client pays",
    basVisoryPays = "Business activity statement - Visory pays",
    iasClientPays = "Instalment activity statement - Client pays",
    iasVisoryPays = "Instalment activity statement - Visory pays",
    gstReturnClientPays = "GST return - Client pays",
    gstReturnVisoryPays = "GST return - Visory pays",
    bookkeepingMonthly = "Bookkeeping Monthly",
    catchUp = "Catch Up",
    gettingToKnowYou = "Getting to know you",
    handover = "Handover",
    onboarding = "Onboarding",
    payrollAutomated = "Payroll - Automated",
    payrollTax = "Payroll tax",
    superannuation = "Superannuation",
    alerts = "Delphi alert",
}

export const WEEK_DAYS_SELECT = Object.freeze({
    [WeekDays.Monday]: "monday",
    [WeekDays.Tuesday]: "tuesday",
    [WeekDays.Wednesday]: "wednesday",
    [WeekDays.Thursday]: "thursday",
    [WeekDays.Friday]: "friday",
});

export const FORTNIGHTLY_DAYS_SELECT = Object.freeze({
    [WeekDays.Monday]: "first monday",
    [WeekDays.Tuesday]: "first tuesday",
    [WeekDays.Wednesday]: "first wednesday",
    [WeekDays.Thursday]: "first thursday",
    [WeekDays.Friday]: "first friday",
    [WeekDays.Monday + 5]: "second monday",
    [WeekDays.Tuesday + 5]: "second tuesday",
    [WeekDays.Wednesday + 5]: "second wednesday",
    [WeekDays.Thursday + 5]: "second thursday",
    [WeekDays.Friday + 5]: "second friday",
});

export const DAY_HOURS_SELECT = Object.freeze({
    "8": "8:00 AM",
    "9": "9:00 AM",
    "10": "10:00 AM",
    "11": "11:00 AM",
    "12": "12:00 PM",
    "13": "1:00 PM",
    "14": "2:00 PM",
    "15": "3:00 PM",
    "16": "4:00 PM",
    "17": "5:00 PM",
    "18": "6:00 PM",
    "19": "7:00 PM",
    "20": "8:00 PM",
});

export const DAY_HOURS = Object.freeze({
    "1": "1:00 AM",
    "1.5": "1:30 AM",
    "2": "2:00 AM",
    "2.5": "2:30 AM",
    "3": "3:00 AM",
    "3.5": "3:30 AM",
    "4": "4:00 AM",
    "4.5": "4:30 AM",
    "5": "5:00 AM",
    "5.5": "5:30 AM",
    "6": "6:00 AM",
    "6.5": "6:30 AM",
    "7": "7:00 AM",
    "7.5": "7:30 AM",
    "8": "8:00 AM",
    "8.5": "8:30 AM",
    "9": "9:00 AM",
    "9.5": "9:30 AM",
    "10": "10:00 AM",
    "10.5": "10:30 AM",
    "11": "11:00 AM",
    "11.5": "11:30 AM",
    "12": "12:00 PM",
    "12.5": "12:30 PM",
    "13": "1:00 PM",
    "13.5": "1:30 PM",
    "14": "2:00 PM",
    "14.5": "2:30 PM",
    "15": "3:00 PM",
    "15.5": "3:30 PM",
    "16": "4:00 PM",
    "16.5": "4:30 PM",
    "17": "5:00 PM",
    "17.5": "5:30 PM",
    "18": "6:00 PM",
    "18.5": "6:30 PM",
    "19": "7:00 PM",
    "19.5": "7:30 PM",
    "20": "8:00 PM",
    "20.5": "8:30 PM",
    "21": "9:00 PM",
    "21.5": "9:30 PM",
    "22": "10:00 PM",
    "22.5": "10:30 PM",
    "23": "11:00 PM",
    "23.5": "11:30 PM",
    "24": "0:00 AM",
    "24.5": "0:30 AM",
});
