import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Observable } from "rxjs";
import { ITimeline, IPayRunReport } from "@visoryplatform/threads";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { FormGroup } from "@angular/forms";
import { PayRunProviders } from "../../constants/payrun-report-constants";
import { map, shareReplay } from "rxjs/operators";
import { PayrunReportDataService } from "../../services/payrun-report-data.service";
import { ICreatePayrunListItem } from "projects/default-plugins/vault/components/request/interfaces/request.interfaces";
import { IPayrunReportLineData } from "../../interfaces/IPayrunReportLineData";

@Component({
    selector: "payrun-report-create-request",
    templateUrl: "./payrun-report-create-request.component.html",
    styleUrls: ["./payrun-report-create-request.component.scss"],
})
export class PayrunReportCreateRequestComponent implements OnInit, OnChanges {
    @Input() reportListItem: ICreatePayrunListItem;
    @Input() thread: ITimeline;
    @Input() cardDescription: any;

    form: FormGroup;
    report$: Observable<IPayRunReport>;
    tableData$: Observable<MatTableDataSource<IPayrunReportLineData>>;

    private readonly dataSource = new MatTableDataSource<IPayrunReportLineData>();

    constructor(
        private payrunDataService: PayrunReportDataService,
        private payrunReportDataService: PayrunReportDataService,
    ) {}

    ngOnInit(): void {
        this.tableData$ = this.getDataSource(this.report$);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { reportListItem } = changes;

        if (reportListItem) {
            this.report$ = this.getPayRunReport(
                reportListItem.currentValue.id,
                this.thread?.accountId,
                PayRunProviders.EmploymentHero,
            );
            this.tableData$ = this.getDataSource(this.report$);
        }
    }

    private getPayRunReport(reportId: number, accountId: string, provider: PayRunProviders): Observable<IPayRunReport> {
        const report$ = this.payrunDataService.getPayRunReportById(reportId, accountId, provider);
        return report$.pipe(shareReplay(1));
    }

    private getDataSource(report$: Observable<IPayRunReport>): Observable<MatTableDataSource<IPayrunReportLineData>> {
        return report$.pipe(
            map((report) => {
                const dataSource = this.dataSource;
                dataSource.data = report.lines
                    .sort((a, b) => a.employeeName.localeCompare(b.employeeName))
                    .map((reportLine) => ({
                        ...reportLine,
                        currency: this.payrunReportDataService.getCurrencyByRegion(report.region),
                        hideRowChild: true,
                    }));
                return dataSource;
            }),
        );
    }
}
