<div class="recent-notifications">
    <div class="recent-notifications-header">
        <div class="recent-notifications-header-main">
            <div class="recent-notifications-header-main-title"><i class="las la-bell"></i> Notifications</div>
            <fx-context-menu
                *ngIf="notifications?.length !== 0"
                #allNotificationsMenu
                [gutter]="false"
                class="recent-notifications-header-main-mark-all"
            >
                <a (click)="markAllNotificationsAsRead()">
                    <i class="ion-md-checkmark"></i>
                    <span>Mark all as read</span>
                </a>
                <a (click)="settingsNavigate.emit()">
                    <i class="las la-cog"></i>
                    Settings</a
                >
            </fx-context-menu>
        </div>

        <div class="recent-notifications-header-unread">
            <v-toggle [formControl]="showUnreadOnly">Show unread only</v-toggle>
        </div>
    </div>

    <div
        [ngSwitch]="!!(filteredNotifications | countNotifications)"
        class="recent-notifications-content"
    >
        <div
            *ngSwitchCase="true"
            class="recent-notifications-list-container"
        >
            <cdk-virtual-scroll-viewport
                (scrolledIndexChange)="handleNextPage()"
                [itemSize]="100"
                class="recent-notifications-viewport"
            >
                <notification
                    *cdkVirtualFor="let notification of filteredNotifications; trackBy: trackNotification"
                    [notification]="notification"
                    (markAsUnread)="markNotificationUnread(notification)"
                    (markAsRead)="markNotificationRead(notification)"
                    (delete)="deleteNotification(notification)"
                    (open)="openNotification(notification)"
                >
                </notification>
            </cdk-virtual-scroll-viewport>
        </div>

        <div
            *ngSwitchDefault
            class="recent-notifications-none"
        >
            <div>
                <img
                    width="126"
                    height="122"
                    src="./assets/images/notifications-none.svg"
                />
            </div>
            <p>You are all caught up!</p>
            <p>Check back later for new notifications</p>
        </div>
    </div>
</div>
