/* eslint-disable max-lines-per-function */
import { Component, ComponentFactoryResolver, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import { combineLatest, Observable, of, Subscription, throwError, zip } from "rxjs";
import { UserProfileService } from "../../../services/user-profile.service";
import { catchError, distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap } from "rxjs/operators";
import { AppUser } from "projects/portal-modules/src/lib/findex-auth/model/AppUser";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ActivatedRoute } from "@angular/router";
import { ChangePasswordDialogComponent } from "../../change-password-dialog/change-password-dialog.component";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { Role, UserRoleService } from "@visoryplatform/threads";
import { AdminSetPasswordDialogComponent } from "../../admin-set-password-dialog/admin-set-password-dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { customValidators } from "projects/portal-modules/src/lib/shared/validators";
import { EnvironmentSpecificConfig } from "../../../../environment/environment.common";
import { UserProfileExtensionDirective } from ".././user-profile-extension.directive";
import { IUserProfileExtension } from ".././IUserProfileExtension";
import { ThreadsService } from "../../../../threads-ui/services/threads.service";
import { ENVIRONMENT } from "src/app/injection-token";
import { environmentCommon } from "src/environments/environment";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { HandledError } from "projects/portal-modules/src/lib/shared/interfaces/errors";
import { UserRoleOptionsService } from "../../../services/user-role-options.service";
import { FilterOption } from "projects/portal-modules/src/lib/timeline/interfaces/timeline-filters";

@Component({
    selector: "general-settings-component",
    templateUrl: "./general-settings.component.html",
    styleUrls: ["./general-settings.component.scss"],
})
export class GeneralSettingsComponent implements OnInit, OnDestroy {
    @ViewChild(UserProfileExtensionDirective, { static: true })
    userProfileExtensionDirective: UserProfileExtensionDirective;

    readonly noRole = "No global role";
    readonly theme = this.environment.theme;
    readonly signupCountries = this.environment.featureFlags.signupCountries;
    readonly staffSupportUrl = environmentCommon.staffSupportUrl;

    userProfileExtension: IUserProfileExtension;
    userProfileExtensionSaveEnabled = false;
    userId: string;
    Role = Role;
    myRole$: Observable<Role>;
    roleOptions: Observable<FilterOption[]>;
    originalProfileRole: string;
    isUserFromSearch$: Observable<boolean>;
    isAdUser$: Observable<boolean>;
    canUpdateUserDetails$: Observable<boolean>;
    avatarNameString = "";
    errorMessage = "";
    internationalPhoneNo: string;
    internationalPhoneNoValid: boolean;
    userAvatarUrl: string;
    loader = new Loader();
    form = new FormGroup({
        givenName: new FormControl(null, [Validators.required]),
        familyName: new FormControl(null, [Validators.required]),
        emailAddress: new FormControl({ value: null, disabled: true }, [Validators.required, Validators.email]),
        mobileNumber: new FormControl({ value: null, disabled: true }, [
            Validators.required,
            customValidators.mobileValidator(this.signupCountries),
        ]),
        profileRole: new FormControl<FilterOption>(null),
    });

    private shouldEnableSaveSubscription: Subscription;
    private onErrorSubscription: Subscription;
    private originalProfile: AppUser;
    private errorSubscription: Subscription;
    private profileSubscription: Subscription;
    private disabledFieldsSubscription: Subscription;
    private currentUserRole: Role;

    constructor(
        private userProfileService: UserProfileService,
        private authService: AuthService,
        private route: ActivatedRoute,
        private threadsService: ThreadsService,
        private dialog: MatDialog,
        private location: Location,
        private permissionService: PermissionService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private userRoleOptionsService: UserRoleOptionsService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    ngOnInit(): void {
        this.errorMessage = "";
        this.roleOptions = this.userRoleOptionsService.get().pipe(shareReplay(1));

        this.errorSubscription = this.route.queryParams.subscribe((queryParams) => {
            const { errorMessage } = queryParams;
            if (errorMessage) {
                this.errorMessage = errorMessage;
            }
        });

        const routeUserId$ = this.route.parent.params.pipe(
            map((params) => params.userId),
            distinctUntilChanged(),
        );

        const userProfile$ = routeUserId$.pipe(
            switchMap((userId) => this.getProfile(userId)),
            catchError((error: unknown) => this.handleError(error)),
        );

        const profileRole$ = routeUserId$.pipe(
            switchMap((userId) => this.userIdOrMe(userId)),
            tap((userId) => this.refreshProfileRole(userId)),
        );

        this.disabledFieldsSubscription = routeUserId$
            .pipe(
                switchMap((userId) => this.userIdOrMe(userId)),
                map((userId) => this.isAzureAdUser(userId)), //TODO: we probably need a user type to come with the profile
            )
            .subscribe((disabledField) => {
                if (disabledField) {
                    this.form.controls.givenName.disable();
                    this.form.controls.familyName.disable();
                }
            });

        this.profileSubscription = zip(userProfile$, profileRole$)
            .pipe(
                switchMap(([user, _]) => {
                    if (user) {
                        this.extractFieldsFromUserProfile(user);
                        return of(this.initializeExtension());
                    }
                    return of(undefined);
                }),
            )
            .subscribe();

        this.myRole$ = this.authService.getUser().pipe(
            filter((user) => !!user),
            map((user) => user.globalRole),
            tap((role) => (this.currentUserRole = role)),
            shareReplay(1),
        );

        this.isUserFromSearch$ = this.route.parent.url.pipe(
            map((urlSegmentArray) => urlSegmentArray.find((urlSegment) => urlSegment.path === "clients") !== undefined),
        );

        this.isAdUser$ = routeUserId$.pipe(
            switchMap((userId) => this.userIdOrMe(userId)),
            map((userId) => (this.isAzureAdUser(userId) ? true : false)),
        );

        this.canUpdateUserDetails$ = this.myRole$.pipe(
            switchMap((role) =>
                this.permissionService.checkPermissions(role, ["UpdateClientUsers", "UpdateStaffUsers"]),
            ),
        );
    }

    ngOnDestroy(): void {
        this.disabledFieldsSubscription?.unsubscribe();
        this.errorSubscription?.unsubscribe();
        this.profileSubscription?.unsubscribe();
    }

    back(): void {
        this.location.back();
    }

    refreshProfileRole(userId: string): void {
        const refreshRole$ = this.threadsService.getGlobalRole(userId);
        this.loader.wrap(combineLatest([refreshRole$, this.roleOptions]).pipe(take(1))).subscribe(([role, options]) => {
            this.originalProfileRole = role;
            const roleOption = options.find((option) => option.key === role);
            this.form.controls.profileRole.setValue(roleOption);
        });
    }

    saveChanges(): void {
        this.errorMessage = "";
        this.commitChanges();
    }

    showResetPasswordDialog() {
        const options = {
            disableClose: true,
            panelClass: ["modal-container", "mat-dialog-no-styling"],
            maxWidth: "100%",
            minWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            data: { userId: this.userId },
        };

        if (this.currentUserRole) {
            return this.dialog.open(AdminSetPasswordDialogComponent, options);
        } else {
            return this.dialog.open(ChangePasswordDialogComponent, options);
        }
    }

    private extractFieldsFromUserProfile(user: AppUser): void {
        this.originalProfile = user;
        this.form.get("givenName").setValue(user.details.givenName);
        this.form.get("familyName").setValue(user.details.familyName);
        this.avatarNameString = `${user.details.givenName} ${user.details.familyName}`;

        if (user.details) {
            const mobileNumber = user.details.mobileNumber || "";
            this.form.controls.mobileNumber.setValue(mobileNumber);
            const emailAddress = user.details.emailAddress || "";
            this.form.controls.emailAddress.setValue(emailAddress.toLowerCase());
        } else {
            this.errorMessage = "Profile for user could not be found";
        }
    }

    private extractFieldsFromRoleData(role): void {
        this.originalProfileRole = role;
    }

    private getChangedUserProperties() {
        const profile = this.originalProfile;
        const profileDetails = this.originalProfile.details;
        if (!profile || !profileDetails) {
            return {};
        }

        const formGivenName = this.form.get("givenName").value;
        const formFamilyName = this.form.get("familyName").value;
        const nameHasChanged = this.validateNameChange(profile, formGivenName, formFamilyName);

        const givenName = nameHasChanged ? formGivenName : undefined;
        const familyName = nameHasChanged ? formFamilyName : undefined;

        const formEmailAddress = this.form.controls.emailAddress.value;
        const profileDetailEmail = profileDetails.emailAddress || "";
        const emailAddress =
            formEmailAddress.toLowerCase() !== profileDetailEmail.toLowerCase()
                ? formEmailAddress.toLowerCase()
                : undefined;

        const formMobileNumber = this.form.controls.mobileNumber.value;
        const profileMobileNumber = profileDetails.mobileNumber || "";
        const mobileNumber =
            formMobileNumber.toLowerCase() !== profileMobileNumber.toLowerCase()
                ? formMobileNumber.toLowerCase()
                : undefined;

        const details = {
            givenName,
            familyName,
            emailAddress,
            mobileNumber,
        };
        return Object.keys(details).reduce((acc, key) => {
            const value = details[key];
            return value ? { ...acc, [key]: value } : acc;
        }, {});
    }

    // NOTE: we check givenName/ familyName for azure ad users as we don't control the display name
    private validateNameChange(profile: AppUser, formGivenName: string, formFamilyName: string): boolean {
        if (this.isAzureAdUser(profile.id)) {
            return profile.details.givenName !== formGivenName && profile.details.familyName !== formFamilyName;
        }

        return profile.name !== `${formGivenName} ${formFamilyName}`;
    }

    private userIdOrMe(userId?: string): Observable<string> {
        if (userId) {
            this.userId = userId;
            return of(userId);
        } else {
            return this.authService.getUser().pipe(
                take(1),
                filter((user) => !!user),
                map((user) => user.id),
                tap((id) => {
                    this.userId = id;
                }),
            );
        }
    }

    private getProfile(userId: string): Observable<AppUser> {
        if (userId) {
            return this.loader.wrap(this.userProfileService.getUserProfile(userId));
        } else {
            return this.loader.wrap(this.userProfileService.getCurrentUserProfile());
        }
    }

    private updateProfile(userId: string, changedUserProperties: any): Observable<{ updatedUser: AppUser }> {
        const originalId = this.originalProfile.id;
        if (userId) {
            return this.userProfileService
                .updateUserProfile(userId, changedUserProperties)
                .pipe(map((result) => ({ updatedUser: { ...result.updatedUser, id: originalId } })));
        } else {
            return this.userProfileService
                .updateCurrentUserProfile(changedUserProperties)
                .pipe(map((result) => ({ updatedUser: { ...result.updatedUser, id: originalId } })));
        }
    }

    private async commitChanges(): Promise<void> {
        this.loader.show();

        const changedUserProperties = this.getChangedUserProperties();

        try {
            if (Object.keys(changedUserProperties).length > 0) {
                const userId = this.route.parent.snapshot.params.userId;
                const updatedProfileDetails = await this.updateProfile(userId, changedUserProperties).toPromise();
                this.extractFieldsFromUserProfile(updatedProfileDetails.updatedUser);
            }

            if (this.originalProfileRole !== this.form.controls.profileRole.value.key) {
                const role = await this.threadsService
                    .putGlobalRole(this.originalProfile.id, this.form.controls.profileRole.value.key as Role)
                    .toPromise();

                this.extractFieldsFromRoleData(role);
            }

            if (this.userProfileExtension) {
                await this.userProfileExtension.saveChanges();
            }
        } catch (error) {
            this.handleError(error);
        } finally {
            this.loader.hide();
        }
    }

    private handleError(error: any): Observable<any> {
        if (error.error && error.error.message) {
            this.errorMessage = error.error.message;
        } else {
            this.errorMessage = "Could not update the user profile";
        }

        return throwError(new HandledError(error));
    }

    private async initializeExtension(): Promise<void> {
        if (!this.environment.featureFlags.userProfileExtension) {
            return;
        }
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory<IUserProfileExtension>(
            this.environment.featureFlags.userProfileExtension,
        );

        this.unsubscribeExtensionListeners();

        const viewContainerRef = this.userProfileExtensionDirective.viewContainerRef;
        viewContainerRef.clear();

        const componentRef = viewContainerRef.createComponent<IUserProfileExtension>(componentFactory);
        const { instance } = componentRef;
        this.userProfileExtension = instance;

        if (instance.onError) {
            this.onErrorSubscription = instance.onError.subscribe((errorMessage) => {
                if (!this.errorMessage) {
                    this.errorMessage = errorMessage;
                }
            });
        }
        if (instance.shouldEnableSave) {
            this.shouldEnableSaveSubscription = instance.shouldEnableSave.subscribe((value) => {
                this.userProfileExtensionSaveEnabled = value;
            });
        }
        await instance.initialize(this.userId, this.currentUserRole);
    }

    private unsubscribeExtensionListeners(): void {
        if (this.shouldEnableSaveSubscription) {
            this.shouldEnableSaveSubscription.unsubscribe();
        }
        if (this.onErrorSubscription) {
            this.onErrorSubscription.unsubscribe();
        }
    }

    private isAzureAdUser(userId: string): boolean {
        return UserRoleService.getUserRole(userId) === Role.Staff;
    }
}
