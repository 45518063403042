import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, FormRecord, Validators } from "@angular/forms";
import {
    IThreadCard,
    CardReply,
    ITimeline,
    BillApprovalState,
    BillApprovalUpdateType,
    BillApprovalUpdate,
    Role,
} from "@visoryplatform/threads";
import {
    RequestActionButtonLabel,
    RequestStatuses,
} from "projects/default-plugins/vault/components/request/constants/request.constants";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { DialogRef, DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { combineLatest, merge, Observable, Subscription } from "rxjs";
import {
    switchMap,
    shareReplay,
    take,
    pairwise,
    scan,
    startWith,
    map,
    filter,
    distinctUntilChanged,
} from "rxjs/operators";
import { BillApprovalService } from "../../services/bill-approval.service";
import { Invoice } from "@visoryplatform/copilot";
import {
    BillApprovalModalData,
    BillApprovalFormItem,
    BillApprovalForm,
    BillApprovalDatePickerFormControl,
    BillApprovalFormValues,
    BILL_APPROVAL_DATE_FORMAT,
} from "../../interfaces/BillApproval";
import { BillApprovalFormStateService } from "../../services/bill-approval-form-state.service";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { DateTime } from "luxon";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";

@Component({
    selector: "edit-bill-approval",
    templateUrl: "./edit-bill-approval.component.html",
    styleUrls: ["./edit-bill-approval.component.scss"],
})
export class EditBillApprovalComponent implements OnInit, OnDestroy {
    readonly buttonLabels = RequestActionButtonLabel;
    readonly requestStatuses = RequestStatuses;
    readonly gaEvents = GA_EVENTS;

    replyMessage = new FormControl("");
    dialogRef: DialogRef;
    modalData: BillApprovalModalData;

    card$: Observable<IThreadCard>;
    form$: Observable<FormGroup<BillApprovalForm>>;
    state$: Observable<BillApprovalState>;
    thread$: Observable<ITimeline>;
    userId$: Observable<string>;
    replies$: Observable<CardReply[]>;
    invoices$: Observable<Invoice[]>;
    cardDescription = new FormControl("", Validators.required);
    range = new FormControl<BillApprovalDatePickerFormControl>(
        {
            startDate: "",
            endDate: "",
        },
        Validators.required,
    );
    plannedPaymentDate = new FormControl("", Validators.required);
    loader = new Loader();
    tableLoader = new Loader();
    updatedTableData: Invoice[];
    role: Role;

    private stateSub: Subscription;
    private cardSub: Subscription;
    private refreshSub: Subscription;
    private threadId$: Observable<string>;

    constructor(
        private authService: AuthService,
        private dialogService: DialogService,
        private billApprovalService: BillApprovalService,
        private threadCardService: ThreadCardService,
        private injector: Injector,
        private billApprovalFormStateService: BillApprovalFormStateService,
    ) {}

    async ngOnInit(): Promise<void> {
        await this.initModalData();

        this.thread$ = this.modalData.thread$;
        this.card$ = this.modalData.card$.pipe(shareReplay(1));
        this.state$ = this.modalData.state$.pipe(shareReplay(1));
        this.replies$ = this.modalData.replies$;
        this.userId$ = this.authService.getUserId();
        this.role = this.modalData.role;

        this.threadId$ = this.thread$.pipe(
            map((thread) => thread.id),
            distinctUntilChanged(),
        );

        this.cardSub = this.card$.subscribe((card) => {
            this.cardDescription.setValue(card.description);
        });

        this.stateSub = this.state$.subscribe((state) => {
            this.plannedPaymentDate.setValue(state.plannedDate);
            this.range.setValue({
                startDate: state.fromDate,
                endDate: state.toDate,
            });
        });

        const formGroup = new FormGroup<BillApprovalForm>({
            invoiceItems: new FormRecord<FormGroup<BillApprovalFormItem>>({}),
        });

        this.form$ = this.state$.pipe(
            startWith(null),
            pairwise(),
            scan(
                (_, [oldState, currState]) =>
                    this.billApprovalFormStateService.setRequestItems(formGroup, oldState, currState),
                formGroup,
            ),
        );

        const invoices$ = this.state$.pipe(
            switchMap((state) => this.downloadAttachment(state)),
            take(1),
            shareReplay(1),
        );

        const invoiceUpdates$ = combineLatest([this.rangeUpdates(), this.invoices$]).pipe(
            map(([newInvoices, invoices]) => this.updateTableItems(formGroup, invoices, newInvoices)),
        );

        this.invoices$ = merge(invoices$, invoiceUpdates$);
    }

    ngOnDestroy(): void {
        this.cardSub?.unsubscribe();
        this.stateSub?.unsubscribe();
        this.refreshSub?.unsubscribe();
    }

    save(thread: ITimeline, card: IThreadCard, state: BillApprovalState): void {
        const deletedItems$ = this.form$.pipe(
            take(1),
            switchMap((_form) => {
                const { startDate, endDate } = this.range.value;
                const plannedPaymentDate = this.plannedPaymentDate.value;

                const startIso = this.parseAndFormatDate(startDate);
                const endIso = this.parseAndFormatDate(endDate);
                const paymentDateIso = this.parseAndFormatDate(plannedPaymentDate);

                const payload: BillApprovalUpdate = {
                    type: BillApprovalUpdateType.Edit,
                    vaultId: state.vaultId,
                    updatedInvoiceItems: this.updatedTableData,
                    plannedDate: paymentDateIso,
                    fromDate: startIso,
                    toDate: endIso,
                };
                return this.billApprovalService.updateRequestItems(thread.id, card.id, payload);
            }),
        );

        const updatedDescription = this.cardDescription.value;
        const description$ = this.threadCardService.updateCardDescription(thread.id, card.id, updatedDescription);
        const updates$ = combineLatest([deletedItems$, description$]);

        this.loader
            .wrap(updates$)
            .pipe(take(1))
            .subscribe(() => {
                this.dialogRef.close(false);
            });
    }

    updateTable(tableData: Invoice[]): void {
        this.updatedTableData = tableData;
    }

    refresh(formGroup: FormGroup<BillApprovalForm>, threadId: string): void {
        const { startDate, endDate } = this.range.value;
        const startIso = this.parseAndFormatDate(startDate);
        const endIso = this.parseAndFormatDate(endDate);

        const newInvoices$ = this.tableLoader.wrap(
            this.billApprovalService
                .listInvoices(threadId, startIso, endIso)
                .pipe(map((invoices) => invoices.sort((a, b) => this.compareInvoices(a, b)))),
        );

        this.invoices$ = combineLatest([this.invoices$, newInvoices$]).pipe(
            map(([invoices, newInvoices]) => this.updateTableItems(formGroup, invoices, newInvoices)),
            take(1),
        );
    }

    private updateTableItems(
        formGroup: FormGroup<BillApprovalForm>,
        invoices: Invoice[],
        newInvoices: Invoice[],
    ): Invoice[] {
        const formItems = Object.values(formGroup.value.invoiceItems);
        const respondedFormIds = this.getRespondedInvoices(formItems);
        const respondedTableIds = invoices.filter((invoice) => respondedFormIds.includes(invoice.invoiceID));
        const newInvoiceData = newInvoices.filter((invoice) => !respondedFormIds.includes(invoice.invoiceID));
        return [...respondedTableIds, ...newInvoiceData];
    }

    private rangeUpdates(): Observable<Invoice[]> {
        return combineLatest([this.threadId$, this.range.valueChanges]).pipe(
            filter(([_, formVal]) => !!formVal?.startDate || !!formVal?.endDate),
            switchMap(([threadId, { startDate, endDate }]) => {
                const startIso = this.parseAndFormatDate(startDate);
                const endIso = this.parseAndFormatDate(endDate);

                const invoices$ = this.billApprovalService
                    .listInvoices(threadId, startIso, endIso)
                    .pipe(map((invoices) => invoices.sort((a, b) => this.compareInvoices(a, b))));

                return this.tableLoader.wrap(invoices$);
            }),
        );
    }

    private getRespondedInvoices(formVaues: Partial<BillApprovalFormValues>[]): string[] {
        return formVaues.filter((item) => item.approved || item.declined).map((item) => item.description);
    }

    private parseAndFormatDate(date?: string | DateTime): string {
        if (typeof date === "string") {
            return DateTime.fromISO(date).toFormat(BILL_APPROVAL_DATE_FORMAT);
        } else {
            return date.toFormat(BILL_APPROVAL_DATE_FORMAT);
        }
    }

    private compareInvoices(a: Invoice, b: Invoice): number {
        const contactCompare = this.compareBillContacts(a, b);
        const areContactsSame = contactCompare === 0;

        if (areContactsSame) {
            return this.compareBillDueDates(a, b);
        }

        return contactCompare;
    }

    private compareBillContacts(a: Invoice, b: Invoice): number {
        const aContact = a?.contact?.name;
        const bContact = b?.contact?.name;

        return aContact?.localeCompare(bContact) || 0;
    }

    private compareBillDueDates(a: Invoice, b: Invoice): number {
        const aDueDate = a?.dueDate;
        const bDueDate = b?.dueDate;

        return aDueDate?.localeCompare(bDueDate) || 0;
    }

    private downloadAttachment(state: BillApprovalState): Observable<Invoice[]> {
        const fileId = state.attachments.fileId;
        const vaultId = state.vaultId;
        return this.tableLoader.wrap(this.billApprovalService.downloadVaultPayRunReport(vaultId, fileId));
    }

    private async initModalData(): Promise<void> {
        this.dialogRef = await this.loader.wrap(this.dialogService.getRef(this.injector)).toPromise();
        this.modalData = await this.loader
            .wrap(this.dialogService.getData<BillApprovalModalData>(this.injector))
            .toPromise();
    }
}
