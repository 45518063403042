/* eslint-disable max-lines-per-function */
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { CopilotTransaction, ITimeline } from "@visoryplatform/threads";
import { CopilotService } from "../../services/copilot.service";
import { Subscription } from "rxjs";
import { DateTime } from "luxon";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { StatementLine, Query, QuerySubject } from "@visoryplatform/copilot";
import { debounceTime, take } from "rxjs/operators";
import { TransactionQuery } from "../transaction-list/transaction-list.component";

export type EditQueriesResponse = {
    description: string;
    statementLines: Record<string, StatementLine>;
    queries: Partial<Query>[];
    startDate: string;
    endDate: string;
};
export type EditQueriesData = Partial<EditQueriesResponse>;

const DEFAULT_QUERY = "Provide invoice";

type TransactionQueryFormGroup = {
    query: FormControl<Partial<Query>>;
    statementLine: FormControl<StatementLine>;
};

@Component({
    selector: "edit-transaction-queries",
    templateUrl: "./edit-transactions-queries.component.html",
    styleUrls: ["./edit-transactions-queries.component.scss"],
})
export class EditTransactionQueriesComponent implements OnInit, OnChanges, OnDestroy {
    @Input() thread: ITimeline;
    @Input() cardDescription: string;
    @Input() transactions?: CopilotTransaction[];

    @Output() update = new EventEmitter<EditQueriesResponse>();

    rangeSub: Subscription;
    formSub: Subscription;
    statementLinesSub: Subscription;
    minDate?: string | null;
    maxDate?: string | null;

    statementMap: Record<string, StatementLine>;

    loader = new Loader();

    form = new FormGroup({
        cardDescription: new FormControl(""),
        transactions: new FormArray<FormGroup<TransactionQueryFormGroup>>([]),
        range: new FormGroup({
            startDate: new FormControl<string>(""),
            endDate: new FormControl<string>(""),
        }),
    });

    constructor(private copilotService: CopilotService) {}

    ngOnInit(): void {
        const startDate = DateTime.local().startOf("month").toFormat("yyyy-MM-dd");
        const endDate = DateTime.local().endOf("month").toFormat("yyyy-MM-dd");

        const range = {
            startDate,
            endDate,
        };

        this.form.patchValue({ range });

        this.rangeSub = this.form.controls.range.valueChanges
            .pipe(debounceTime(150))
            .subscribe(({ startDate, endDate }) => {
                const start = this.parseDate(startDate);
                const end = this.parseDate(endDate);

                if (start) {
                    this.minDate = null;
                    this.maxDate = start.plus({ days: 365 }).toISO();
                } else if (end) {
                    this.maxDate = null;
                    this.minDate = end.minus({ days: 365 }).toISO();
                }

                if (!start || !end || start > end) {
                    return;
                }

                const dateFormat = "yyyy-MM-dd";

                const startIso: string = start.toFormat(dateFormat);
                const endIso: string = end.toFormat(dateFormat);

                void this.updateDateRange(this.thread.id, startIso, endIso);
            });

        this.formSub = this.form.valueChanges.subscribe((val) => {
            const queries = val.transactions.map((control) => control.query);
            this.updateResponse(val.cardDescription, this.statementMap, queries);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.thread || changes.transactions) {
            const { startDate, endDate } = this.form.value?.range || {};
            void this.setTransactions(this.thread?.id, startDate, endDate);
        }

        if (changes.cardDescription) {
            this.form.patchValue({ cardDescription: this.cardDescription });
        }
    }

    ngOnDestroy(): void {
        this.statementLinesSub?.unsubscribe();
        this.rangeSub?.unsubscribe();
        this.formSub?.unsubscribe();
    }

    updateResponse(
        description: string,
        statementLines: Record<string, StatementLine>,
        queries: Partial<Query>[],
    ): void {
        const { startDate, endDate } = this.form.value?.range || {};

        const response: EditQueriesResponse = {
            description,
            statementLines,
            queries,
            startDate,
            endDate,
        };

        this.update.next(response);
    }

    trackControl(_index: number, control: FormControl<Partial<Query>>): string {
        return control.value.subjectId;
    }

    removeControl(id: string): void {
        const transactions = this.form.controls.transactions;
        const index = transactions.controls.findIndex((control) => control.value.query.subjectId === id);
        if (index < 0) {
            return;
        }

        transactions.removeAt(index);
    }

    changeQuery(transaction: TransactionQuery): void {
        const transactions = this.form.controls.transactions;
        const control = transactions.controls.find(
            (control) => control.value.query.subjectId === transaction.query.subjectId,
        );
        if (!control) {
            return;
        }

        control.patchValue(transaction);
    }

    private parseDate(date?: string | DateTime): DateTime {
        if (typeof date === "string") {
            return DateTime.fromISO(date);
        } else {
            return date;
        }
    }

    private reduceStatementLine(
        acc: Record<string, StatementLine>,
        statementLine: StatementLine,
    ): Record<string, StatementLine> {
        return {
            ...acc,
            [statementLine.id]: statementLine,
        };
    }

    private async setTransactions(threadId: string, startDate?: string, endDate?: string): Promise<void> {
        const accountControls = await this.getControls(threadId, startDate, endDate);
        this.form.setControl("transactions", new FormArray(accountControls));
    }

    private async updateDateRange(threadId: string, startDate?: string, endDate?: string): Promise<void> {
        const accountControls = await this.getControls(threadId, startDate, endDate, true);
        this.form.setControl("transactions", new FormArray(accountControls));
    }

    private async getControls(
        threadId: string,
        startDate?: string,
        endDate?: string,
        isUpdate?: boolean,
    ): Promise<FormGroup<TransactionQueryFormGroup>[]> {
        if (this.transactions && !isUpdate) {
            return this.mapExistingTransactions(this.transactions);
        } else if (this.transactions && isUpdate) {
            return await this.updateExistingTransactions(threadId, startDate, endDate, this.transactions);
        } else {
            return await this.retrieveAllTransactions(threadId, startDate, endDate);
        }
    }

    private mapExistingTransactions(transactions: CopilotTransaction[]): FormGroup<TransactionQueryFormGroup>[] {
        const accountControls = transactions.map((transaction) => {
            const queryControl = new FormControl<Query>(transaction.query);
            const statementLineControl = new FormControl<StatementLine>(transaction.statementLine);
            return new FormGroup<TransactionQueryFormGroup>({
                query: queryControl,
                statementLine: statementLineControl,
            });
        });

        this.statementMap = transactions.reduce(
            (acc, transaction) => this.reduceStatementLine(acc, transaction.statementLine),
            {} as Record<string, StatementLine>,
        );

        return accountControls;
    }

    private async retrieveAllTransactions(
        threadId: string,
        startDate: string,
        endDate: string,
    ): Promise<FormGroup<TransactionQueryFormGroup>[]> {
        if (!startDate || !endDate) {
            return [];
        }

        const accountStatements$ = this.loader
            .wrap(this.copilotService.getTransactions(threadId, startDate, endDate))
            .pipe(take(1));

        const accountStatements = await accountStatements$.toPromise();
        const statementLines = accountStatements.map((accountStatement) => accountStatement.statementLines).flat();

        this.statementMap = statementLines.reduce(
            (acc, statementLine) => this.reduceStatementLine(acc, statementLine),
            {} as Record<string, StatementLine>,
        );

        const accountControls = accountStatements.map((account) =>
            account.statementLines.map((line) => {
                const queryControl = new FormControl<Partial<Query>>({
                    subjectId: line.id,
                    query: DEFAULT_QUERY,
                });

                return new FormGroup<TransactionQueryFormGroup>({
                    query: queryControl,
                    statementLine: new FormControl<StatementLine>(line),
                });
            }),
        );

        return accountControls.flat(1);
    }

    private async updateExistingTransactions(
        threadId: string,
        startDate: string,
        endDate: string,
        transactions: CopilotTransaction[],
    ): Promise<FormGroup<TransactionQueryFormGroup>[]> {
        if (!startDate || !endDate) {
            return [];
        }

        const accountStatements$ = this.loader
            .wrap(this.copilotService.getTransactions(threadId, startDate, endDate))
            .pipe(take(1));

        const accountStatements = await accountStatements$.toPromise();
        const statementLines = accountStatements.map((accountStatement) => accountStatement.statementLines).flat();

        const accountControls = accountStatements.map((account) =>
            account.statementLines.map((line) => {
                const existingQuery = transactions.find((transaction) => transaction.statementLine?.id === line.id);

                const query: Partial<Query> = {
                    subjectId: line.id,
                    subjectType: QuerySubject.BankStatementLine,
                    query: DEFAULT_QUERY,
                    ...(existingQuery?.query || {}),
                };

                return query;
            }),
        );

        const queries = accountControls.flat(1);
        const queryIds = queries.map((control) => control.subjectId);
        const removedWithAnswers = transactions
            .filter((transaction) => transaction.query?.answer || transaction?.query?.vaultDetails?.filename)
            .filter((transaction) => !queryIds.includes(transaction.statementLine.id));

        const removedStatements = removedWithAnswers.map((transaction) => transaction.statementLine);

        this.statementMap = [...statementLines, ...removedStatements].reduce(
            (acc, statementLine) => this.reduceStatementLine(acc, statementLine),
            {} as Record<string, StatementLine>,
        );
        const removedQueries = removedWithAnswers.map((transaction) => transaction.query);

        return [...queries, ...removedQueries].map((query) => {
            const queryControl = new FormControl<Partial<Query>>(query);
            const statementLineControl = new FormControl<StatementLine>(this.statementMap[query.subjectId]);
            return new FormGroup<TransactionQueryFormGroup>({
                query: queryControl,
                statementLine: statementLineControl,
            });
        });
    }
}
