import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Role, WorkflowDueDateService, WorkflowStepType } from "@visoryplatform/threads";
import { ENVIRONMENT } from "src/app/injection-token";
import {
    IWorkflow,
    IStep,
    IWorkflowAction,
    SystemStepId,
    AssigneeExtensionHelpers,
} from "@visoryplatform/workflow-core";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { IWorkflowStepUI } from "../../../threads-ui/interfaces/IWorkflowStepUI";
import { WorkflowTextConstants } from "../../constants/workflow-text.constants";

@Component({
    selector: "workflow-step-entry",
    templateUrl: "./workflow-step-entry.component.html",
    styleUrls: ["./workflow-step-entry.component.scss"],
})
export class WorkflowStepEntryComponent implements OnChanges {
    @Input() entry: IWorkflowStepUI;
    @Input() workflow: IWorkflow;
    @Input() role: Role;
    @Input() accountLabel: string;
    @Input() index: number;
    @Input() isLastMilestone: boolean;

    @Output() performAction = new EventEmitter<IWorkflowAction>();

    /** TODOS in html */

    readonly stepTypes = WorkflowStepType;
    readonly theme = this.environment.theme;
    readonly SystemStepId = SystemStepId;

    hasDueDates: boolean;
    roles = Role;
    selectedStep: IStep;
    stateChanged = false;
    text = WorkflowTextConstants;
    timeZone: string;
    assignees: string[];

    constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}

    ngOnChanges(changes: SimpleChanges): void {
        const { entry } = changes;

        if (entry) {
            this.hasDueDates = WorkflowDueDateService.isSla(this.entry?.step);
            this.assignees = AssigneeExtensionHelpers.getAssigneeData(this.entry?.step?.extensions)?.assignees || [];
        }
    }

    selectStepAt(action: IWorkflowAction): void {
        this.performAction.emit(action);
    }
}
