import { Component, Inject } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { AnalyticsService } from "projects/portal-modules/src/lib/analytics";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { OnboardingVideoModalComponent } from "../select-customer-success-manager/onboarding-video-modal/onboarding-video-modal.component";

@Component({
    selector: "app-onboarding-context-screen",
    templateUrl: "./onboarding-context-screen.component.html",
    styleUrls: ["./onboarding-context-screen.component.scss"],
})
export class OnboardingContextScreenComponent {
    onboardingVideoUrl: string;

    constructor(
        private dialog: MatDialog,
        private analyticsService: AnalyticsService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {
        this.onboardingVideoUrl = this.environment.onboardingVideoUrl;
    }

    openVideoModal($event: Event) {
        $event.preventDefault();

        this.analyticsService.recordEvent("onboarding-video", "clicked");

        const config = {
            panelClass: "onboarding-video-modal-panel",
            width: "90%",
            maxWidth: "640px",
            disableClose: false,
        };

        this.dialog.open(OnboardingVideoModalComponent, config);
    }
}
