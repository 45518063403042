import { Component, Inject, OnInit } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

export interface ItemListModalModalParams {
    items: string[];
    modalTitle: string;
    closeButtonTitle?: string;
    noItemsDescription?: string;
}

@Component({
    selector: "lib-item-list-modal",
    templateUrl: "./item-list-modal.component.html",
    styleUrls: ["./item-list-modal.component.scss"],
})
export class ItemListModalComponent implements OnInit {
    closeButtonTitle: string;
    modalTitle: string;
    noItemsDescription: string;
    items: string[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ItemListModalModalParams,
        private dialogRef: MatDialogRef<ItemListModalComponent>,
    ) {}

    ngOnInit(): void {
        this.closeButtonTitle = this.data?.closeButtonTitle || "Close";
        this.modalTitle = this.data?.modalTitle || "Item list:";
        this.noItemsDescription = this.data?.noItemsDescription || "No items to display";
    }

    public close(): void {
        this.dialogRef.close(true);
    }
}
