import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

export enum MenuType {
    Profile = "Profile",
    NotificationsMobile = "NotificationsMobile",
    Navigation = "Navigation",
    CollapsedMenuIcon = "CollapsedMenuIcon", // hamburger button for mobile
    ThreadsMenu = "ThreadsMenu",
    WorkflowList = "WorkflowList",
}

@Injectable({
    providedIn: "root",
})
export class MenuService {
    private showNavigationMenu$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private showNotificationMobileMenu$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private showProfileMenu$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private showMobileCollapsedIcon$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private showThreadsMenu$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private showWorkflowList$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    show(menuType: MenuType): void {
        this.toggleMenu(menuType, true);
    }

    hide(menuType: MenuType): void {
        this.toggleMenu(menuType, false);
    }

    toggle(menuType: MenuType): void {
        this.toggleMenu(menuType);
    }

    showNotificationMenuMobile(): Observable<boolean> {
        return this.showNotificationMobileMenu$.asObservable();
    }

    showWorkflowList(): Observable<boolean> {
        return this.showWorkflowList$.asObservable();
    }

    showProfileMenu(): Observable<boolean> {
        return this.showProfileMenu$.asObservable();
    }

    showMobileCollapsedIcon(): Observable<boolean> {
        return this.showMobileCollapsedIcon$.asObservable();
    }

    showThreadsMenu(): Observable<boolean> {
        return this.showThreadsMenu$.asObservable();
    }

    showNavigationMenu(): Observable<boolean> {
        return this.showNavigationMenu$.asObservable();
    }

    private toggleMenu(menuType: MenuType, showMenu?: boolean): void {
        switch (menuType) {
            case MenuType.Profile:
                this.showProfileMenu$.next(this.getNextMenuState(showMenu, this.showProfileMenu$.value));
                break;
            case MenuType.NotificationsMobile:
                this.showNotificationMobileMenu$.next(
                    this.getNextMenuState(showMenu, this.showNotificationMobileMenu$.value),
                );
                break;
            case MenuType.Navigation:
                this.showNavigationMenu$.next(this.getNextMenuState(showMenu, this.showNavigationMenu$.value));
                break;
            case MenuType.CollapsedMenuIcon:
                this.showMobileCollapsedIcon$.next(
                    this.getNextMenuState(showMenu, this.showMobileCollapsedIcon$.value),
                );
                break;
            case MenuType.ThreadsMenu:
                this.showThreadsMenu$.next(this.getNextMenuState(showMenu, this.showThreadsMenu$.value));
                break;
            case MenuType.WorkflowList:
                this.showWorkflowList$.next(this.getNextMenuState(showMenu, this.showWorkflowList$.value));
                break;
            default:
                return;
        }
    }

    private getNextMenuState(value: boolean, currentValue: boolean): boolean {
        return typeof value === "boolean" ? value : !currentValue;
    }
}
