<ng-container
    *ngIf="{
        card: card$ | async,
        state: state$ | async,
        thread: thread$ | async,
        form: form$ | async,
        userId: userId$ | async,
        replies: replies$ | async,
    } as context"
>
    <centered-modal-layout
        [loading]="!!loader.counter"
        [cancelButtonTitle]="context.state?.isCompleted ? buttonLabels.Close : buttonLabels.SaveClose"
        [disableSave]="context.state?.isCompleted && !modalData.allowRecomplete"
        [showSaveButton]="!context.state?.isCompleted"
        [saveButtonTitle]="actionedPercentage === 100 ? buttonLabels.Complete : buttonLabels.Review"
        (save)="save(context.thread, context.card)"
        (close)="dialogRef.close()"
    >
        <div modal-header>
            <request-header
                title="Accounts payable report for review and approval"
                [isComplete]="context.state?.isCompleted"
                [completedStatusLabel]="requestStatuses.Completed"
                [accountName]="context.thread?.account?.label || ''"
            ></request-header>
        </div>

        <div modal-content>
            <div class="request-common-group">
                <request-reply
                    *ngIf="context?.thread && context?.card"
                    [replies]="context.replies"
                    [userId$]="userId$"
                    [thread]="context?.thread"
                    [card]="context?.card"
                    [replyMessage]="replyMessage"
                    [focusReplyInput]="modalData?.focusReplyInput"
                ></request-reply>
            </div>

            <div class="view-bill-approval-controls">
                <planned-payment-date [formControl]="plannedPaymentDate"></planned-payment-date>
            </div>

            <loader-icon
                *ngIf="!!tableLoader.counter"
                color="light"
                [static]="true"
            ></loader-icon>
            <ng-container *ngIf="!tableLoader.counter">
                <ng-container *ngIf="invoices$ | async; let invoices">
                    <div class="bill-approval-summary-title">
                        <h3 class="bill-approval-summary-title-heading">Bills awaiting payment</h3>
                        <div class="bill-approval-summary-title-desc">
                            Review bills for payment and approve those to be paid
                        </div>
                    </div>

                    <ng-container *ngIf="form$ | async as form">
                        <div [formGroup]="form">
                            <bill-approval-list
                                [form]="form"
                                *ngIf="invoices?.length"
                                [invoices]="invoices"
                                [state]="context.state"
                                [allowUpdate]="true"
                                (updateInvoiceItem)="updateValue(context.thread, context.card, context.state, $event)"
                            ></bill-approval-list>
                        </div>
                    </ng-container>

                    <empty-state
                        *ngIf="!invoices.length"
                        title="You're all up to date!"
                        htmlText="There are no bills due for the selected range"
                    >
                    </empty-state>
                </ng-container>
            </ng-container>
        </div>
    </centered-modal-layout>
</ng-container>
