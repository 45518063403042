import { Component, OnDestroy, OnInit, inject } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Account, IntegrationTypes } from "@visoryplatform/threads";
import { AccountRouteService } from "projects/portal-modules/src/lib/account/services/account-route.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";

@Component({
    selector: "account-transformers",
    templateUrl: "./account-transformers.component.html",
    styleUrls: ["./account-transformers.component.scss"],
})
export class AccountTransformersComponent implements OnInit, OnDestroy {
    accountRouteService = inject(AccountRouteService);
    formBuilder = inject(FormBuilder);

    loader = new Loader();

    form = this.formBuilder.group({
        sources: this.formBuilder.array([
            this.formBuilder.group({
                sourceId: ["", Validators.required],
            }),
        ]),
        resultTransforms: this.formBuilder.array([
            this.formBuilder.group({
                label: ["Verify nil balances", Validators.required],
                resultTransformId: ["", Validators.required],
            }),
        ]),
    });

    account$ = this.accountRouteService.getAccount();
    transforms$ = this.account$.pipe(map((account) => account.metadata.transforms));
    xeroIntegration$ = this.account$.pipe(
        map((account) => account.metadata.integrations?.[IntegrationTypes.Xero]?.businessId),
    );
    sourcePrefix$ = this.xeroIntegration$.pipe(map((xero) => `xero.` + xero));

    private transformSub: Subscription;

    ngOnInit(): void {
        this.transformSub = this.transforms$.subscribe((transforms) => {
            this.form.patchValue(transforms);
        });
    }

    ngOnDestroy(): void {
        this.transformSub?.unsubscribe();
    }

    save(account: Account, updatedTransforms: unknown): void {
        console.log("Updating", { account, updatedTransforms });

        const updates$ = this.accountRouteService.updateMetadata(account.id, {
            ...account.metadata,
            transforms: updatedTransforms,
        });

        this.loader.wrap(updates$).subscribe();
    }
}
