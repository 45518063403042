<section class="section">
    <lib-calendar-header></lib-calendar-header>

    <loader-icon
        *ngIf="loader.counter"
        color="light"
    ></loader-icon>

    <div class="calendar">
        <mwl-calendar-month-view
            [viewDate]="viewDate"
            [events]="events$ | async"
            [refresh]="refresh"
            [excludeDays]="excludeDays"
            [cellTemplate]="milestoneStepItem"
            (dayClicked)="dayClicked()"
        >
        </mwl-calendar-month-view>
    </div>
</section>

<ng-template
    #milestoneStepItem
    let-day="day"
    let-locale="locale"
>
    <div class="cal-cell-top">
        <div class="cal-day-number">
            {{ day.date | calendarDate : "monthViewDayNumber" : locale }}
        </div>
    </div>

    <ng-container *ngIf="day?.events?.length">
        <ng-container *ngFor="let event of day.events; index as i">
            <div *ngIf="i < rowCellLimit || shouldShowAllOnWeek.get(day.date | date : 'W')">
                <div *ngIf="event.id.includes('milestone')">
                    <lib-calendar-milestone
                        [title]="event.title"
                        [type]="event.meta.type"
                        [color]="event.color.primary"
                        [backgroundColor]="event.color.secondary"
                        (milestoneClicked)="onCalendarMilestoneClicked(event)"
                        [tuiHint]="
                            (event.meta.type | valueToFriendlyName) + ' - ' + event.meta.account.label | titlecase
                        "
                        tuiHintDirection="top"
                    >
                    </lib-calendar-milestone>
                </div>

                <div *ngIf="event.id.includes('meeting')">
                    <lib-calendar-meeting
                        [title]="event.title"
                        [start]="event.start"
                        [end]="event.end"
                        [color]="event.color.primary"
                        (calendarMeetingClicked)="onViewMeetingDetails(event.meta)"
                    >
                    </lib-calendar-meeting>
                </div>
            </div>
        </ng-container>

        <button
            *ngIf="day.events.length > rowCellLimit"
            mat-icon-button
            (click)="toggleShowAll(day.date)"
        >
            <mat-icon>
                {{ shouldShowAllOnWeek.get(day.date | date : "W") ? "keyboard_arrow_up" : "keyboard_arrow_down" }}
            </mat-icon>
        </button>
    </ng-container>
</ng-template>
