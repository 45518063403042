import { AssigneeType, ConfigTokenExtensionToken } from "@visoryplatform/workflow-core";
import { IWorkflowToken, WorkflowTokenTypes } from "@visoryplatform/threads";

export const customerRequestToken: IWorkflowToken = {
    id: "1a699bf7-a54e-4f99-8c0a-e19202b30da4",
    label: "Customer",
    type: WorkflowTokenTypes.CustomerRequest,
    configTokens: {
        [ConfigTokenExtensionToken.EVENT_ACTOR_ROLE_ASSIGNEE_VARIATION]: {
            assignees: [],
            assigneeType: AssigneeType.Internal,
        },
    },
};

export const customerRequestTokens: IWorkflowToken[] = [customerRequestToken];
