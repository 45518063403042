import { Component, OnDestroy, OnInit } from "@angular/core";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "../../../../portal-modules/src/lib/feature-flags";
import { FormControl, FormGroup } from "@angular/forms";
import { NavigationEnd, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { GA_EVENTS } from "../../../../portal-modules/src/lib/analytics";
import { filter } from "rxjs/operators";
import { GlobalSearchService } from "../../services/global-search.service";

enum KeypressEvent {
    Enter = "Enter",
}

@Component({
    selector: "global-search",
    templateUrl: "./global-search.component.html",
    styleUrls: ["./global-search.component.scss"],
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
    isSearchRouteActive: boolean;
    globalSearchEnabled$: Observable<boolean>;

    form = new FormGroup({
        search: new FormControl<string | null>(""),
    });

    routeSubscription: Subscription;
    queryParamsSubscription: Subscription;
    valueChangesSubscription: Subscription;
    debounceLimit = 0;

    readonly gaEvents = GA_EVENTS;
    private readonly featureFlags = LaunchDarklyFeatureFlags;

    constructor(
        private featureFlagService: FeatureFlagService,
        private router: Router,
        private globalSearchService: GlobalSearchService,
    ) {
        this.globalSearchEnabled$ = this.getGlobalSearchFeatureFlag();
    }

    ngOnInit(): void {
        this.routeSubscription = this.checkIsSearchRouteActive();
        this.queryParamsSubscription = this.globalSearchService.setControlValueFromSearchQueryParams(
            this.form.controls.search,
        );

        this.form.controls.search.valueChanges.pipe(filter(() => this.isSearchRoute())).subscribe((value) => {
            this.routeToSearchPage(value);
        });
    }

    isSearchRoute(): boolean {
        const url = this.router.url;
        const isSearchingOnRoute = url.includes("/search");
        this.debounceLimit = isSearchingOnRoute ? 1500 : 0;
        return isSearchingOnRoute;
    }

    ngOnDestroy(): void {
        this.routeSubscription?.unsubscribe();
        this.valueChangesSubscription?.unsubscribe();
        this.queryParamsSubscription?.unsubscribe();
    }

    onKeyPress(event: KeyboardEvent): void {
        const isSearchRoute = this.isSearchRoute();
        if (event.key === KeypressEvent.Enter && !isSearchRoute) {
            event.preventDefault();
            this.routeToSearchPage(this.form.value.search);
        }
    }

    routeToSearchPage(searchQuery?: string): void {
        const query = searchQuery?.trim();
        const queryParams = { query };
        this.router.navigate(["/search"], { queryParams });
    }

    private checkIsSearchRouteActive(): Subscription {
        return this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.isSearchRouteActive = event.url.includes("search");

                if (!this.isSearchRouteActive) {
                    this.form.patchValue({ search: null });
                }
            });
    }

    private getGlobalSearchFeatureFlag(): Observable<boolean> {
        return this.featureFlagService.getFlag(this.featureFlags.EnableGlobalSearch);
    }
}
