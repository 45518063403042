import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";

import { merge, Observable } from "rxjs";
import { distinctUntilChanged, map, shareReplay, switchMap } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { environmentCommon, EnvironmentSpecificConfig } from "../../environment/environment.common";
import { IUserDataService } from "../interfaces/IUserDataService";
import { WorkflowService } from "./workflow/workflow.service";
import {
    IInviteUsersResponse,
    IParticipant,
    IThread,
    IThreadListing,
    IThreadUnresolvedNotifications,
    ITimeline,
    IUserSetupRequest,
    ParticipantType,
    Role,
    CardReference,
    ThreadStatus,
    WorkflowCurrentStepService,
} from "@visoryplatform/threads";
import { IWorkflowInputs } from "@visoryplatform/workflow-core";
import { AppUser } from "../../findex-auth";
import { IWorkflowToken } from "@visoryplatform/threads";

@Injectable({ providedIn: "root" })
export class ThreadsService implements IUserDataService {
    constructor(
        private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private workflowService: WorkflowService,
    ) {}

    createThread(
        title: string,
        designId: string,
        accountId: string,
        participants: IParticipant[],
        threadType: string,
        workflowInputs?: IWorkflowInputs,
        workflowConfigurationId?: string,
        workflowTokens?: IWorkflowToken[],
        referenceFrom?: CardReference,
        restrictCardsToInternal?: boolean,
    ): Observable<IThread> {
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}`;
        const body = {
            title,
            participants,
            designId,
            accountId,
            threadType,
            workflowConfigurationId,
            workflowInputs,
            workflowTokens,
            referenceFrom,
            restrictCardsToInternal,
        };
        return this.http.post<IThread>(url, body);
    }

    updateThread(
        threadId: string,
        updates: Partial<Pick<IThread, "title">>,
        workflowInputs?: IWorkflowInputs,
        participants?: IParticipant[],
    ): Observable<void> {
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}`;
        return this.http.put<void>(url, { ...updates, workflowInputs, participants });
    }

    getGlobalRole(participantId: string): Observable<Role> {
        const { participants, role: rolePath } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${participants}/${participantId}${rolePath}`;
        return this.http.get<{ role: Role }>(url).pipe(map(({ role }) => role));
    }

    putGlobalRole(participantId: string, roleToPut: Role): Observable<Role> {
        const { participants, role: rolePath } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${participants}/${participantId}${rolePath}`;
        return this.http.put<{ role: Role }>(url, { role: roleToPut }).pipe(map(({ role }) => role));
    }

    addParticipant(threadId: string, participantId: string, role: Role, type: ParticipantType) {
        const { threads, participants } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${participants}/${participantId}`;
        return this.http.post(url, { role, type });
    }

    removeParticipant(threadId: string, participantId: string) {
        const { threads, participants } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${participants}/${participantId}`;
        return this.http.delete(url);
    }

    getThread(threadId: string): Observable<IThread> {
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}`;
        return this.http.get<IThread>(url).pipe(
            map((thread: IThread) => {
                const mappedParticipants = thread.participants.map((participant: IParticipant) =>
                    this.mapParticipant(participant),
                );
                return {
                    ...thread,
                    participants: mappedParticipants,
                };
            }),
        );
    }

    getUnresolvedNotifications(threadId: string): Observable<IThreadUnresolvedNotifications> {
        const { notifications } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${notifications}/threads/${threadId}/unresolved`;
        return this.http.get<IThreadUnresolvedNotifications>(url);
    }

    getUserData(participantId: string): Observable<any> {
        const { participants, data } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${participants}/${participantId}${data}`;
        return this.http.get<any>(url);
    }

    putUserData(participantId: string, body: any): Observable<void> {
        const { participants, data } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${participants}/${participantId}${data}`;
        return this.http.put<void>(url, body);
    }

    removeTask(taskId: string): Observable<void> {
        const { tasks } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${tasks}/${taskId}`;
        return this.http.delete<void>(url);
    }

    getParticipants(participantIds: string[]): Observable<IParticipant[]> {
        const { participants } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${participants}`;
        return this.http.post<IParticipant[]>(url, { participantIds });
    }

    sendClientInvites(userSetupRequest: IUserSetupRequest) {
        const { inviteParticipants } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${inviteParticipants}`.replace(":threadId", userSetupRequest.threadId);
        return this.http.post<IInviteUsersResponse[]>(url, userSetupRequest);
    }

    updateUserTimeZone(userId: string): Observable<void> {
        return this.putUserData(userId, {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
    }

    mapParticipant(participant: IParticipant) {
        const staffTitleOverride = this.environment.featureFlags.temporaryFeatureFlags.staffTitleOverride;
        if (staffTitleOverride) {
            if (participant.profile && participant.profile.title) {
                return {
                    ...participant,
                    profile: {
                        ...participant.profile,
                        title: staffTitleOverride,
                    },
                };
            }
        }
        return participant;
    }

    isThreadActive(thread: IThread | IThreadListing | ITimeline): boolean {
        if (!thread) {
            return true;
        }

        const currentStep = this.workflowService.getCurrentStep(thread.workflow);
        return WorkflowCurrentStepService.isStepOpen(currentStep);
    }

    getThreadTypes(): Observable<Record<string, string>> {
        const { timelineGroups } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.commonEndpoints;
        const url = `${base}${timelineGroups}`;
        return this.http.get<Record<string, string>>(url);
    }

    getStatusFilterFromUser(
        currentUser$: Observable<AppUser>,
        filterChanges$: Observable<string>,
    ): Observable<ThreadStatus> {
        const environmentStatus = this.environment.featureFlags.threadListFilterStatus.active?.toLowerCase();
        const preferredFilter$ = currentUser$.pipe(
            switchMap((user) => this.getUserData(user.id)),
            map((userData) => userData?.statusFilter || environmentStatus),
        );

        return merge(preferredFilter$, filterChanges$).pipe(distinctUntilChanged(), shareReplay(1));
    }
}
