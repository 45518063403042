import { IWorkflowToken, RoleAssigneeVariations } from "@visoryplatform/threads";

import { AssigneeExtensionHelpers } from "@visoryplatform/workflow-core";
import { RolesAssigneeFormValue } from "../../account/components/workflow-configuration-roles/workflow-configuration-roles.component";

export class AssigneeVariationsService {
    getAssigneeTokensFromWorkflowTokens(workflowTokens: IWorkflowToken[]): RolesAssigneeFormValue | null {
        if (!workflowTokens?.length) {
            return null;
        }

        const assigneeVariations = Object.keys(RoleAssigneeVariations);
        const assigneeTokens = workflowTokens.reduce<RolesAssigneeFormValue>(
            (acc, token) => this.getRolesAssigneeFormValues(assigneeVariations, token, acc),
            {},
        );

        return Object.keys(assigneeTokens).length ? assigneeTokens : null;
    }

    mapRoleAssigneeToConfigTokens(
        workflowTokens: IWorkflowToken[],
        selectedRoleAssignees?: RolesAssigneeFormValue | null,
    ): IWorkflowToken[] {
        if (!workflowTokens || !selectedRoleAssignees) {
            return [];
        }

        return workflowTokens.reduce<IWorkflowToken[]>(
            (roleAssigneeFormValue, workflowToken) =>
                this.updateConfigTokensWithRoleAssigneeData(
                    workflowToken,
                    selectedRoleAssignees,
                    roleAssigneeFormValue,
                ),
            [],
        );
    }

    private getRolesAssigneeFormValues(
        assigneeVariations: string[],
        token: IWorkflowToken,
        roleAssigneeFormValue: RolesAssigneeFormValue,
    ): RolesAssigneeFormValue {
        const [configTokenKey] = Object.keys(token.configTokens);
        const configTokenData = token.configTokens[configTokenKey];

        const hasRoleAssigneeVariation = assigneeVariations.some((variation) => variation in token.configTokens);
        const isValidAssigneeInputData = AssigneeExtensionHelpers.isValidAssigneeInputData(configTokenData);

        if (hasRoleAssigneeVariation && isValidAssigneeInputData) {
            roleAssigneeFormValue = {
                ...roleAssigneeFormValue,
                [configTokenKey]: configTokenData.assignees,
            };
        }
        return roleAssigneeFormValue;
    }

    private updateConfigTokensWithRoleAssigneeData(
        workflowToken: IWorkflowToken,
        selectedRoleAssignees: RolesAssigneeFormValue,
        roleAssigneeFormValue: IWorkflowToken[],
    ): IWorkflowToken[] {
        const [configTokenKey] = Object.keys(workflowToken.configTokens);
        const configTokenData = workflowToken.configTokens[configTokenKey];
        const assignees = selectedRoleAssignees[configTokenKey];

        if (configTokenKey && assignees) {
            const configTokens = { [configTokenKey]: { ...configTokenData, assignees } };
            roleAssigneeFormValue.push({
                ...workflowToken,
                configTokens,
            });
        }

        return roleAssigneeFormValue;
    }
}
