import { Component, OnInit, OnDestroy, ViewChild, Injector } from "@angular/core";
import { VaultRequestService } from "../../../services/vault-request.service";
import { RequestActionButtonLabel, RequestStatuses, VaultCardType } from "../constants/request.constants";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { IRequestAnalyticsTags } from "../interfaces/IRequestAnalyticsTags";
import { AnalyticsService, GA_EVENTS_PREFIX } from "projects/portal-modules/src/lib/analytics";
import { IRequestReviewModalTitles } from "../interfaces/IRequestReviewModalTitles";
import { IRequestModalData } from "../interfaces/IRequestModalData";
import { RfiTodosComponent } from "../rfi-todos/rfi-todos.component";
import {
    CardReply,
    CardStatus,
    ITimeline,
    IRequestItem,
    IThreadCard,
    IUpdatedRequestItems,
    IVaultRequestCardState,
    Role,
} from "@visoryplatform/threads";
import { Observable, Subscription, combineLatest, of } from "rxjs";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { shareReplay, takeUntil, switchMap, take, map, tap, filter } from "rxjs/operators";
import { IRequestForm, IRequestItemFormGroup } from "../interfaces/IRequestForms";
import { SubscriberBaseComponent } from "projects/portal-modules/src/lib/shared/components/subscriber-base.component";
import { RfiService } from "../../../services/rfi.service";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";

@Component({
    selector: "action-rfi-request",
    templateUrl: "./action-rfi-request.component.html",
    styleUrls: ["./action-rfi-request.component.scss"],
})
export class ActionRfiRequestComponent extends SubscriberBaseComponent implements OnInit, OnDestroy {
    @ViewChild("rfiTodosComponent") rfiTodos: RfiTodosComponent;

    readonly buttonLabel = RequestActionButtonLabel;
    readonly CARD_STATUSES = CardStatus;
    readonly requiredReviewPermissions = ["RequestApprove", "ThreadUpdateAll"];
    readonly requiredReopenPermissions = ["RequestReopen", "ThreadUpdateAll"];
    readonly requestStatuses = RequestStatuses;
    readonly todosTitle = "Action each to-do item and tick them off to complete this request.";

    actionedPercentage = 0;
    actionedRequestItems = 0;
    analyticsTags: IRequestAnalyticsTags;
    card$: Observable<IThreadCard>;
    canReopenRequest$: Observable<boolean>;
    canUpdateTodoListItem$: Observable<boolean>;
    currentUserRole: Role;
    form: FormGroup<IRequestForm>;
    readonly: boolean;
    replies$: Observable<CardReply[]>;
    requestReviewModalTitles: IRequestReviewModalTitles;
    state$: Observable<IVaultRequestCardState>;
    thread$: Observable<ITimeline>;
    updateRFIRequestItemsSub: Subscription;
    userId$: Observable<string>;
    modalData: IRequestModalData;

    constructor(
        private dialog: MatDialog,
        private vaultRequestService: VaultRequestService,
        private permissionService: PermissionService,
        private rfiService: RfiService,
        private authService: AuthService,
        private analytics: AnalyticsService,
        private injector: Injector,
        private dialogService: DialogService,
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.modalData = await this.dialogService.getData<IRequestModalData>(this.injector).toPromise();

        this.initForm();
        this.setLocalVariables(this.modalData);
        this.updateState(this.state$);
    }

    ngOnDestroy(): void {
        this.updateRFIRequestItemsSub?.unsubscribe();
    }

    savePendingChanges(): void {
        this.rfiTodos.todoItemActionComponents.forEach((component) => {
            if (component.requestResponseComponent?.showCompose) {
                component.submitTextResponse(component.requestResponseComponent.editResponse.value);
            }
        });
    }

    updateRFI(control: FormControl<IRequestItem>, analyticsPrefix: GA_EVENTS_PREFIX): void {
        this.markRFIItemComplete(control.value.response.complete.state, control, analyticsPrefix);
    }

    markRFIItemComplete(
        isTicked: boolean,
        requestItemControl: FormControl<IRequestItem>,
        analyticsPrefix: GA_EVENTS_PREFIX,
    ): void {
        this.analytics.recordEvent("mouse-click", `${analyticsPrefix}_markitemcomplete`);

        if (!requestItemControl || !requestItemControl.value?.fileId) {
            return;
        }

        const fileId = requestItemControl.value?.fileId;
        const stateThreadCard$ = combineLatest([this.state$, this.thread$, this.card$]).pipe(take(1));
        const updateRFIRequestItem$ = stateThreadCard$.pipe(
            switchMap(([state, thread, card]) => this.updateRFIRequestItem(state, thread.id, card, isTicked, fileId)),
        );

        this.updateRFIRequestItemsSub?.unsubscribe();
        this.updateRFIRequestItemsSub = updateRFIRequestItem$.subscribe(() => {
            requestItemControl.markAsPristine();
        });
    }

    async markAllComplete(
        checkBoxState: boolean,
        card: IThreadCard,
        state: IVaultRequestCardState,
        threadId: string,
    ): Promise<void> {
        this.rfiService.markAsComplete(checkBoxState, this.form);
        const updatedRequestItems: IUpdatedRequestItems[] = state.requestItems
            .filter((requestItem) => requestItem.response.complete.state !== checkBoxState)
            .map((requestItem) => ({
                fileId: requestItem.fileId,
                complete: checkBoxState,
            }));
        this.actionedRequestItems = checkBoxState ? state.requestItems.length : 0;
        this.actionedPercentage = checkBoxState ? 100 : 0;
        await this.vaultRequestService.updateRequest(updatedRequestItems, card.id, threadId, state.vaultId);
    }

    private updateState(state$: Observable<IVaultRequestCardState>): void {
        state$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state) => {
            if (state) {
                const requestItems = this.rfiService.getRequestItemsFormArray(state.requestItems);
                this.form.setControl("requestItems", requestItems);
                this.form.patchValue({
                    title: state.title,
                });
                this.readonly = state.isCompleted;
                this.updateRequestProgress(state);
            }
        });
    }

    private updateRFIRequestItem(
        state: IVaultRequestCardState,
        threadId: string,
        card: IThreadCard,
        isTicked: boolean,
        fileId: string,
    ): Observable<void> {
        const cardId: string = card.id;
        const requestUpdate = { fileId, complete: isTicked };
        const stateVaultId: string = state.vaultId;
        const stateRequestItemsLength: number = state.requestItems.length;

        return this.shouldReopenRFIRequestItem(state, !isTicked).pipe(
            switchMap((shouldReopen) => (shouldReopen ? this.reopenRFIRequest(card, threadId, fileId) : of(null))),
            map(() => this.updateActionedRequestItems(isTicked, stateRequestItemsLength)),
            switchMap(() => this.vaultRequestService.updateRequest([requestUpdate], cardId, threadId, stateVaultId)),
        );
    }

    private reopenRFIRequest(card: IThreadCard, threadId: string, fileId: string): Observable<boolean> {
        const reopen$ = this.rfiService.reopenRequestModal(
            card,
            threadId,
            this.analyticsTags.analyticsPrefix,
            this.dialog,
        );
        return reopen$.pipe(
            tap((value) => {
                if (!value) {
                    this.patchRequestItemControlCompletedValue(fileId, true);
                }
            }),
            filter((value) => !!value),
        );
    }

    private patchRequestItemControlCompletedValue(fileId: string, completed: boolean): void {
        for (const requestItemControl of this.form.controls.requestItems.controls) {
            if (requestItemControl.controls.requestItem.value.fileId === fileId) {
                requestItemControl.patchValue({ completed });
            }
        }
    }

    private updateRequestProgress(state: IVaultRequestCardState): void {
        const progress = VaultRequestService.calculateProgress(state.requestItems);
        this.actionedRequestItems = progress.actionedRequestItems;
        this.actionedPercentage = progress.actionedPercentage;
    }

    private updateActionedRequestItems(isTicked: boolean, stateRequestItemsLength: number): void {
        this.actionedRequestItems = this.actionedRequestItems + (isTicked ? 1 : -1);
        this.actionedPercentage = Math.floor((this.actionedRequestItems / stateRequestItemsLength) * 100);
    }

    private shouldReopenRFIRequestItem(state: IVaultRequestCardState, notTicked: boolean): Observable<boolean> {
        const isRequestComplete = VaultRequestService.isRequestComplete(state.requestItems);
        const isStateComplete = state.isCompleted;
        const canReopenRFIItem = !isRequestComplete && isStateComplete && notTicked;
        return this.canReopenRequest$.pipe(map((canReopen) => canReopen && canReopenRFIItem));
    }

    private initForm(): void {
        this.form = new FormGroup<IRequestForm>({
            title: new FormControl(""),
            cardDescription: new FormControl(""),
            replyMessage: new FormControl(""),
            requestItems: new FormArray<FormGroup<IRequestItemFormGroup>>([]),
        });
    }

    private setLocalVariables(modalData: IRequestModalData): void {
        this.currentUserRole = modalData.role;
        this.readonly = modalData.readonly;
        this.thread$ = modalData.thread$;
        this.card$ = modalData.card$;
        this.state$ = modalData.state$;
        this.replies$ = modalData.replies$;
        this.userId$ = this.authService.getUserId();
        this.canReopenRequest$ = this.permissionService
            .hasSomePermission(this.currentUserRole, this.requiredReopenPermissions)
            .pipe(shareReplay(1));
        this.canUpdateTodoListItem$ = this.rfiService.getCanUpdateItem(
            this.state$,
            this.thread$,
            this.card$,
            this.canReopenRequest$,
            this.readonly,
        );
        this.analyticsTags = this.vaultRequestService.getAnalyticsTags(VaultCardType.VaultRequest);
        this.requestReviewModalTitles = this.vaultRequestService.getRequestReviewModalTitles(
            VaultCardType.VaultRequest,
        );
    }
}
