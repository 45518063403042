import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { GA_EVENTS } from "../../../../portal-modules/src/lib/analytics";
import { ActivatedRoute, Router } from "@angular/router";
import { GlobalSearchService } from "../../services/global-search.service";
import { combineLatest, Observable, of, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map, shareReplay, switchMap } from "rxjs/operators";
import { Loader } from "../../../../portal-modules/src/lib/shared/services/loader";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { ENVIRONMENT } from "src/app/injection-token";
import { EmptyStateImageType } from "projects/portal-modules/src/lib/empty-state/components/empty-state.component";
import { IGlobalSearchCard, SearchSqlRecord } from "@visoryplatform/threads";

@Component({
    selector: "global-search-page",
    templateUrl: "./global-search-page.component.html",
    styleUrls: ["./global-search-page.component.scss"],
})
export class GlobalSearchPageComponent implements OnInit, OnDestroy {
    readonly gaEvents = GA_EVENTS;
    readonly emptyState = EmptyStateImageType;

    form = new FormGroup({
        search: new FormControl<string | null>(null),
    });
    loader = new Loader();
    results$: Observable<SearchSqlRecord<IGlobalSearchCard>>;
    queryParams$: Observable<string>;
    debounceLimit = 500;
    valueChangesSub: Subscription;
    aiAssistantFeatureEnabled$: Observable<boolean>;
    queryParamsSubscription: Subscription;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private globalSearchService: GlobalSearchService,
        private featureFlagService: FeatureFlagService,
        @Inject(ENVIRONMENT) public environment: EnvironmentSpecificConfig,
    ) {}

    ngOnInit(): void {
        this.aiAssistantFeatureEnabled$ = this.checkAiAssistantFeatureEnabled();

        this.queryParamsSubscription = this.globalSearchService.setControlValueFromSearchQueryParams(
            this.form.controls.search,
        );

        this.queryParams$ = this.mapQueryParamsToSearch();

        this.results$ = this.queryParams$.pipe(
            switchMap((searchTerm) => {
                if (!searchTerm) {
                    return of({
                        result: [],
                    });
                }
                return this.loader.wrap(this.globalSearchService.getSearch(searchTerm));
            }),
        );

        const valueChanges$ = this.form.controls.search.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            shareReplay(1),
        );

        this.valueChangesSub = valueChanges$.subscribe(() => this.routeToSearchPage(this.form.value.search));
    }

    ngOnDestroy(): void {
        this.valueChangesSub?.unsubscribe();
        this.queryParamsSubscription?.unsubscribe();
    }

    routeToSearchPage(query?: string): void {
        void this.router.navigate([], { queryParams: { query } });
    }

    routeToAssistantPage(): void {
        const query = this.form.value.search;
        void this.router.navigate(["/assistant/chats"], { queryParams: { query } });
    }

    private checkAiAssistantFeatureEnabled(): Observable<boolean> {
        const aiAssistantEnabled$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableAIAssistant);
        const aiBrandingEnabled$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableDelphiBranding);

        return combineLatest([aiAssistantEnabled$, aiBrandingEnabled$]).pipe(
            map(([aiAssistant, branding]) => aiAssistant && branding),
        );
    }

    private mapQueryParamsToSearch(): Observable<string> {
        const queryParams$ = this.activatedRoute.queryParams;
        return queryParams$.pipe(map((params) => params.query));
    }
}
