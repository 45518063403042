<div class="stepper-footer">
    <div class="stepper-footer-button">
        <button
            class="stepper-footer-buttons-back"
            [disabled]="disabled || selectedIndex === 0"
            cdkStepperPrevious
        >
            <i class="las la-angle-left stepper-icon"></i>
            {{ backStepLabel }}
        </button>
    </div>

    <progress-bar
        class="stepper-progress-bar"
        [progress]="((selectedIndex + 1) / totalSteps) * 100"
    ></progress-bar>

    <!-- [disabled]="button.isDisabled"
    [analyticsClick]="
        analyticsPrefix + '_' + stepConfig.name?.toLowerCase() + '_' + button.analyticsTitle?.toLowerCase()
    " -->

    <div class="stepper-footer-button">
        <button
            class="fx-btn fx-btn--primary stepper-buttons-next"
            [disabled]="disabled || (stepper.linear && !stepper.selected?.completed)"
            (click)="next(selectedIndex, totalSteps)"
            cdkStepperNext
        >
            {{ selectedIndex + 1 === totalSteps && endStepLabel ? endStepLabel : nextStepLabel }}
            <i class="las la-angle-right stepper-icon"></i>
        </button>
    </div>
</div>
