<div
    class="quill-editor-wrapper"
    [ngClass]="{ 'quill-editor-wrapper--default': !inline }"
>
    <div class="quill-editor-wrapper-input">
        <quill-editor
            #editor
            [styles]="quillStyles"
            [ngClass]="toolbar ? 'editor-toolbar--show-toolbar' : 'editor-toolbar--hide-toolbar'"
            [placeholder]="placeholder || 'Type a message...'"
            aria-label="message"
            (onContentChanged)="onChange()"
            (onEditorCreated)="autoFocus($event)"
            (onBlur)="toggleToolbar()"
            (onFocus)="toggleToolbar()"
            [formControl]="message"
            [modules]="modules"
            [readOnly]="readOnly"
        >
        </quill-editor>
    </div>
    <div
        class="quill-editor-wrapper-error"
        *ngIf="characterError"
    >
        <p>Your message is too large. Please reduce the size of your message including images.</p>
    </div>
</div>
