<div
    [ngClass]="{ 'list--read-only': !role || role === roles.Client }"
    class="list"
>
    <loader-icon
        *ngIf="!!loader.counter"
        color="light"
    ></loader-icon>

    <div class="participants">
        <timeline-participants
            [thread]="thread"
            [role]="role"
        ></timeline-participants>
    </div>

    <ng-container *ngIf="thread?.workflow">
        <ng-container
            *ngFor="
                let entry of stepEntries | visibleEntries : workflow?.currentStepId;
                let index = index;
                let count = count;
                trackBy: trackEntry
            "
        >
            <workflow-step-entry
                [ngClass]="{
                    'non-milestone': !entry.isMilestone,
                    'active-step': entry.step.id === workflow.currentStepId
                }"
                [entry]="entry"
                [workflow]="workflow"
                [role]="role"
                [accountLabel]="accountLabel"
                [index]="index"
                [isLastMilestone]="index === count - 1"
                (performAction)="selectStepAt($event)"
            >
            </workflow-step-entry>
        </ng-container>

        <div
            *ngIf="role | permissions : 'UpdateWorkflowTimeEstimates' | async"
            [ngSwitch]="isWorkflowValid$ | async"
        >
            <div class="buttons-container">
                <button
                    *ngSwitchCase="true"
                    (click)="editDatesClicked()"
                    role="button"
                    class="fx-btn fx-btn--primary-outlined"
                    [disabled]="!!loader.counter"
                >
                    {{ text.Edit }}
                </button>
            </div>
            <warning-banner
                class="workflow-alert"
                *ngSwitchDefault
                >The current workflow design is outdated and does not allow for date modifications. Please contact
                support support for assistance.</warning-banner
            >
        </div>
    </ng-container>
</div>
