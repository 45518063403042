<section
    *ngIf="thread?.participants"
    class="timeline-participants"
>
    <label
        id="participantsLabel"
        class="timeline-participants-label"
    >
        <small>Participants</small>
    </label>
    <div class="timeline-participants-avatars">
        <div
            *ngFor="let avatar of avatars$ | async; trackBy: 'id' | trackProperty"
            class="timeline-participants-avatars-avatar"
            aria-labelledby="participantsLabel"
        >
            <a
                *ngIf="role | permissions : 'ReadRoleAll' | async; else avatarTemplate"
                href="/admin/clients/{{ avatar.id }}/details"
                target="_blank"
            >
                <ng-container *ngTemplateOutlet="avatarTemplate"></ng-container>
            </a>

            <ng-template #avatarTemplate>
                <v-avatar
                    [name]="avatar.name"
                    [src]="avatar.src"
                    size="s"
                ></v-avatar>
            </ng-template>
        </div>

        <div class="thread-participants">
            <thread-participants
                [thread]="thread"
                [role]="role"
                [showBadge]="false"
                originX="start"
                [addPartipantDialogClasses]="['timeline-participants']"
            ></thread-participants>
        </div>
    </div>
</section>
