import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { PaymentCardComponent } from "./components/payment-card/payment-card.component";
import { PaymentDetailsComponent } from "./components/payment-details/payment-details.component";
import { PaymentModalComponent } from "./components/payment-modal/payment-modal.component";
import { CreatePaymentModalComponent } from "./components/create-payment-modal/create-payment-modal.component";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { NgxStripeModule } from "ngx-stripe";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AccountSubscriptionComponent } from "./components/account-subscriptions/account-subscriptions.component";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui";
import { SharedModule } from "projects/portal-modules/src/lib/shared";
import { ThreadsUiModule } from "projects/portal-modules/src/lib/threads-ui";
import { PLUGIN_FACTORY } from "projects/portal-modules/src/lib/plugins";
import { PaymentPlugin } from "./payment.plugin";
import { EditBillingContactDetailsComponent } from "./components/edit-billing-contact-details/edit-billing-contact-details.component";
import { EditBillingDetailsComponent } from "./components/edit-billing-details/edit-billing-details.component";
import { PaymentSubscriptionsComponent } from "./components/payments-subscriptions/payments-subscriptions.component";
import { AccountBillingHistoryComponent } from "./components/account-billing-history/account-billing-history.component";
import { FxTableModule } from "projects/portal-modules/src/lib/fx-table/fx-table.module";

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FxUiModule,
        ThreadsUiModule,
        MatDialogModule,
        FindexUiModule,
        RouterModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        FxTableModule,
        NgxStripeModule.forRoot(),
    ],
    exports: [],
    declarations: [
        PaymentCardComponent,
        PaymentDetailsComponent,
        PaymentModalComponent,
        CreatePaymentModalComponent,
        EditBillingContactDetailsComponent,
        EditBillingDetailsComponent,
        PaymentSubscriptionsComponent,
        AccountSubscriptionComponent,
        AccountBillingHistoryComponent,
    ],
    providers: [{ provide: PLUGIN_FACTORY, useClass: PaymentPlugin, multi: true }],
})
export class PaymentModule {}
