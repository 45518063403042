import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges } from "@angular/core";
import { VAULT_ACTION } from "@visoryplatform/vault";
import { DocumentCategory } from "projects/default-plugins/vault/enums/DocumentCategory";
import { IVaultGroup } from "projects/default-plugins/vault/interfaces/IVaultGroup";
import { IVaultItem } from "projects/default-plugins/vault/interfaces/IVaultItem";
import { VaultCountService } from "../../services/vault-count.service";

@Component({
    selector: "vault-attachments",
    templateUrl: "./vault-attachments.component.html",
    styleUrls: ["./vault-attachments.component.scss"],
})
export class VaultAttachmentsComponent implements OnChanges {
    @Input() groups: IVaultGroup[];
    @Input() disabled: boolean;
    @Input() canSign: boolean;
    @Input() canCreateReports: boolean;
    @Input() editable: boolean | null;

    @Output() download = new EventEmitter<IVaultItem>();
    @Output() preview = new EventEmitter<IVaultItem>();
    @Output() sign = new EventEmitter<IVaultItem>();
    @Output() markAsSign = new EventEmitter<IVaultItem>();
    @Output() markAsReport = new EventEmitter<IVaultItem>();
    @Output() rename = new EventEmitter<{ item: IVaultItem; name: string }>();
    @Output() delete = new EventEmitter<IVaultItem>();

    readonly DocumentCategory = DocumentCategory;
    readonly vaultActions = VAULT_ACTION;

    downloadCount: number;
    isSignActionInGroup: boolean;
    isReportInGroup: boolean;

    ngOnChanges(changes: SimpleChanges): void {
        const { groups } = changes;

        if (groups) {
            this.downloadCount = VaultCountService.countActions(groups.currentValue, [
                VAULT_ACTION.Download,
                VAULT_ACTION.Sign,
            ]);

            this.isSignActionInGroup = this.isItemWithActionInGroups(groups, this.vaultActions.Sign);
            this.isReportInGroup = this.isItemWithActionInGroups(groups, this.vaultActions.Preview);
        }
    }

    renameItem(item: IVaultItem, name: string): void {
        this.rename.emit({ item, name });
    }

    markDocumentAsSign(item: IVaultItem): void {
        this.markAsSign.emit(item);
    }

    markDocumentAsReport(item: IVaultItem): void {
        this.markAsReport.emit(item);
    }

    trackGroup(_index: number, group: IVaultGroup): string {
        return group.displayName;
    }

    trackItem(_index: number, item: IVaultItem): string {
        return item.fileId;
    }

    private isItemWithActionInGroups(groups: SimpleChange, action: VAULT_ACTION): boolean {
        return groups.currentValue.some((group: IVaultGroup) =>
            group.items.some((item: IVaultItem) => item?.actions.includes(action)),
        );
    }
}
