<div class="stepper-breadcrumb">
    <div
        *ngFor="let step of steps; let index = index"
        class="stepper-breadcrumb-step-container"
    >
        <div
            cdkStepHeader
            class="stepper-breadcrumb-step"
            [class.stepper-breadcrumb-step-active]="selectedIndex == index"
        >
            {{ step.label }}
        </div>
        <i
            *ngIf="index < steps.length - 1"
            class="las la-angle-right stepper-icon"
        ></i>
    </div>
</div>
