<sidebar-modal-layout
    header="New request"
    (close)="close()"
>
    <div class="customer-request-inner-container">
        <div class="customer-request-body">
            <div class="customer-request-head">
                <div class="customer-request-head-title">Success!</div>
                <div class="customer-request-head-title">Your request has been submitted to your Visory team.</div>
            </div>
            <div>
                You can also view the progress of this request at any time on your
                <a (click)="goToWorkflowsPage()">workflows page.</a>
            </div>
            <div class="customer-request-footer">
                <div class="customer-request-footer-buttons customer-request-footer-buttons--mobile">
                    <button
                        [analyticsClick]="gaEvents.CUSTOMER_REQUEST_SUCCESS_VIEW"
                        (click)="view()"
                        class="fx-btn fx-btn--white"
                    >
                        View request
                    </button>
                    <button
                        [analyticsClick]="gaEvents.CUSTOMER_REQUEST_SUCCESS_CREATE"
                        class="fx-btn fx-btn--white"
                        role="button"
                        (click)="create()"
                    >
                        Make another request
                    </button>
                </div>
            </div>
        </div>
    </div>
</sidebar-modal-layout>
