import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormGroup, FormRecord } from "@angular/forms";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { Invoice } from "@visoryplatform/copilot";
import { BillApprovalState, BillApprovalUpdatedItem } from "@visoryplatform/threads";
import { BillApprovalFormItem, BillApprovalForm } from "../../interfaces/BillApproval";

@Component({
    selector: "bill-approval-list",
    templateUrl: "./bill-approval-list.component.html",
    styleUrls: ["./bill-approval-list.component.scss"],
})
export class BillApprovalListComponent implements OnChanges {
    @Input() invoices: Invoice[];
    @Input() state: BillApprovalState;
    @Input() allowUpdate = false;
    @Input() allowEdit = false;
    @Input() form = new FormGroup<BillApprovalForm>({
        invoiceItems: new FormRecord<FormGroup<BillApprovalFormItem>>({}),
    });

    @Output() updateTable = new EventEmitter<Invoice[]>();
    @Output() updateInvoiceItem = new EventEmitter<BillApprovalUpdatedItem>();

    tableData = new MatTableDataSource<Invoice>();

    ngOnChanges(changes: SimpleChanges): void {
        const { invoices } = changes;

        if (invoices && this.invoices) {
            this.updateTableData(this.invoices);
        }
    }

    removeInvoice(invoices: Invoice[], invoiceToRemove: Invoice): void {
        if (this.form?.controls.invoiceItems.controls[invoiceToRemove.invoiceID]?.value) {
            const controls = this.getFormControl(invoiceToRemove);
            const values = controls?.value;

            if (values.approved || values.declined) {
                return;
            }
        }

        const updatedInvoices = invoices.filter((invoice) => invoice !== invoiceToRemove);
        this.updateTableData(updatedInvoices);
    }

    updateValue(requestTableGroup: FormGroup<BillApprovalFormItem>, $event: boolean, approve: boolean): void {
        const { fileId } = requestTableGroup.value;

        if (approve) {
            requestTableGroup.patchValue({ approved: $event, declined: false });
            this.updateInvoiceItem.emit({
                approved: $event,
                declined: false,
                fileId,
            });
        } else {
            requestTableGroup.patchValue({ approved: false, declined: $event });
            this.updateInvoiceItem.emit({
                approved: false,
                declined: $event,
                fileId,
            });
        }
    }

    private getFormControl(invoiceToRemove: Invoice): FormGroup<BillApprovalFormItem> {
        return this.form.controls?.invoiceItems?.controls[invoiceToRemove.invoiceID];
    }

    private updateTableData(invoices: Invoice[]): void {
        this.tableData.data = invoices;
        this.updateTable.emit(invoices);
    }
}
