import { IWorkflowToken, WorkflowTokenContent } from "@visoryplatform/threads";

import { environmentCommon } from "../../environment/environment.common";

type TokenMap = { [id: string]: IWorkflowToken };

export class WorkflowVariationsService {
    readonly workflowTokenKeys = environmentCommon.workflowConfigTokens;

    getWorkflowTokens(tokenIds?: string[]): IWorkflowToken[] | null {
        const tokenMap = this.getTokenMap();

        const foundTokens: IWorkflowToken[] = tokenIds?.map((id) => tokenMap[id]).filter((token) => token);
        if (!foundTokens?.length) {
            return null;
        }

        return foundTokens;
    }

    getTokenAffectingScheduling(tokens?: IWorkflowToken[]): IWorkflowToken | null {
        if (!tokens?.length) {
            return null;
        }

        const tokensAffectingScheduled = tokens.find((token) => token?.affectsScheduling);
        if (!tokensAffectingScheduled) {
            return null;
        }

        return tokensAffectingScheduled;
    }

    private getTokenMap(): TokenMap {
        const tokenTypes = Object.keys(this.workflowTokenKeys);

        return tokenTypes.reduce<TokenMap>((tokenMap, type) => {
            const tokensFromType = this.getTokensFromType(type);
            return this.mergeTokensToMap(tokensFromType, tokenMap);
        }, {});
    }

    private getTokensFromType(type: string): WorkflowTokenContent[] {
        const controls: WorkflowTokenContent[] = this.workflowTokenKeys[type];
        return controls;
    }

    private mergeTokensToMap(tokensFromType: WorkflowTokenContent[], tokenMap: TokenMap): TokenMap {
        for (const control of tokensFromType) {
            for (const token of control.tokens) {
                tokenMap[token.id] = token;
            }
        }

        return tokenMap;
    }
}
