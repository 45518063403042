<ng-container *ngIf="modalData">
    <request
        (save)="save()"
        (close)="close()"
        [title]="modalTitle.PayrolReport"
        [subtitle]="subtitle"
        [loading]="!!loader?.counter"
        [accountName]="accountName"
        [disableSave]="!form?.valid"
        [saveButtonTitle]="buttonLabel.Create"
        [analyticsPrefix]="analyticsTags.analyticsPrefix"
    >
        <div class="payrun-container">
            <div class="request-common-group request-common-group--narrow">
                <div class="request-common-label request-common-group-header">Note or message</div>
                <request-view-reply [cardDescription]="form.controls.cardDescription"></request-view-reply>
            </div>
            <payrun-report-create-request
                [cardDescription]="form?.controls?.cardDescription"
                [reportListItem]="modalData.reportListItem"
                [thread]="modalData.thread"
            >
            </payrun-report-create-request>
        </div>
    </request>
</ng-container>
