import { ROUTES, RouterModule, Routes } from "@angular/router";

import { ConfirmEmailComponent } from "./components/confirm-email/confirm-email.component";
import { GeneralSettingsComponent } from "./components/user-profile/general-settings/general-settings.component";
import { ILibrary } from "../plugins/interfaces/ILibrary";
import { InsightsPermissionsComponent } from "../../../../default-plugins/insights/components/insights-permissions/insights-permissions.component";
import { NgModule } from "@angular/core";
import { NotificationSettingsComponent } from "./components/user-profile/notification-settings/notification-settings.component";
import { PROFILE_ROUTE_LIBRARY } from "src/app/injection-token";
import { ProfileRouteComponent } from "./components/profile-route/profile-route.component";
import { RouteExtension } from "../plugins/services/Libraries";
import { RouteHelper } from "../plugins/services/RouteHelper";
import { authGuard } from "../findex-auth";

export const profileRoutes: Routes = [
    { path: "details", component: GeneralSettingsComponent, canActivate: [authGuard] },
    { path: "notifications", component: NotificationSettingsComponent, canActivate: [authGuard] },
    { path: "insights-permissions", component: InsightsPermissionsComponent, canActivate: [authGuard] },
    { path: "", redirectTo: "details", pathMatch: "full" },
];

@NgModule({
    imports: [RouterModule],
    exports: [RouterModule],
    providers: [
        {
            provide: ROUTES,
            useFactory: (library: ILibrary<RouteExtension>) => {
                const dynamicRoutes = RouteHelper.getRoutes(library);
                return [
                    ...dynamicRoutes,
                    {
                        path: "",
                        component: ProfileRouteComponent,
                        children: profileRoutes,
                    },
                    { path: "verify-email", component: ConfirmEmailComponent, canActivate: [authGuard] },
                ];
            },
            deps: [PROFILE_ROUTE_LIBRARY],
            multi: true,
        },
    ],
})
export class UserProfileRoutingModule {}
