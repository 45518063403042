import { Component, Inject, Input, OnChanges, SimpleChanges } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Role } from "@visoryplatform/threads";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { CreateCustomerRequestModalComponent } from "../create-customer-request-modal/create-customer-request-modal.component";

@Component({
    selector: "customer-request-global-button",
    templateUrl: "./customer-request-global-button.component.html",
    styleUrls: ["./customer-request-global-button.component.scss"],
})
export class CustomerRequestGlobalButtonComponent implements OnChanges {
    @Input() role: Role;

    readonly gaEvents = GA_EVENTS;
    readonly appName = this.environment.appName;

    isMobileView: boolean;

    canCreateNewRequest$: Observable<boolean>;

    private readonly featureFlags = LaunchDarklyFeatureFlags;

    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private permissionService: PermissionService,
        private featureFlagService: FeatureFlagService,
        private dialog: MatDialog,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        const { role } = changes;

        if (role && this.role) {
            this.canCreateNewRequest$ = this.canCreateNewRequest(this.role);
        }
    }

    newRequest(): void {
        const config = {
            disableClose: false,
            backdropClass: "modal-backdrop",
            panelClass: ["threads-sidebar", "mat-dialog-no-styling"],
            closeOnNavigation: true,
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            height: "100vh",
            data: {},
        };

        this.dialog.open(CreateCustomerRequestModalComponent, config).afterClosed().subscribe();
    }

    canCreateNewRequest(role: Role): Observable<boolean> {
        const permission = this.permissionService.hasSomePermission(role, ["CreateCustomerRequest", "ThreadUpdateAll"]);
        const featureFlag = this.featureFlagService.getFlag(this.featureFlags.EnableGlobalCustomerNewRequest);

        return combineLatest([permission, featureFlag]).pipe(
            map(([hasPermission, isEnabled]) => hasPermission && isEnabled),
        );
    }
}
