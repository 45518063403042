export enum RequestTypes {
    BlankRequest = "blank-request",
    TemplateRequest = "template-request",
    PayrunRequest = "payrun-approval-request",
    CreateBankFileRequest = "payrun-create-bank-file-request",
}

export interface ICreateRequestMenu {
    title: string;
    description: string;
    template?: ICreateRequestMenuTemplateDefinition;
    templates?: ICreateRequestTemplate[];
    type: RequestTypes;
}

export interface ICreateRequestTemplate {
    id: string;
    title: string;
    type: string[]; // workflow id
    template: ICreateRequestMenuTemplateDefinition;
}

export interface ICreateRequestMenuTemplateDefinition {
    vaultTitle?: string;
    cardDescription?: string;
    requestItems?: string[];
}
