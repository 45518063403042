import { Injectable } from "@angular/core";
import { IStep, IWorkflow, IWorkflowDesign } from "@visoryplatform/workflow-core";
import { ITimeline, IWorkflowDesignType } from "@visoryplatform/threads";
import { WorkflowApiService } from "./workflow-api.service";
import { IPaginated } from "@visoryplatform/datastore-types";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";

export interface IProviderWorkflow {
    provider: string;
    workflow: IWorkflow;
}

export interface IThreadWorkflowGroup {
    threads: ITimeline[];
    provider: string;
    workflow: IWorkflow;
    active?: boolean;
}

@Injectable({
    providedIn: "root",
})
export class WorkflowService {
    designCache: Record<string, Observable<IWorkflowDesign>> = {};

    constructor(private workflowApi: WorkflowApiService) {}

    listWorkflowDesigns(next?: string, limit?: number): Observable<IPaginated<IWorkflowDesign>> {
        return this.workflowApi.listWorkflowDesigns(next, limit);
    }

    listDesignTypes(): Observable<IWorkflowDesignType[]> {
        return this.workflowApi.listDesignTypes();
    }

    getDesign(designId: string): Observable<IWorkflowDesign> {
        if (!this.designCache[designId]) {
            const design$ = this.workflowApi.getDesign(designId).pipe(shareReplay(1));
            this.designCache[designId] = design$;
        }

        return this.designCache[designId];
    }

    getCurrentStep(workflow: IWorkflow): Readonly<IStep> {
        return workflow?.steps?.[workflow.currentStepId];
    }
}
