import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { UiToastComponent } from "../../threads-ui/components/toast/ui-toast.component";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { delay, filter, skipUntil, take, takeUntil } from "rxjs/operators";
import { interval, MonoTypeOperatorFunction, of, Subject } from "rxjs";
import { ErrorService } from "./error.service";

/**
 * -- HOW TO TEST --
 *
 * Testing locally:
 *
 * npm i -g http-server
 * enable serviceWorker flag for dev in angular.json
 * enable enableServiceWorkerRefresh for dev in environment.sigma.dev.ts
 * cd to frontend app
 * npm run build:dev-sigma; http-server -p 8080 -c-1 dist/dev
 * NOTE: service worker may need to be unregistered in devtools
 *
 * Testing QA/ UAT:
 * enable serviceWorker flag for QA/ UAT in angular.json
 *
 * SCRIPT: bump ngsw.json timestamp and invalidate cache
 * npm run migration release-1.33.0/trigger-frontend-refresh
 *
 * MANUAL: bump ngsw.json timestamp and invalidate cache
 * find ngsw.json in s3 frontend bucket and increment timestamp
 * invalidate cloudfront distribution with /* or /ngsw.json
 */
@Injectable({ providedIn: "root" })
export class PromptUpdateService {
    private readonly POLL_MINUTES = 1000 * 60;
    private stopPolling$ = new Subject<void>();

    constructor(private updates: SwUpdate, private dialog: MatDialog, private errorService: ErrorService) {}

    checkForUpdate(): void {
        if (!this.updates.isEnabled) {
            return;
        }

        this.updates.checkForUpdate();
        const swUpdates$ = this.updates.versionUpdates;

        swUpdates$
            .pipe(
                take(1),
                filter((swUpdates) => swUpdates.type === "VERSION_DETECTED"),
            )
            .subscribe(async () => {
                this.errorService.disableDialog = true;
                this.stopPolling$.next();
                this.openWarningDialog();
                await this.updates.checkForUpdate();
                document.location.reload();
            });

        interval(this.POLL_MINUTES)
            .pipe(takeUntil(this.stopPolling$))
            .subscribe(() => {
                this.updates.checkForUpdate();
            });

        swUpdates$.pipe(this.delayInitialPoll(), takeUntil(this.stopPolling$)).subscribe((swUpdates) => {
            if (swUpdates.type === "VERSION_DETECTED") {
                this.errorService.disableDialog = true;
            }
            if (swUpdates.type === "VERSION_READY") {
                this.stopPolling$.next();
                this.openPromptDialog();
            }
        });

        // Listen in case broken files are served by SW (leading to chunkLoadError)
        this.updates.unrecoverable.subscribe(() => {
            this.openWarningDialog();
            document.location.reload();
        });
    }

    private delayInitialPoll(): MonoTypeOperatorFunction<any> {
        return skipUntil(of(true).pipe(delay(this.POLL_MINUTES)));
    }

    private openWarningDialog(): void {
        this.dialog.open(UiToastComponent, {
            closeOnNavigation: false,
            disableClose: true,
            backdropClass: "modal-backdrop",
            panelClass: ["centre-toast-modal"],
            data: {
                message: "Updating to the latest version. Please wait.",
            },
        });
        this.errorService.disableDialog = false;
    }

    private openPromptDialog(): void {
        const dialogRef = this.dialog.open(UiToastComponent, {
            closeOnNavigation: false,
            disableClose: true,
            backdropClass: "modal-backdrop",
            panelClass: ["centre-toast-modal"],
            data: {
                message: "A new version is available. Please refresh to get the latest version.",
                buttonLabel: "Refresh",
            },
        });

        dialogRef.afterClosed().subscribe(() => {
            this.errorService.disableDialog = false;
            document.location.reload();
        });
    }
}
