import { Injectable, InjectionToken, Injector, Type } from "@angular/core";
import { Observable, Subject, Subscriber } from "rxjs";
import { DrawerRef } from "./drawer-ref";
import { MatDrawerMode } from "@angular/material/sidenav";

export const DRAWER_DATA = new InjectionToken("DRAWER_DATA");
export const DRAWER_REF = new InjectionToken("DRAWER_REF");

export interface DrawerConfig {
    mode?: MatDrawerMode;
    position?: "start" | "end";
    data: any;
}

export interface DrawerContext<R = unknown> {
    $implicit: Subscriber<R>;
    config: DrawerConfig;
    component: Type<any>;
}

export interface DrawerItem extends DrawerContext {
    injector: Injector;
}

export const defaultConfig: Partial<DrawerConfig> = {
    mode: "over",
    position: "end",
};

@Injectable({ providedIn: "root" })
export class DrawerService {
    item$ = new Subject<DrawerItem>();

    constructor(private injector: Injector) {}

    open<R = void>(component: Type<any>, config: DrawerConfig): Observable<R> {
        return new Observable((observer) => {
            const context = {
                config: { ...defaultConfig, ...config },
                $implicit: observer,
                component: component,
            };
            const drawerRef = new DrawerRef(context);

            const injector = Injector.create({
                parent: this.injector,
                providers: [
                    { provide: DRAWER_DATA, useValue: config.data },
                    { provide: DrawerRef, useValue: drawerRef },
                ],
            });

            const item = {
                ...context,
                injector,
            };

            this.item$.next(item);

            return () => {
                this.item$.next(null);
            };
        });
    }

    close(): void {
        this.item$.next(null);
    }
}
