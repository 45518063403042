<ng-container [formGroup]="form">
    <div *ngIf="threadTypes">
        <label class="fx-form-label">Service:</label>
        <div class="v-select-container">
            <v-select
                placeholder="Select a service"
                [options]="threadTypes | orderBy : ['threadTypeLabel', sortOption.ASC]"
                [optionContent]="optionContent"
                [tuiTextfieldCleaner]="false"
                formControlName="threadType"
            >
            </v-select>

            <ng-template
                #optionContent
                let-option
            >
                {{ option.threadTypeLabel }}
            </ng-template>
        </div>

        <div
            class="fx-form-validation"
            *ngIf="!form.controls.threadType.valid && form.controls.threadType.touched"
        >
            Service is required.
            <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
        </div>
    </div>

    <ng-container *ngIf="showWorkflowConfigurationSelectors">
        <div class="fx-form-group">
            <ng-container>
                <input
                    type="radio"
                    id="configured-workflow"
                    formControlName="configuration"
                    class="form-radio-input"
                    [value]="WorkflowConfigurationOptions.Configured"
                    checked
                />
                <label
                    class="fx-form-label"
                    for="configured-workflow"
                    >Configured workflow</label
                >
                <p class="label-sublabel">Dates and assignees will be as per account configuration</p>

                <input
                    type="radio"
                    id="blank-workflow"
                    formControlName="configuration"
                    [value]="WorkflowConfigurationOptions.Blank"
                    class="form-radio-input"
                />

                <label
                    class="fx-form-label"
                    for="blank-workflow"
                    >Blank workflow</label
                >
                <p class="label-sublabel">Assignees will be blank</p>
            </ng-container>
        </div>
    </ng-container>
</ng-container>
