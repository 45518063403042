import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CdkStepper } from "@angular/cdk/stepper";

@Component({
    selector: "stepper-layout",
    templateUrl: "stepper-layout.component.html",
    styleUrls: ["stepper-layout.component.scss"],
    providers: [{ provide: CdkStepper, useExisting: StepperLayoutComponent }],
})
export class StepperLayoutComponent extends CdkStepper {
    @Input() endStepLabel?: string;
    @Input() disabled?: boolean;
    @Output() completed = new EventEmitter<void>();
}
