import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import {
    CardStatus,
    ITimeline,
    IThreadCard,
    TransformResultState,
    TransformSource,
    TransformerCardState,
} from "@visoryplatform/threads";
import {
    RequestActionButtonLabel,
    RequestStatuses,
} from "../../../vault/components/request/constants/request.constants";
import { IRequestAnalyticsTags } from "../../../vault/components/request/interfaces/IRequestAnalyticsTags";
import { combineLatest, forkJoin, Observable, Subscription } from "rxjs";
import { FormGroup } from "@angular/forms";
import { IRequestForm } from "../../../vault/components/request/interfaces/IRequestForms";
import { AuthService } from "../../../../portal-modules/src/lib/findex-auth";
import { map, mapTo, switchMap, filter, take } from "rxjs/operators";
import { TransformersService } from "../../services/transformers.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { DialogRef, DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";
import { ITransformerModalData } from "../../types/ITransformerModalData";

@Component({
    selector: "transformer-action",
    templateUrl: "./transformer-action.component.html",
    styleUrls: ["./transformer-action.component.scss"],
})
export class TransformerActionComponent implements OnInit, OnDestroy {
    readonly CARD_STATUSES = CardStatus;
    readonly buttonLabels = RequestActionButtonLabel;
    readonly requestStatuses = RequestStatuses;

    analyticsTags: IRequestAnalyticsTags;
    card$: Observable<IThreadCard>;
    form$: Observable<FormGroup<IRequestForm>>;
    state$: Observable<TransformerCardState>;
    stateSubscription: Subscription;
    thread$: Observable<ITimeline>;
    userId$: Observable<string>;
    allChecksPass$: Observable<boolean>;
    loader = new Loader();

    public modalData: ITransformerModalData;
    public dialogRef: DialogRef;

    constructor(
        private authService: AuthService,
        private transformService: TransformersService,
        private injector: Injector,
        private dialogService: DialogService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.dialogRef = await this.dialogService.getRef(this.injector).pipe(take(1)).toPromise();
        this.modalData = await this.dialogService
            .getData<ITransformerModalData>(this.injector)
            .pipe(take(1))
            .toPromise();

        this.thread$ = this.modalData.thread$;
        this.card$ = this.modalData.card$;
        this.state$ = this.modalData.state$;
        this.userId$ = this.authService.getUserId();

        this.allChecksPass$ = this.state$.pipe(map((state) => this.isAllChecksPassing(state)));

        const runSources$ = combineLatest([this.thread$, this.state$]).pipe(
            switchMap(([thread, state]) => this.runSources(thread.id, state.sources)),
        );

        this.stateSubscription = this.allChecksPass$
            .pipe(
                filter((allChecksPass) => !allChecksPass),
                switchMap(() => runSources$),
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.stateSubscription?.unsubscribe();
    }

    trackTransformerResult(_index: number, transformerResult: TransformResultState): string {
        return transformerResult?.resultTransformId;
    }

    private runSources(threadId: string, sources: TransformSource[]): Observable<void> {
        console.log("running sources", threadId, sources);
        return forkJoin(sources.map((source) => this.runSource(threadId, source))).pipe(mapTo(null));
    }

    private runSource(threadId: string, source: TransformSource): Observable<void> {
        return this.loader.wrap(this.transformService.runSource(threadId, source.sourceId, source.event));
    }

    private isAllChecksPassing(state: TransformerCardState): boolean {
        return state?.resultTransforms?.every((transform) => transform.latestResult === true);
    }
}
