import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { VAULT_ACTION } from "@visoryplatform/vault";
import { IVaultItem } from "projects/default-plugins/vault/interfaces/IVaultItem";
import { AnalyticsService, GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { ENVIRONMENT } from "../../../../../../../src/app/injection-token";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";

@Component({
    selector: "vault-card-item",
    templateUrl: "./vault-card-item.component.html",
    styleUrls: ["./vault-card-item.component.scss"],
})
export class VaultCardItemComponent implements OnChanges {
    @Input() icon: string;
    @Input() disableRequestSignature;
    @Input() disableMarkAsReport;
    @Input() item: IVaultItem;
    @Input() disabled: boolean;
    @Input() canSign: boolean;
    @Input() canRename: boolean;
    @Input() canCreateReports: boolean;
    @Input() canDelete: boolean;
    @Input() isReport: boolean;

    @Output() download = new EventEmitter<void>();
    @Output() preview = new EventEmitter<void>();
    @Output() sign = new EventEmitter<void>();
    @Output() delete = new EventEmitter<void>();
    @Output() rename = new EventEmitter<string>();
    @Output() markAsSign = new EventEmitter<void>();
    @Output() markAsReport = new EventEmitter<void>();

    readonly vaultActions = VAULT_ACTION;
    readonly PDF_FILE_TYPE = "application/pdf";
    readonly gaEvents = GA_EVENTS;
    readonly showInsights = this.environment.featureFlags?.insightsConfiguration?.showInsights;

    hasSignAction = false;
    displayName: string;
    renameMode = false;

    constructor(
        private analyticsService: AnalyticsService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        const { item, canRename, canDelete } = changes;

        if (item) {
            this.displayName = this.item?.displayName;
            this.hasSignAction = this.item?.actions?.includes(this.vaultActions.Sign);
        }

        if (canRename || canDelete) {
            if (!this.canRename && !this.canDelete) {
                this.renameMode = false;
            }
        }
    }

    renameItem(name: string): void {
        this.renameMode = false;

        if (name !== this.item.displayName) {
            this.rename.emit(name);
        }
    }

    downloadClicked(): void {
        if (this.isReport) {
            this.analyticsService.recordEvent("mouse-click", this.gaEvents.INSIGHTS_DOWNLOAD);
        } else {
            this.analyticsService.recordEvent("mouse-click", this.gaEvents.DOCUMENTS_DOWNLOAD);
        }

        this.download.emit();
    }

    markDocumentAsSign(): void {
        this.markAsSign.emit();
    }

    markDocumentAsReport(): void {
        this.markAsReport.emit();
    }
}
