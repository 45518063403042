import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IExtensionStaticInput, IWorkflow, IWorkflowDesign, ValidationResult } from "@visoryplatform/workflow-core";
import { ENVIRONMENT } from "src/app/injection-token";
import { environmentCommon, EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { IPaginated } from "@visoryplatform/datastore-types";
import { IWorkflowDesignType } from "@visoryplatform/threads";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class WorkflowApiService {
    constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig, private http: HttpClient) {}

    listWorkflowDesigns(next?: string, limit?: number): Observable<IPaginated<IWorkflowDesign>> {
        const { workflows, designs } = environmentCommon.workflowEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${designs}${workflows}`;

        const params = { next: next ?? "", limit: limit?.toString() ?? "" };
        return this.http.get<IPaginated<IWorkflowDesign>>(url, { params });
    }

    listDesignTypes(): Observable<IWorkflowDesignType[]> {
        const { designs, types } = environmentCommon.workflowEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${designs}${types}`;
        return this.http.get<IWorkflowDesignType[]>(url);
    }

    getDesign(designId: string): Observable<IWorkflowDesign> {
        const { designs, workflows } = environmentCommon.workflowEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${designs}${workflows}/${designId}`;
        return this.http.get<IWorkflowDesign>(url);
    }

    async isActionValid(threadId: string, stepId: string, actionId: string): Promise<ValidationResult> {
        const { workflow, steps, actions, valid } = environmentCommon.workflowEndpoints;
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;

        const url = `${base}${threads}/${threadId}${workflow}${steps}/${stepId}${actions}/${actionId}${valid}`;
        return await this.http.get<ValidationResult>(url).toPromise();
    }

    async executeExtension(threadId: string, stepId: string, extensionId: string, payload?: unknown): Promise<void> {
        const { workflow, steps, extensions } = environmentCommon.workflowEndpoints;
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;

        const url = `${base}${threads}/${threadId}${workflow}${steps}/${stepId}${extensions}/${extensionId}`;
        const body = { payload };

        await this.http.post<void>(url, body).toPromise();
    }

    async performAction(threadId: string, stepId: string, actionId: string): Promise<IWorkflow | ValidationResult> {
        const { workflow, steps, actions } = environmentCommon.workflowEndpoints;
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;

        const url = `${base}${threads}/${threadId}${workflow}${steps}/${stepId}${actions}/${actionId}`;

        return await this.http.post<IWorkflow | ValidationResult>(url, {}).toPromise();
    }

    async resolveInputs(
        threadId: string,
        stepId: string,
        extensionId: string,
    ): Promise<IExtensionStaticInput<unknown>[]> {
        const { workflow, steps, extensions, data } = environmentCommon.workflowEndpoints;
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${workflow}${steps}/${stepId}${extensions}/${extensionId}${data}`;

        return await this.http.get<IExtensionStaticInput<unknown>[]>(url).toPromise();
    }

    async getExtensionData(
        threadId: string,
        stepId: string,
        extensionId: string,
        updateInputs?: IExtensionStaticInput<unknown>,
    ): Promise<void> {
        const { workflow, steps, extensions, data } = environmentCommon.workflowEndpoints;
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;

        const url = `${base}${threads}/${threadId}${workflow}${steps}/${stepId}${extensions}/${extensionId}${data}`;
        const body = { updateInputs };

        await this.http.post<void>(url, body).toPromise();
    }

    async putExtensionInputs(
        threadId: string,
        stepId: string,
        extensionId: string,
        updateInputs: Record<string, unknown>,
    ): Promise<void> {
        const { workflow, steps, extensions } = environmentCommon.workflowEndpoints;
        const { threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;

        const url = `${base}${threads}/${threadId}${workflow}${steps}/${stepId}${extensions}/${extensionId}/inputs/data`;
        const body = { updateInputs };

        await this.http.put<void>(url, body).toPromise();
    }
}
