import { AssigneeType, ConfigTokenExtensionToken } from "@visoryplatform/workflow-core";
import { IWorkflowToken, WorkflowTokenTypes } from "@visoryplatform/threads";

export const expertRoleAssigneeVariation: IWorkflowToken = {
    id: "bf3d32b3-4acb-4abb-8782-972196d0f835",
    label: "Expert",
    type: WorkflowTokenTypes.MonthEnd,
    configTokens: {
        [ConfigTokenExtensionToken.EXPERT_ROLE_ASSIGNEE_VARIATION]: {
            assignees: [],
            assigneeType: AssigneeType.Internal,
        },
    },
};

export const monthEndRoleTokens = [expertRoleAssigneeVariation];
