import { Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { IDocument } from "../create-card-document/create-card-document.component";
import { EnvironmentSpecificConfig } from "../../../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

@Component({
    selector: "rename-file-modal",
    templateUrl: "./rename-file-modal.component.html",
    styleUrls: ["./rename-file-modal.component.scss"],
})
export class RenameFileModalComponent {
    name: string;
    readonly theme = this.environment.theme;

    constructor(
        @Inject(MAT_DIALOG_DATA) document: IDocument,
        private dialogRef: MatDialogRef<RenameFileModalComponent>,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {
        this.name = document.description;
    }

    save() {
        this.dialogRef.close(this.name);
    }

    close() {
        this.dialogRef.close();
    }
}
