import { Component, Inject, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { AnalyticsService } from "projects/portal-modules/src/lib/analytics";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";

@Component({
    selector: "two-factor-authentication-dialog",
    templateUrl: "./two-factor-authentication-dialog.component.html",
    styleUrls: ["./two-factor-authentication-dialog.component.scss"],
})
export class TwoFactorAuthenticationDialogComponent implements OnInit {
    readonly userDetails: any;

    constructor(
        private dialogRef: MatDialogRef<TwoFactorAuthenticationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private analyticsService: AnalyticsService,
    ) {
        this.userDetails = data.userDetails;
    }

    ngOnInit() {
        this.recordAnalyticsEvent("displayed");
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("two-factor-auth", category);
    }

    public closeDialog(submitted: boolean) {
        this.dialogRef.close(submitted);
    }
}
