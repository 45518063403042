import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, FormRecord } from "@angular/forms";
import {
    IThreadCard,
    CopilotTransaction,
    CardReply,
    ITimeline,
    CardStatus,
    BillApprovalState,
    BillApprovalUpdatedItem,
    BillApprovalUpdate,
    BillApprovalUpdateType,
} from "@visoryplatform/threads";
import {
    RequestActionButtonLabel,
    RequestStatuses,
} from "projects/default-plugins/vault/components/request/constants/request.constants";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { DialogRef, DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { Observable, of, Subscription } from "rxjs";
import { switchMap, shareReplay, take, mapTo, pairwise, scan, startWith, map } from "rxjs/operators";
import { BillApprovalService } from "../../services/bill-approval.service";
import { Invoice } from "@visoryplatform/copilot";
import { VaultRequestService } from "projects/default-plugins/vault/services/vault-request.service";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ConfirmModalComponent } from "projects/portal-modules/src/lib/shared/components/confirm-modal/confirm-modal.component";
import { CompleteRequestModalComponent } from "projects/default-plugins/vault/components/upload/complete-rfi-modal/complete-request-modal.component";
import { BillApprovalCompleteModalData, BillApprovalReviewModalData } from "../../constants";
import { BillApprovalModalData, BillApprovalFormItem, BillApprovalForm } from "../../interfaces/BillApproval";
import { BillApprovalFormStateService } from "../../services/bill-approval-form-state.service";

@Component({
    selector: "view-bill-approval",
    templateUrl: "./view-bill-approval.component.html",
    styleUrls: ["./view-bill-approval.component.scss"],
})
export class ViewBillApprovalComponent implements OnInit, OnDestroy {
    readonly CARD_STATUSES = CardStatus;
    readonly buttonLabels = RequestActionButtonLabel;
    readonly requestStatuses = RequestStatuses;

    replyMessage = new FormControl("");
    dialogRef: DialogRef;
    modalData: BillApprovalModalData;

    card$: Observable<IThreadCard>;
    form$: Observable<FormGroup<BillApprovalForm>>;
    state$: Observable<BillApprovalState>;
    transactions$: Observable<CopilotTransaction[]>;
    thread$: Observable<ITimeline>;
    userId$: Observable<string>;
    replies$: Observable<CardReply[]>;
    invoices$: Observable<Invoice[]>;

    loader = new Loader();
    tableLoader = new Loader();
    actionedPercentage = 0;

    plannedPaymentDate: FormControl<string> = new FormControl({
        value: "",
        disabled: true,
    });

    private formValSub: Subscription;
    private stateSub: Subscription;

    constructor(
        private authService: AuthService,
        private dialogService: DialogService,
        private billApprovalService: BillApprovalService,
        private vaultRequestService: VaultRequestService,
        private dialog: MatDialog,
        private injector: Injector,
        private billApprovalFormStateService: BillApprovalFormStateService,
    ) {}

    async ngOnInit(): Promise<void> {
        await this.initModalData();

        this.thread$ = this.modalData.thread$;
        this.card$ = this.modalData.card$;
        this.state$ = this.modalData.state$.pipe(shareReplay(1));
        this.replies$ = this.modalData.replies$;
        this.userId$ = this.authService.getUserId();

        const formGroup = new FormGroup<BillApprovalForm>({
            invoiceItems: new FormRecord<FormGroup<BillApprovalFormItem>>({}),
        });

        this.stateSub = this.state$.subscribe((state) => {
            this.plannedPaymentDate.patchValue(state.plannedDate);
        });

        this.form$ = this.state$.pipe(
            startWith(null),
            pairwise(),
            scan(
                (_, [oldState, currState]) =>
                    this.billApprovalFormStateService.setRequestItems(formGroup, oldState, currState),
                formGroup,
            ),
        );

        this.invoices$ = this.state$.pipe(
            switchMap((state) => this.downloadAttachment(state)),
            take(1),
            shareReplay(1),
        );

        this.formValSub = formGroup.valueChanges
            .pipe(
                map(() => this.calculateActionedPercentage(formGroup)),
                startWith(0),
                shareReplay(1),
            )
            .subscribe((val) => (this.actionedPercentage = val));
    }

    ngOnDestroy(): void {
        this.formValSub?.unsubscribe();
        this.stateSub?.unsubscribe();
    }

    updateValue(
        thread: ITimeline,
        card: IThreadCard,
        state: BillApprovalState,
        requestItem: BillApprovalUpdatedItem,
    ): void {
        const { vaultId, isCompleted } = state;
        const payload: BillApprovalUpdate = {
            type: BillApprovalUpdateType.Update,
            vaultId,
            update: {
                invoiceItems: [requestItem],
            },
        };

        if (isCompleted) {
            return;
        } else {
            this.billApprovalService.updateRequestItems(thread.id, card.id, payload).pipe(take(1)).subscribe();
        }
    }

    save(thread: ITimeline, card: IThreadCard): void {
        if (this.actionedPercentage === 100) {
            this.markAsCompleted(thread, card).subscribe((result) => {
                this.dialogRef.close(result);
            });
        } else {
            this.sendForReview(thread, card).subscribe((result) => {
                this.dialogRef.close(result);
            });
        }
    }

    private calculateActionedPercentage(formGroup: FormGroup<BillApprovalForm>): number {
        const formValues = formGroup.value;
        const invoiceItems = Object.values(formValues.invoiceItems);
        const completed = invoiceItems.filter((invoiceItem) => invoiceItem?.approved || invoiceItem?.declined);
        return Math.floor((completed.length / invoiceItems.length) * 100);
    }

    private downloadAttachment(state: BillApprovalState): Observable<Invoice[]> {
        const fileId = state.attachments.fileId;
        const vaultId = state.vaultId;
        return this.tableLoader.wrap(this.billApprovalService.downloadVaultPayRunReport(vaultId, fileId));
    }

    private sendForReview(thread: ITimeline, card: IThreadCard): Observable<unknown> {
        const dialogParams = {
            data: {
                confirmText: RequestActionButtonLabel.Review,
                declineText: "Cancel",
                promptText: BillApprovalReviewModalData.Title,
                areYouSureText: BillApprovalReviewModalData.Subhead,
            },
            panelClass: ["centered-modal"],
            width: "420px",
        };
        return this.dialog
            .open(ConfirmModalComponent, dialogParams)
            .afterClosed()
            .pipe(
                switchMap((completed) => this.sendReviewEvent(thread, card, completed)),
                take(1),
            );
    }

    private sendReviewEvent(thread: ITimeline, card: IThreadCard, completed: boolean): Observable<boolean | null> {
        const requestCardComplete$ = this.vaultRequestService.sendRequestCardEvent(thread.id, card.id, card.type, {
            body: { isReview: true, isCompleted: false },
        });

        return completed ? this.loader.wrap(requestCardComplete$).pipe(mapTo(true)) : of(null);
    }

    private markAsCompleted(thread: ITimeline, card: IThreadCard): Observable<unknown> {
        return this.dialog
            .open(CompleteRequestModalComponent, {
                panelClass: "centered-modal",
                data: {
                    title: BillApprovalCompleteModalData.Title,
                    subhead: BillApprovalCompleteModalData.Subhead,
                    analyticsPrefix: "bill-approval",
                },
            })
            .afterClosed()
            .pipe(
                switchMap((complete) => this.sendCompletedEvent(thread, card, complete)),
                take(1),
            );
    }

    private sendCompletedEvent(thread: ITimeline, card: IThreadCard, complete: boolean): Observable<boolean | null> {
        const requestCardComplete$ = this.vaultRequestService.sendRequestCardEvent(thread.id, card.id, card.type, {
            body: { isCompleted: true },
        });
        return complete ? this.loader.wrap(requestCardComplete$).pipe(mapTo(true)) : of(null);
    }

    private async initModalData(): Promise<void> {
        this.dialogRef = await this.loader.wrap(this.dialogService.getRef(this.injector)).toPromise();
        this.modalData = await this.loader
            .wrap(this.dialogService.getData<BillApprovalModalData>(this.injector))
            .toPromise();
    }
}
